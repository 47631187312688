<template>
  <header class="top-header">
    <div class="header-left">
      <div class="header-logo">
        <router-link :to="{ name: 'home' }">
          <img
            src="@/assets/youpaq-logo.svg"
            width="100"
            alt="Youpag logo"
          >
        </router-link>
      </div>
      <div class="header-ville">
        <img
          src="@/assets/ville-icon.png"
          alt="Icone ville"
          class="icon"
          @click="openMenuVille = true, openLogin = false"
        >
        <div
          class="header-ville-content"
          @click="openMenuVille = true, openLogin = false"
        >
          <span class="header-ville-choisir d-none d-md-flex">{{ $t('content.header.chooseCity') }}</span>
        </div>
        <div
          class="menu-ville"
          :class="{ active: openMenuVille }"
        >
          <TopMainMenuVille
            @closeMenuEvent="openMenuVille = false"
          />
        </div>
      </div>
    </div>
    <div class="header-right">
      <div class="header-actions">
        <div class="header-compte">
          <div class="compte-icon">
            <img
              v-if="!connected"
              src="@/assets/compte-icon.png"
              class="icon"
              alt="Icone compte"
              @click="openLogin = true, openMenu = false, openCart = false, openMenuVille = false, openUserMenu = false"
            />
            <img
              v-else
              src="@/assets/compte-icon.png"
              class="icon"
              alt="Icone compte"
              @click="openUserMenu = true, openLogin = false, openMenu = false, openCart = false, openMenuVille = false"
            />
            <span v-if="user" class="name">{{ user.firstname }}<br />{{ user.lastname }}</span>
          </div>
          <div
            class="login"
            :class="{ active: openLogin }"
          >
            <Login :show="openLogin" refresh-on-login @closeLoginEvent="openLogin = false" />
          </div>
          <div
            class="user-menu"
            :class="{ active: openUserMenu }"
          >
            <TopUserMenu @closeUserMenuEvent="openUserMenu = false" />
          </div>
        </div>
        <div
          v-if="connected"
          class="header-logout d-none"
        >
          <a href="#" @click="doLogout">
            <img
              src="@/assets/logout-icon.png"
              class="icon"
              alt="Icone logout"
            >
          </a>
        </div>
        <div class="header-email d-none d-md-flex">
          <a href="mailto:info@youpaq.com"><img
            src="@/assets/email-icon.png"
            class="icon"
            alt="Icone email"
          ></a>
        </div>
      </div>
      <div class="header-contact d-none d-md-flex">
        <img
          src="@/assets/telephone-icon.png"
          class="icon"
          alt="Icone téléphone"
        >
        <span>{{ $t('content.header.contactUs') }}<br>info@youpaq.com</span>
      </div>
    </div>
  </header>
</template>

<script>
import TopMainMenuVille from '@/components/MenusAndPopin/TopMainMenuVille.vue'
import TopUserMenu from '@/components/MenusAndPopin/TopUserMenu.vue'
import Login from '@/components/MenusAndPopin/Login.vue'

export default {
  name: 'HomeHeader',
  components: {
    TopMainMenuVille,
    TopUserMenu,
    Login
  },
  data() {
    return {
      openMenuVille: false,
      openLogin: false,
      openUserMenu: false
    }
  },
  computed: {
    connected() {
      return this.$store.getters['auth/connected']
    },
    user() {
      return this.$store.getters['auth/user']
    }
  },
  watch: {
    $route() {
      this.openLogin = false
    }
  },
  methods: {
    doLogout() {
      return this.$store.dispatch('auth/logout')
        .catch(err => this.$err(err))
        .finally(() => {
          if (!this.$route.params.partnerId) {
            // no redirection if not inside a city
            return
          }
          this.$router.push({ name: 'home' })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

header {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding: 15px 10px 10px 10px;
  background: #ffffff;

  @include media-breakpoint-up(lg) {
    padding: 15px 10px 10px 100px;
  }
}

header > div {
  display: flex;
  align-items: center;
}

header > div > div {
  display: flex;
}

.top-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 0px 5px 0px #0000005e;

  img.icon {
    max-height: 30px;
  }

  .header-left {
    position: relative;

    .header-logo {
      width: 120px;

      img {
        max-height: 43px;
      }
    }

    .header-ville {
      position: relative;
      margin-left: 20px;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(lg) {
        margin-left: 50px;
      }

      img {
        cursor: pointer;
      }

      .header-ville-choisir {
        padding-left: 10px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .header-right {
    .header-actions {
      display: flex;
      align-items: center;

      .header-email {
        margin-right: 30px;
      }

      .header-compte {
        position: relative;

        .compte-icon {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--gray-color);
          color: #e45e43;
          font-size: 12px;

          img {
            cursor: pointer;
          }
        }

        img {
          margin-right: 10px;
        }

        span {
          text-align: left;
        }

        .login {
          position: absolute;
          top: 48px;
          right: -26px;
        }
      }
    }
    .header-contact {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0 30px;

      img {
        margin-right: 10px;
      }

      span {
        text-align: left;
        font-size: 15px;
      }
    }
  }
}

.menu-ville:not(.active) {
  display: none;
}

.header-compte .user-menu:not(.active) {
  display: none;
}

.header-actions > div {
  margin: 0 20px;
  display: flex;
}

.header-compte .login:not(.active) {
  display: none;
}

</style>
