/* eslint-disable */
import lodash from 'lodash'
import decimal from '@/plugins/decimal'
import unitprice from '@/plugins/unitprice'
import moment from 'moment'
import Vue from 'vue'
import ProductUnitStepTable from '@/mixins/ProductUnitStepTable'

var Article = function (props) {
    // magouille pour permettre aux Offers d'avoir accès à l'article
    // (vient de la v1...)
    if (props.reseller_offers) {
        props.reseller_offers.forEach(offer => {
            offer.article = lodash.cloneDeep(props)
            delete offer.article.reseller_offers
        })
    }
    if (props.offers) {
        props.offers.forEach(offer => {
            offer.article = lodash.cloneDeep(props)
            delete offer.article.offers
        })
    }
    if (props.offer) {
        props.offer.article = lodash.cloneDeep(props)
        delete props.offer.article.offer
    }

    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, Article)
};

Article.relations = {
    offer: 'Offer',
    offers: 'Offer',
    reseller_offers: 'Offer'
};

Article.mixins = {
    'metadata': {},
    'images': {},
    'updatable': {
        getJson: {
            merge: {type: 'article'}
        },
        update: {
            postUpdate: Article.cleanPackaging,
            equalExclude: [
                'lastimport'
            ]
        }
    }
};

lodash.assign(Article.prototype, {

    /**
     * Récupère les resellers offers. Dans certains contexte, l'instance
     * d'article n'a pas les resellers offers chargées, mais on en a une
     * à dispo comme objet séparé.
     *
     * @param {Array} [offers] un tableau d'offres pas dans l'instance d'article
     * @return {Array}
     */
    getResellerOffers: function (offers) {
        return offers || this.reseller_offers;
    },

    getSupplierOffers: function () {
        return this.offers;
    },

    hasSupplierOffers: function () {
        return lodash.isArray(this.offers) && this.offers.length > 0;
    },

    hasMultipleResellerOffers: function () {
        return this.getResellerOffers().length > 1;
    },

    getResellerOffersIds: function () {
        return lodash.map(this.getResellerOffers(), 'id');
    },

    /**
     * Check si l'article a des promotions
     *
     * @param {Date} date la date
     * @param {Array} offers un tableau d'offres pas dans l'instance d'article
     * @return {Boolean}
     */
    hasPromotion: function (date, offers) {
        return lodash.compact(lodash.map(this.getResellerOffers(offers), function (offer) {
            return offer.hasPromotion(date);
        })).length > 0;
    },

    /**
     * Check si l'article a des promotions annoncées
     *
     * @param {Date} date la date d'annonce
     * @param {Array} offers un tableau d'offres pas dans l'instance d'article
     * @return {Boolean}
     */
    hasAnnouncedPromotion: function (date, offers) {
        return lodash.compact(lodash.map(this.getResellerOffers(offers), function (offer) {
                return offer.hasAnnouncedPromotion(date);
            })).length > 0;
    },

    /**
     * Récupération de la première promotion de l'article
     *
     * @info pour l'instant on s'embête pas à récupérer toutes le promotions car il ne devrait pas y en avoir plus d'une par article
     *       dans un contexte de région (validé par Freddy le 29.08.2016)
     *
     * @param {Date} date la date
     * @param {Array} offers un tableau d'offres pas dans l'instance d'article
     * @return {Object}
     */
    getFirstPromotionedOffer: function (date, offers) {
        return lodash.chain(this.getResellerOffers(offers))
            .filter(function (offer) {
                return offer.hasPromotion(date);
            })
            .head()
            .value();
    },

    getPromotionEnd: function () {
        var end = lodash.chain(this.getResellerOffers())
            .map(function (offer) {
                return offer.hasAnnouncedPromotion() || offer.hasPromotion() ? offer.getPromotion().getDateEnd() : false;
            })
            .compact()
            .sortBy(function (date) {
                return date.unix();
            })
            .value();

        if (end.length === 1) {
            return lodash.head(end);
        }
        return end;
    },

    getPromotionStart: function () {
        var start = lodash.chain(this.getResellerOffers())
            .map(function (offer) {
                return offer.hasAnnouncedPromotion() || offer.hasPromotion() ? offer.getPromotion().getDateStart() : false;
            })
            .compact()
            .sortBy(function (date) {
                return date.unix();
            })
            .value();

        if (start.length === 1) {
            return lodash.head(start);
        }
        return start;
    },

    /**
     * Récupération du délai avant le début de la promotion depuis la date donnée.
     *
     * @param [date]
     * @returns {Integer} un nombre de jours
     */
    getPromotionDelayStartFrom: function (date) {
        date = moment(date, 'YYYY-MM-DD') || moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
        return this.getPromotionStart().diff(date, 'days');
    },

    /**
     * Récupération du délai avant la fin de la promotion depuis la date donnée.
     *
     * @param [date]
     * @returns {Integer} un nombre de jours
     */
    getPromotionDelayEndFrom: function (date) {
        date = moment(date, 'YYYY-MM-DD') || moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
        return this.getPromotionEnd().diff(date, 'days');
    },

    getId: function () {
        return this.id;
    },

    setId: function (id) {
        this.id = id;
        return this;
    },

    getCategoryId: function () {
        if (!this.youpaq) {
            return null;
        }
        return this.youpaq.category;
    },

    getOwnerId: function () {
        var owner_id = this.owner ? this.owner.id : null;
        return owner_id;
    },

    getBrand: function (strict) {
        return (this.brand || (strict ? '' : '[non renseigné]'));
    },

    getTitle: function (lang, strict) {
        if (!this.title) {
            return '';
        }
        lang = lang || Vue.prototype.$storage.get('ypq-lang');
        return (this.title[lang] || this.title.fr || (strict ? '' : '???'));
    },

    getDisplayName: function (lang) {
        var brand = this.getBrand(true),
            title = this.getTitle(lang, true);

        return (brand ? brand + ' ' : '') + title;
    },

    getDescription: function (lang) {
        if (!this.description) {
            return '';
        }
        lang = lang || Vue.prototype.$storage.get('ypq-lang');
        return lodash.isObject(this.description) ? (this.description[lang] || this.description.fr) : this.description;
    },

    getComposition: function (lang) {
        if (!this.composition) {
            return '';
        }
        lang = lang || Vue.prototype.$storage.get('ypq-lang');
        return lodash.isObject(this.composition) ? (this.composition[lang] || this.composition.fr) : this.composition;
    },

    getProducer: function (strict) {
        return this.producer || (strict ? '' : '[non renseigné]');
    },

    getMadeIn: function () {
        return this.made_in || [];
    },

    hasMadeIn: function () {
        return this.getMadeIn().length > 0;
    },

    getPackaging: function () {
        return this.packaging || {};
    },

    getPackagingType: function () {
        return this.getPackaging().type;
    },

    /**
     * Unité de conditionnement de l'article
     *
     * @returns {String}
     */
    getUnit: function () {
        return this.getPackaging().unit;
    },

    /**
     * Unité pour la tarification de l'article
     *
     * @returns {String}
     */
    getTarificationUnit: function () {
        return (this.getPackaging().price_quantity_unit || this.getPackaging().unit);
    },

    isRawPackaged: function () {
        return this.getPackagingType() && this.getPackagingType() === 'en vrac';
    },

    isWeightPriced: function () {
        return this.getPackaging().pricing_type === 'quantity';
    },

    getUnitStep: function () {
        if (!this.isRawPackaged()) {
            return 1
        }
        const customerUnit = this.getUnit()
        const priceUnit = this.getTarificationUnit()

        const t = ProductUnitStepTable;

        // if nothing found, set a decimal step to permit this sort of input
        return (t[customerUnit] && t[customerUnit][priceUnit]) || 0.1
    },

    cleanPackaging: function () {
        if (this.isRawPackaged()) {
            this.packaging.quantity = null;
        }
    },

    hasVariableWeight: function () {
        return this.getPackaging().margin > 0;
    },

    getWeight: function () {
        return Number(this.getPackaging().quantity);
    },

    getMinWeight: function () {
        return decimal.mul(this.getWeight(), (100 - this.getPackaging().margin) / 100);
    },

    getMaxWeight: function () {
        return decimal.mul(this.getWeight(), (100 + this.getPackaging().margin) / 100);
    },

    getMaxQuantity: function (baseQuantity) {
        return unitprice.getMaxQuantity(this, baseQuantity);
    },

    getMinQuantity: function (baseQuantity) {
        return unitprice.getMinQuantity(this, baseQuantity);
    },

    getMaxPrice: function (offers, refPrice) {
        return unitprice.getMaxPrice(this, (offers || this.getResellerOffers()), refPrice);
    },

    isTextValidated: function () {
        if (!this.youpaq || !this.youpaq.validated) {
            return false;
        }
        return this.youpaq.validated.text;
    },

    isImagesValidated: function () {
        if (!this.youpaq || !this.youpaq.validated) {
            return false;
        }
        return this.youpaq.validated.images;
    },

    isManuallyDisabled: function () {
        if (!this.youpaq || !this.youpaq.validated) {
            return false;
        }
        return this.youpaq.validated.disabled;
    },

    isNotValidated: function () {
        return (!this.isTextValidated() || !this.isImagesValidated());
    },

    isValidated: function () {
        return this.isImagesValidated() && this.isTextValidated() && !this.isManuallyDisabled();
    },

    isFavourite: function () {
        return this.favourite;
    },

    getSpecific: function (spec, checkArray) {
        var data = this.specific && this.specific[spec];
        if (checkArray && lodash.isArray(data) && !data.length) {
            return null;
        }
        if (lodash.isObject(data) && data.text) {
            return data.text;
        }
        if (lodash.isArray(data)) {
            return lodash.map(data, function (d) {
                return lodash.isObject(d) && d.text ? d.text : d;
            });
        }
        return data;
    }

});

export default Article;
