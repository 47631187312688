/* eslint-disable */
import lodash from 'lodash'
import moment from 'moment'
import Vue from 'vue'

var Promotion = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, Promotion)
};

Promotion.relations = {
    'article': 'Article',
    'reseller_offer': 'Offer',
    'campaign': 'PromotionCampaign'
};

Promotion.mixins = {
    'metadata': {},
    'updatable': {
        getJson: {
            merge: {type: 'promotion'}
        }
    }
};

lodash.assign(Promotion.prototype, {
    getId: function () {
        return this.id;
    },

    getArticle: function () {
        return this.article;
    },

    getOffer: function () {
        return this.reseller_offer;
    },

    getCampaign: function () {
        return this.campaign;
    },

    getPrice: function () {
        return this.price;
    },

    getDateStart: function () {
        return moment(this.start, 'YYYY-MM-DD');
    },

    getDateEnd: function () {
        return moment(this.end, 'YYYY-MM-DD');
    },

    updateFromCampaign: function (campaign) {
        this.announcement = campaign.announcement;
        this.start = campaign.start;
        this.end = campaign.end;
        this.display = campaign.promotions_type;
        this.campaign_id = campaign.getId();
        return this;
    }
});

export default Promotion;
