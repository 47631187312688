<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="footer-logo col-12 col-md-3">
          <router-link :to="{ name: partnerId ? 'partner' : 'home' }">
            <img
              src="@/assets/youpaq-logo-footer.png"
              class="icon"
              alt="Youpag logo"
            >
          </router-link>
          <br />
          <LangSelector color="white" class="mt-3" />
        </div>
        <div class="footer-menu col-12 col-md-7">
          <h4>{{ $t('content.footer.links') }}</h4>
          <nav>
            <ul>
              <li><router-link :to="$siteLink($route, 'faq')">{{ $t('content.footer.faq') }}</router-link></li>
              <li><router-link :to="$siteLink($route, 'a-propos')">{{ $t('content.footer.about') }}</router-link></li>
              <li><router-link :to="$siteLink($route, 'contact')">{{ $t('content.footer.contact') }}</router-link></li>
            </ul>
            <ul>
              <li><router-link :to="$siteLink($route, 'conditions-generales')">{{ $t('content.footer.conditions') }}</router-link></li>
              <li><router-link :to="$siteLink($route, 'politque-confidentialite')">{{ $t('content.footer.privacy') }}</router-link></li>
            </ul>
          </nav>
          <p><strong>&copy;{{ year }}</strong> Youpaq SA, CH-1806 St-Légier</p>
        </div>
        <div class="footer-social col-12 col-md-2">
          <div>
            <p class="social-logo">
              <a href="https://www.facebook.com/Youpaq-937281423015550" target="_blank">
                <img src="@/assets/social-facebook.png" width="50" height="50" :alt="$t('content.footer.facebook')" :title="$t('content.footer.facebook')" />
              </a>
              <a href="https://www.instagram.com/youpaq_les_courses_a_la_maison" target="_blank">
                <img src="@/assets/social-instagram.png" width="50" height="50" :alt="$t('content.footer.instagram')" :title="$t('content.footer.instagram')" />
              </a>
            </p>
            <p><a href="mailto:info@youpaq.com">info@youpaq.com</a></p>
            <PaiementLogos />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LangSelector from '@/components/LangSelector'
import PaiementLogos from '@/components/CartCheckout/PaiementLogos.vue'

export default {
  name: 'Footer',
  components: { LangSelector, PaiementLogos },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    year: (new Date()).getFullYear()
  }),
  computed: {
    lang() {
      return this.$store.getters['lang/current']
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

footer {
  background-color: #3E3E3D;
  font-size: 20px;
  color: white;
  display: flex;
  padding-top: 20px;
  padding-bottom: 60px;
  text-align: left;
  position: relative;
  z-index: 201;
}
footer:after {
  content: ' ';
  display: block;
  background-color: #3E3E3D;
  position: absolute;
  top: -75px;
  bottom: 150px;
  right: 0;
  left: 0;
  transform: skewY(7deg);
  z-index: 202;

  @include media-breakpoint-up(md) {
    top: -45px;
    transform: skewY(3deg);
  }

  @include media-breakpoint-up(lg) {
    top: -65px;
  }
}
.footer-logo {
  z-index: 203;
  text-align: center;

  @include media-breakpoint-up(md) {
   text-align: left;
  }
  img {
    max-height: 85px;
  }
}
.footer-menu {
  padding-top: 40px;
  z-index: 204;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-top: 0;
    text-align: left;
  }

  h4 {
    font-weight: 700;
    margin-top: 0;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: 300;
  }

  ul {
    list-style-type: none;
    padding: 0 80px;
    margin: 0;
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  p {
    font-size: 18px;
  }
}
.footer-social {
  padding-top: 40px;
  z-index: 204;
  color: white;
  font-size: 18px;
  display: flex;
  text-align: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: normal;
    text-align: left;
    align-items: flex-end;
  }

  a {
    color: white;
    text-decoration: none;
  }

  .social-logo {
    a {
      margin-right: 10px;
    }
    a:last-child {
      margin-right: 0;
    }
  }
}
footer li {
  padding: 2px 0;
  font-size: 18px;
}
</style>
