<template>
  <div id="app">
    <AppLoader />
    <component :is="$route.meta.headerCmp || 'Header'" v-bind="$route.params" />
    <router-view />
    <Footer v-bind="$route.params" />
    <ModalUi />
    <Messages />
  </div>
</template>

<script>
import Header from '@/components/Layout/Header'
import HomeHeader from '@/components/Layout/HomeHeader'
import Footer from '@/components/Layout/Footer'
import ModalUi from '@/components/MenusAndPopin/ModalUi'
import Messages from '@/components/MenusAndPopin/Messages'
import AppLoader from '@/components/MenusAndPopin/AppLoader'
import { setOptions, bootstrap } from 'vue-gtag'

export default {
  name: 'App',
  components: {
    Header,
    HomeHeader,
    Footer,
    ModalUi,
    Messages,
    AppLoader
  },
  created() {
    this.$store.dispatch('msg/start')
    // bootstrap google analytics
    if (this.$config.googleAnalyticsEnabled) {
      setOptions({
        params: {
          send_page_view: true
        },
        config: { id: this.$config.googleAnalyticsId }
      })
      return bootstrap()
        .catch(err => {
          // eslint-disable-next-line
          console.error('unable to load google analytics.', err)
        })
    }
  },
  beforeDestroy() {
    this.$store.dispatch('msg/stop')
  }
}
</script>

<style lang="scss">
@import './assets/styles/youpaq.scss';

body {
  margin: 0;
  font-size: 16px;
}

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3E3E3D;
}
/* sidewidget mobile slider */
#side-widget .agile__actions {
  position: relative;
}
#side-widget .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  bottom: 120px;
}
#side-widget .agile__nav-button--prev {
  left: -50px;
}
#side-widget .agile__nav-button--next {
  right: 70px;
}

/* Simple CSS to Fix Form Zooming on iphone */
input, select { font-size: 100%; }

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}
</style>
