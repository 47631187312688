import Vue from 'vue'

const Cart = Vue.youpaqModel.Cart

export default {
  namespaced: true,

  state: {
    cart: null,
    email: null
  },

  getters: {
    cart: state => state.cart,
    shops: state => state.cart?.shops || [],
    shop: state => shopId => (state.cart?.shops || []).find(shop => shop.id === shopId),
    offers: state => shopId => {
      if (shopId) {
        return (state.cart?.shops || []).find(shop => shop.id === shopId) || []
      }
      return (state.cart?.shops || []).reduce((acc, shop) => {
        return acc.concat(shop.offers || [])
      }, [])
    }
  },

  mutations: {
    email(state, email) {
      state.email = email
    },

    reset(state) {
      state.cart = null
    }
  },

  actions: {
    async get({ state }, { partnerId, force }) {
      if (!partnerId && state.cart?.partner_id) {
        partnerId = state.cart.partner_id
      }
      if (!partnerId) {
        return
      }
      if (!force && state.cart && state.cart.partner_id === partnerId) {
        return state.cart
      }
      const params = {}
      if (state.email) {
        params.email = state.email
      }
      const res = await Vue.prototype.$api.get('/cart', { params })
      state.cart = new Cart(res.data)

      return state.cart
    },

    async addOffer({ dispatch }, { offerId, shopId, quantity, selectedQuantity, portions }) {
      const res = await Vue.prototype.$api.post('/cart/offers', {
        offer: offerId,
        shop: shopId,
        hub: '',
        quantity: quantity || quantity === 0 ? Number(quantity) : quantity,
        selectedQuantity,
        portions: portions || portions === 0 ? Number(portions) : portions
      })

      await dispatch('get', { force: true })

      return res.data.offer
    },

    async updateOffer({ dispatch }, { offerId, shopId, quantity, selectedQuantity, portions }) {
      if (quantity === 0) {
        return dispatch('removeOffer', { offerId, shopId })
      }

      const res = await Vue.prototype.$api.put(`/cart/offers/${offerId}`, {
        shop: shopId,
        hub: '',
        quantity: quantity || quantity === 0 ? Number(quantity) : quantity,
        selectedQuantity,
        portions: portions || portions === 0 ? Number(portions) : portions
      })

      await dispatch('get', { force: true })

      return res.data.offer
    },

    async removeOffer({ state, dispatch }, { offerId, shopId }) {
      await Vue.prototype.$api.delete(`/cart/offers/${offerId}`, {
        params: {
          shop: shopId
        }
      })
      await dispatch('get', { force: true })

      if (!state.cart.shops.length) {
        // remove cart if it is empty
        await Vue.prototype.$api.delete('/cart')
        await dispatch('get', { force: true })
      }
    },

    async resetDelivery({ dispatch }) {
      await Vue.prototype.$api.post('/cart/deliveryTemp', {
        salepoint: null,
        type: null,
        mode: null,
        date: null
      })

      await dispatch('get', { force: true })
    },

    async setDelivery({ dispatch }, { hubId, mode, date }) {
      await Vue.prototype.$api.post('/cart/deliveryTemp', {
        salepoint: hubId,
        type: 'hub',
        mode: mode,
        date: date
      })

      await dispatch('get', { force: true })
    },

    async setDeliveryNote({ state }, { note }) {
      await Vue.prototype.$api.post('/cart/deliveryTemp', {
        ...state.cart.delivery,
        note: note
      })
      state.cart.delivery.note = note
    },

    async checkout({ commit }, { cvc }) {
      const data = {}
      if (cvc) {
        data.cvc = cvc
      }

      await Vue.prototype.$api.post('/cart/checkout', data)
      commit('reset')
    },

    async checkCheckout() {
      const res = await Vue.prototype.$api.post('/cart/checkout-check')
      return res.data
    },

    async getCheckoutParams() {
      const res = await Vue.prototype.$api.get('/cart/checkout-params')
      return res.data
    },

    async feedback(context, { rating, message }) {
      await Vue.prototype.$api.post('/feedbacks', {
        rating,
        message
      })
    },

    async getPaymentError(context, { id }) {
      const res = await Vue.prototype.$api.get(`/cart/checkout-error/${id}`)
      return res.data
    },

    async recover(context, { token }) {
      const res = await Vue.prototype.$api.post('cart/recover', { token })
      return res.data
    }
  }
}
