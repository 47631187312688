/* eslint-disable */
import lodash from 'lodash'
import moment from 'moment'

/**
 * Mixin
 *
 */
var cache = {};
var Mixin = function (options) {

    this.getId = function () {
        return this.id;
    };

    this.isHub = function () {
        return this.shops !== undefined;
    };

    this.getDisplayName = function () {
        return this.name;
    };

    this.setDeliveryMode = function (code) {
        this.deliveryMode = code;
    };

    /**
     * Récupère le mode de livraison. Si TRUE est passé, va strictement
     * regardé la propriété devliveryMode, même si elle ne vaut rien. Autrement
     * ça va retourner une valeur par défaut
     *
     * @param {Boolean} [raw]
     * @return {String}
     */
    this.getRawDeliveryMode = function (raw) {
        if (raw) {
            return this.deliveryMode;
        }
        return (this.deliveryMode || 'pickup');
    };

    this.getActiveDeliveryMode = function (strict) {
        if (!this.getRawDeliveryMode(strict)) {
            return null;
        }
        return this.getDeliveryMode(this.getRawDeliveryMode(strict));
    };

    this.setDeliveryDate = function (date) {
        this.deliveryDate = date;
    };

    this.getDeliveryDate = function () {
        if (!this.deliveryDate) {
            return null;
        }
        return moment(this.deliveryDate);
    };

    this.getDeliveryDateRaw = function () {
        return this.deliveryDate;
    };

    /**
     * Mise en attente de tous les articles d'un point de vente dans le panier (ces articles
     * ne seront pas commandés si la commande est confirmée. Ils resteront dans le panier.
     *
     * @param {Boolean} status
     */
    this.setHold = function (status) {
        this.hold = status;
    };

    /**
     * Est-ce que ce point de vente est en attente au niveau de la commande (voir ci-dessus).
     *
     * @returns {Boolean}
     */
    this.isHold = function () {
        return this.hold;
    };

    /**
     * Voir services/salepoint:getNowForDelivery et ici this.getDeliveryDelay()
     */
    this.setFrontDeliveryDelay = function (delay) {
        delay = delay < 0 ? 0 : delay;
        // on arrondi au quart d'heure.
        delay = Math.ceil(delay / 15) * 15;
        cache[this.id + this.isHub()] = delay < 0 ? 0 : delay;
    };

    /**
     * Retourne le délai de préparation/livraison de commande, en minutes.
     *
     * @param {Boolean} skipFrontDelay voir services/salepoint:getNowForDelivery
     * @returns {number}
     */
    this.getDeliveryDelay = function (skipFrontDelay) {
        // utilisé pour le J+1, afin de comptabiliser le temps de préparation
        // déjà fait pour le jour sauté (voir services/salepoint:getNowForDelivery())
        if (!skipFrontDelay && cache[this.id + this.isHub()] !== undefined && cache[this.id + this.isHub()] !== null) {
            return cache[this.id + this.isHub()];
        }

        // cas particulier du pickup
        if (this.getRawDeliveryMode() === 'pickup') {
            return this.getPickupOrderDelay();
        }
        var active_delivery_mode = this.getActiveDeliveryMode(),
            delivery_modes = this.getDeliveryModes();

        // ça arrive qu'on ne passe pas une liste de modes, mais juste un...
        if (!active_delivery_mode) {
            if (delivery_modes && delivery_modes.delay) {
                return Number(delivery_modes.delay) * 60;
            }
            return 0;
        }

        return Number(active_delivery_mode.delay) * 60;
    };

    /**
     * Récupération du prix minimum de commande pour le mode de livraison donné
     *
     * @param {String} [mode] Le mode de livraison. Si aucun mode, essaiera de prendre le mode actif
     * @param {Object} [cart] le panier complet, pour les subtilités du minimumprice
     * @returns {Number}
     */
    this.getMinimumPrice = function (mode, cart) {
        var active_delivery_mode = mode ? this.getDeliveryMode(mode) : this.getActiveDeliveryMode(),
            delivery_modes = this.getDeliveryModes();

        // ça arrive qu'on ne passe pas une liste de modes, mais juste un...
        if (!active_delivery_mode) {
            if (delivery_modes && delivery_modes.minimumOrderPrice) {
                if (cart && cart.getShops().length === 1 && delivery_modes.minimumOrderPriceSolo > 0) {
                    return Number(delivery_modes.minimumOrderPriceSolo);
                }
                return Number(delivery_modes.minimumOrderPrice);
            }
            return 0;
        }
        if (cart && cart.getShops().length === 1 && active_delivery_mode.minimumOrderPriceSolo > 0) {
            return Number(active_delivery_mode.minimumOrderPriceSolo);
        }
        return Number(active_delivery_mode.minimumOrderPrice);
    };

    this.getHomeDeliveryFee = function () {
        var mode = this.getDeliveryMode('pickup'),
            amount = this.getTotalPrice(),
            slot = mode && lodash.find(mode.slots, function (s) {
                return s.fee && amount >= s.min && (amount <= s.max || s.max === 0);
            });

        if (!slot) {
            return null;
        }
        return slot.fee;
    };

    return this;
};

Mixin.mixins = {
    'salepoint': {}
};

export default Mixin;
