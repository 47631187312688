<template>
  <div>
    <h6>{{ $t('base.spamTitle') }}</h6>
    <div v-marked="$t('base.spam')"></div>
  </div>
</template>

<script>
export default {
  name: 'SpamMessage'
}
</script>
