import Vue from 'vue'

Vue.prototype.$err = function(err, data) {
  data = data || {
    returnMessage: false,
    skip: false
  }

  let message = ''
  if (err?.response?.data?.message) {
    message = err.response.data.message
  } else if (err?.response?.message) {
    message = err.response.message
  } else if (err?.message) {
    message = err.message
  } else if (err?.request) {
    message = 'Request error'
  } else {
    message = 'Unknown request'
  }
  if (data.returnMessage) {
    return message
  }
  this.$store.commit('msg/show', {
    message: message,
    type: 'error',
    ...data
  })
  if (data.skip) {
    return
  }
  throw err
}

Vue.prototype.$success = function(res, data) {
  data = data || {}
  const msg = data.message
  delete data.message
  this.$store.commit('msg/show', {
    message: msg,
    type: 'info',
    ...data
  })
}

Vue.prototype.$warn = function(data) {
  data = data || {}
  const msg = data.message
  delete data.message
  this.$store.commit('msg/show', {
    message: msg,
    type: 'warn',
    ...data
  })
}
