module.exports = {
  g: {
    g: 1,
    '100g': 50,
    dg: 20,
    kg: 50
  },
  '100g': {
    g: 0.1,
    '100g': 0.1,
    dg: 1,
    kg: 1
  },
  dg: {
    g: 0.1,
    '100g': 1,
    dg: 1,
    kg: 1
  },
  kg: {
    g: 0.01,
    '100g': 0.1,
    dg: 0.1,
    kg: 0.1
  },
  ml: {
    ml: 1,
    cl: 1,
    dl: 10,
    l: 50
  },
  cl: {
    ml: 0.1,
    cl: 1,
    dl: 1,
    l: 10
  },
  dl: {
    ml: 0.1,
    cl: 0.1,
    dl: 1,
    l: 1
  },
  l: {
    ml: 0.01,
    cl: 0.1,
    dl: 0.5,
    l: 0.1
  }
}
