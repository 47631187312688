/* eslint-disable */
import lodash from 'lodash'
import moment from 'moment'

/**
 * Mixin
 *
 */
var Mixin = function (options) {

    this.getId = function () {
        return this.id;
    };

    this.getDisplayName = function () {
        return this.name;
    };

    this.getType = function () {
        return this.type;
    };

    this.getStreet = function () {
        return this.coordinate.street;
    };

    this.getZipCity = function () {
        return this.coordinate.zip + ' ' + this.coordinate.city;
    };

    this.getPhone = function () {
        return this.coordinate.phone;
    };

    this.getEmail = function () {
        return this.coordinate.email;
    };

    this.getGpsCoords = function () {
        return this.coordinate.gps;
    };

    this.getRandomPartner = function () {
        if (this.partners.length === 0) {
            return null;
        }
        var key = lodash.random(this.partners.length - 1);
        return this.partners[key];
    };

    this.isOpenToday = function () {
        return this.isOpen(moment());
    };

    this.isOpen = function (date, timetable) {
        if (!this.timetable && !timetable) {
            return null;
        }
        if (lodash.isString(date)) {
            date = moment(date);
        }
        var today = lodash.toLower(date.locale('en').format('dddd')),
            today_timetable = lodash.find((timetable || this.timetable), {name: today});

        return today_timetable && today_timetable.hours && today_timetable.hours.length > 0;
    };

    this.getLastOpeningHoursToday = function () {
        if (!this.timetable) {
            return null;
        }
        var today = lodash.toLower(moment().locale('en').format('dddd')),
            timetable = lodash.find(this.timetable, {name: today}),
            hours = null;
        lodash.forEach(timetable.hours, function (t) {
            hours = t;
        });
        return hours;
    };

    this.getNowForDelivery = function (cart) {
        var date = moment();
        if (this.deliverPickupTomorrowAtBest(cart)) {
            // ce point de vente ne livre jamais aujourd'hui. On va donc
            // simuler que "now" = demain à minuit, pour récupérer la 1ère
            // heure possible de livraison demain
            date.add(1, 'days').startOf('hour').hours(0);
        }
        return date;
    };

    this.getOpeningHoursToday = function (date) {
        return this.getOpeningHours(moment());
    };

    this.getOpeningHours = function (date, timetables) {
        timetable = timetable ? timetables.timetable : this.timetable
        if (!timetable) {
            return null;
        }
        // on clone pour éviter de passer en anglais la date donnée
        var today_raw = moment(date).clone(),
            today = lodash.toLower(today_raw.locale('en').format('dddd')),
            timetable = lodash.find(timetable, {name: today}),
            special = this.isSpecialTimetable(today_raw.format('YYYY-MM-DD'), timetables?.closed);

        if (this.isClosed(today_raw.format('YYYY-MM-DD'), timetables?.closed)) {
            timetable.hours = [];
        } else if (special) {
            timetable = special;
        }

        return lodash.map(timetable.hours, function (t) {
            return t.start + ' - ' + t.end;
        }).join(', ');
    };

    /**
     * Génère un objet avec les jours ouvrables groupés par horaire
     * similaires.
     *
     * {
     *   'lun-mer': '07h30 - 19h30',
     *   'jeu': '8h00 - 17h00',
     *   'ven-sam': '06h00 . 12h00'
     * }
     *
     * @return {void}
     */
    this.initOpeningHoursGrouped = function () {
        this._groupedHours = [];
        const today = moment().locale('en').format('dddd');
        const data = lodash.reduce(this.timetable, (acc, day) => {
            const hours = lodash.map(day.hours, h => `${h.start} - ${h.end}`);
            const last = acc.length && acc[acc.length - 1];

            if (last && last.key === JSON.stringify(hours)) {
                last.lastDay = day.name;
                last.today = last.today || day.name === today
            } else {
                acc.push({
                    firstDay: day.name,
                    lastDay: day.name,
                    hours: hours,
                    key: JSON.stringify(hours),
                    today: day.name === today
                });
            }
            return acc;
        }, []);

        this._groupedHours = data;
    };

    this.getOpeningHoursGrouped = function () {
        if (!this.timetable) {
            return null;
        }
        if (!this._groupedHours) {
            this.initOpeningHoursGrouped();
        }
        return this._groupedHours;
    };

    this.initOpeningDays = function () {
        var date = moment().locale('en'),
            days = [],
            i,
            today,
            timetable,
            special,
            home_delivery_mode = this.getDeliveryMode('home'),
            home_delivery = home_delivery_mode && home_delivery_mode.enabled;

        for (i = 0; i < 7; i += 1) {
            if (!this.isClosed(date.format('YYYY-MM-DD'))) {
                today = lodash.toLower(date.format('dddd'));
                timetable = lodash.find(this.timetable, { name: today });
                special = this.isSpecialTimetable(date.format('YYYY-MM-DD'));
                // on fait le check sur les horaires delivery home seulement
                // si ce mode est activé
                if ((special || timetable || { hours: [] }).hours.length || (home_delivery && (special || timetable || { delivery: [] }).delivery.length)) {
                    days.push({
                        index: Number(date.format('d')) || 8, // on s'assure le dimanche en dernier
                        date: moment(date).locale(moment().locale())
                    });
                }
            }
            date.add(1, 'day');
        }
        return lodash.chain(days)
            .sortBy('index')
            .map(function (day) {
                return lodash.upperFirst(day.date.format('dd'));
            })
            .uniq()
            .value();
    };

    this.getOpeningDays = function () {
        if (!this.timetable) {
            return null;
        }
        if (!this._openingDays) {
            this._openingDays = this.initOpeningDays();
        }
        return this._openingDays;
    };

    this.getDeliveryMode = function (mode) {
        var modes = this.getDeliveryModes();
        return lodash.find(modes, {code: mode});
    };

    this.getMinimumFees = function () {
        var modes = this.getDeliveryModes();
        for (var i = 0; i < modes.length; i += 1) {
            if (modes[i].slots) {
                for (var j = 0; j < modes[i].slots.length; j += 1) {
                    if (modes[i].slots[j].fee) {
                        return modes[i].slots[j].fee;
                    }
                }
            }
            if (modes[i].fees) {
                return modes[i].fees;
            }
        }
        return 0;
    };

    this.getOwner = function () {
        return this.Owner;
    };

    this.getOwnerId = function () {
        return this.profile.id;
    };

    /**
     * Vérification si une date est une fermeture exceptionnelle du magasin
     *
     * @param {String} [checkdate] La date à vérifier, au format [YYYY-MM-DD]
     * @returns {boolean}
     */
    this.isClosed = function (checkdate, closed) {
        var matching;
        closed = closed || this.closed
        if (!closed) {
            return false;
        }
        checkdate = checkdate || moment();
        if (!lodash.isString(checkdate)) {
            checkdate = checkdate.format('YYYY-MM-DD');
        }
        if (closed.periods && closed.periods.length > 0) {
            matching = lodash.filter(closed.periods, function (p) {
                return checkdate >= p.start && checkdate <= p.end;
            });
            if (matching.length > 0) {
                return true;
            }
        }
        if (closed.specialdays && closed.specialdays.length > 0) {
            matching = lodash.filter(closed.specialdays, function (sd) {
                return sd.date === checkdate && sd.hours.length === 0;
            });
            if (matching.length > 0) {
                return true;
            }
        }
        return false;
    };

    /**
     * Vérification s'il y a un horaire spécial à la date donnée. Si oui, retourne ledit horaire
     *
     * @param {String} [checkdate] La date à vérifier, au format [YYYY-MM-DD]
     * @returns {Boolean|Array}
     */
    this.isSpecialTimetable = function (checkdate, closed) {
        closed = closed || this.closed
        if (!closed || !closed.specialdays || closed.specialdays.length === 0) {
            return false;
        }
        checkdate = checkdate || moment().format('YYYY-MM-DD');

        return lodash.find(closed.specialdays, function (sd) {
            return sd.date === checkdate;
        });
    };

    /**
     * Détermine si en pickup, la livraison se fera au mieux le jour
     * suivant.
     *
     * si la méthode est déjà définie dans la classe parente, on ne
     * l'écrase pas
     *
     * @param {Boolean} strictWithClose si TRUE, tient compte de l'horaire pour les checks
     * @return {Boolean}
     */
    this.deliverPickupTomorrowAtBestBase = function (strictWithClose) {
        var delivery_mode = this.getDeliveryMode('pickup');
        if (!delivery_mode) {
            return false;
        }
        if (strictWithClose && !delivery_mode.nextdayFromClose) {
            // en principe, on est là dans un calcul HUB et on veut être
            // sûr que le shop veut livrer en J+1, mais en tenant compte
            // de ses horaires d'ouverture (par défaut on s'en fiche)
            return false;
        }
        // le J+2 peut être actif, mais pas pour l'actuelle ville. C'est
        // encore une autre spécialité...
        if (strictWithClose && config.currentContext && delivery_mode.partnersNextdayFromClose && !delivery_mode.partnersNextdayFromClose[config.currentContext.id]) {
            return false;
        }

        return delivery_mode.nextday;
    };
    if (!this.deliverPickupTomorrowAtBest) {
        this.deliverPickupTomorrowAtBest = this.deliverPickupTomorrowAtBestBase;
    }
    return this;
};

Mixin.mixins = {
    'images': {
        multiple: true
    },
    'logo': {}
};

Mixin.relations = {
    Owner: 'Profile'
};

export default Mixin;
