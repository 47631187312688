/* eslint-disable camelcase */
import lodash from 'lodash'
import moment from 'moment'
import decimal from './decimal'
import units from './units'

const number_format = (input, decimal, thousandSeparator) => {
  decimal = decimal !== undefined ? decimal : 2
  thousandSeparator = thousandSeparator !== undefined ? thousandSeparator : "'"
  return input.toFixed(decimal).replace(/./g, (c, i, a) => {
    return i && c !== '.' && ((a.length - i) % 3 === 0) ? thousandSeparator + c : c
  })
}

const formatPrice = (price, article) => {
  let unitprice = Number(price)
  const unit = article.getUnit()
  const tarification_unit = article.getTarificationUnit()

  // si l'article est en vrac, on n'a rien de plus à faire...
  if (article.isRawPackaged()) {
    return number_format(unitprice, 2)
  }

  if (article.isWeightPriced()) {
    unitprice = decimal.mul(units.convert(article.getPackaging().quantity || 1, unit, tarification_unit), unitprice)
    // #YPQ-327 on arrondit aux 5ct
    unitprice = decimal.round5(unitprice)
  }

  // #YPQ-327: on pourrait calculer un prix supérieur s'il y a une
  // marge sur le poids, mais non. On reste sur le prix normal indiqué
  // donc on ne fait pas d'autres opérations

  return number_format(unitprice, 2)
}

/**
 * Récupération du prix de base de l'article, en tenant compte d'éventuelles promotions
 *
 * @param {Boolean} omitPromotion Si vrai, ne tiendra pas compte des promotions
 * @param {Moment} date La date pour laquelle on aimerait connaître le prix
 * @param {Array} offers un tableau d'offres pas dans l'instance d'article
 * @returns {Array|Number}
 */
const getSellPrice = (omitPromotion, date, offers) => {
  const prices = lodash.chain(offers)
    .map(offer => omitPromotion ? offer.getBaseRawPrice() : offer.getRawPrice(date))
    .uniq()
    .value()
    .sort()

  if (prices.length > 1) {
    return [lodash.first(prices), lodash.last(prices)]
  }

  return prices[0]
}

const display = lodash.template(
  '<div class="price-display">' +
  '<div class="clearfix">' +
  '<div class="price-line">' +
  '<span<% if (original_prices.length > 0) { %> class="promotion"<% } %>><%= prefix %>CHF <%= prices.join("-") %><% if (unit) { %>/<%= unit %><% } %></span>' +
  '<% if (original_prices.length > 0 && original_prices[0] !== original_prices[original_prices.length - 1]) { %>' +
  ' <span class="original-price">(<%= original_prices.join("-") %><% if (unit) { %>/<%= unit %><% } %>)</span>' +
  '<% } %>' +
  '</div>' +
  '<% if (promo_rate) { %>' +
  '<div class="rate-line">' +
  '<span class="promo-rate"><i class="ypq ypq-discount"></i><span class="rate"><%= promo_rate %>%</span></span>' +
  '</div>' +
  '<% } %>' +
  '</div>' +
  '<% if (weightprice) { %>' +
  '<div class="weightprice-line">' +
  '<span class="weightprice">(<%= weightprice %>)</span>' +
  '</div>' +
  '<% } %>' +
  '</div>'
)

const filter = (article, omitPromotion, date, offers, withWeightPrice, prefix) => {
  date = date ? moment(date, 'YYYY-MM-DD') : moment()
  const packaging = article.getPackaging()
  const tarification_unit = packaging.price_quantity_unit
  const price = getSellPrice(omitPromotion, date, offers || article.getResellerOffers())
  const unitprice = {
    prices: [],
    original_prices: [],
    promo_rate: null,
    unit: null,
    weightprice: false,
    prefix: prefix || ''
  }
  let promo_offer
  const process_prices = price => {
    if (lodash.isArray(price)) {
      return lodash.flatten(lodash.map(price, p => formatPrice(p, article)))
    }
    let prices = formatPrice(price, article)
    if (!lodash.isArray(prices)) {
      prices = [prices]
    }
    return prices
  }

  // prix
  unitprice.prices = process_prices(price)

  // promotion
  if (article.hasPromotion(date, offers) && !omitPromotion) {
    promo_offer = article.getFirstPromotionedOffer(date, offers)
    if (promo_offer.getPromotion().display === 'percentage') {
      unitprice.promo_rate = Math.round(100 - (promo_offer.getPromotion().getPrice() / promo_offer.getBaseRawPrice() * 100))
    }

    // récupération des prix originaux
    unitprice.original_prices = process_prices(getSellPrice(true, undefined, offers || article.getResellerOffers()))
  }

  // packaging en vrac
  if (article.isRawPackaged() && tarification_unit) {
    unitprice.unit = tarification_unit
  }

  // prix au poids
  if (withWeightPrice && !article.isRawPackaged()) {
    unitprice.weightprice = getWeightPrice(article, (offers || article.getResellerOffers())[0].getRawPrice(date))
  }

  return display(unitprice)
}

const cartUnitPrice = (article, offers, refPrice, omitPromotion, date) => {
  const price = refPrice || getSellPrice(omitPromotion, date, offers || article.getResellerOffers())
  const unitprice = formatPrice(price, article)

  return Number(unitprice)
}

const getPrice = (article, quantity, offers, refPrice, date, omitPromotion) => {
  const unit = article.getUnit()
  const tarification_unit = article.getTarificationUnit()
  let price

  quantity = quantity || 1

  if (article.isWeightPriced()) {
    if (article.isRawPackaged()) {
      price = decimal.mul(units.convert(quantity, unit, tarification_unit), cartUnitPrice(article, offers, refPrice, omitPromotion, date))
      // #YPQ-327: on arrondit aux 5ct
      return decimal.round5(price)
    }
  }
  return decimal.mul(quantity, cartUnitPrice(article, offers, refPrice, omitPromotion, date))
}

const getPriceFromQuantityAndUnitprice = (quantity, unitprice, article) => {
  const unit = article.getUnit()
  const tarification_unit = article.getTarificationUnit()
  let price

  // si l'article est en vrac, on n'a rien de plus à faire...
  if (article.isRawPackaged()) {
    price = decimal.mul(units.convert(quantity, unit, tarification_unit), unitprice)
    // #YPQ-327: on arrondit aux 5ct
    return decimal.round5(price)
  }

  return decimal.mul(unitprice, quantity)
}

/**
 * Récupère le prix au poids, litre, etc. Genre "50.- le kg"
 *
 * @param {Article} article
 * @param {Float} price
 * @return {String}
 */
const getWeightPrice = (article, price) => {
  if (!article.isWeightPriced()) {
    return false
  }
  return number_format(price, 2) + ' / ' + article.getTarificationUnit()
}

/**
 * Récupération du prix maximum possible pour un article ayant une marge
 */
const getMaxPrice = (article, offers, refPrice) => {
  // articles standards
  if (!article.hasVariableWeight()) {
    return cartUnitPrice(article, offers, refPrice)
  }

  // article en vrac, le prix max, c'est le prix au kg, point final.
  if (article.isRawPackaged()) {
    return (refPrice || getSellPrice(undefined, undefined, offers || article.getResellerOffers()))
  }

  const price = refPrice || getSellPrice(undefined, undefined, offers || article.getResellerOffers())
  const unitprice = decimal.mul(units.convert((article.getPackaging().quantity || 1), article.getUnit(), article.getTarificationUnit()), price)
  const margin = article.getPackaging().margin

  // là on va ajouter la marge au prix moyen et ça nous sort le prix max possible.
  return decimal.mul(unitprice, ((100 + margin) / 100))
}

/**
 * Même cirque que getMaxPrice, mais pour la quantité d'un article en vrac avec marge de coupe
 */
const getMaxQuantity = (article, quantity) => {
  if (!article.hasVariableWeight()) {
    return quantity
  }

  const margin = article.getPackaging().margin

  // là on va ajouter la marge à la quantité passée et ça nous sort la quantité maximum.
  return decimal.mul(quantity, ((100 + margin) / 100))
}

/**
 * Même cirque que getMaxQuantity, mais pour la quantité minimale
 */
const getMinQuantity = (article, quantity) => {
  if (!article.hasVariableWeight()) {
    return quantity
  }

  const margin = article.getPackaging().margin

  // là on va soustraire la marge à la quantité passée et ça nous sort la quantité minimum.
  return decimal.mul(quantity, ((100 - margin) / 100))
}

/**
 * Affichage du packaging d'un article
 *
 * @param {Article} article Un modèle article bien complet
 * @param {Function} translator Un outil de traduction (angular-translate filter ou i18next t)
 * @returns {string}
 */
const packaging = (article, translator) => {
  const packaging = article.getPackaging()
  let type = packaging.type && packaging.type !== 'piece' ? packaging.type : ''
  let weight = ''
  let original_type = ''

  if (!packaging.weight_hidden) {
    if (article.hasVariableWeight() && !article.isRawPackaged()) {
      weight = article.getMinWeight() + packaging.unit + ' - ' + article.getMaxWeight() + packaging.unit
    } else if (article.getWeight()) {
      weight = article.getWeight() + packaging.unit
    }
  }

  original_type = type.replace(' ', '')
  if (type) {
    type = original_type
  }
  if (type === 'envrac') {
    return translator('article.packaging.types.envrac')
  }

  if (type && weight) {
    if (packaging.type === 'piece') {
      type = translator('article.packaging.types.piece')
    } else {
      type = translator(`packaging.${packaging.type}`)
    }
    return type + ' de ' + weight
  }
  return type + weight
}

const portions = (offer, dbg) => {
  if (!offer.portions) {
    return ''
  }
  const pkg = offer.getArticle().packaging
  if (pkg.type !== 'en vrac' && pkg.weight_hidden) {
    return ''
  }
  const text = offer.portions + 'x' + offer.selectedQuantity + offer.getArticle().getUnit()
  return text
}

export default {
  portions,
  packaging,
  getMinQuantity,
  getMaxQuantity,
  getMaxPrice,
  getPriceFromQuantityAndUnitprice,
  getWeightPrice,
  getPrice,
  cartUnitPrice,
  filter
}
