<template>
  <div class="cart-popin" :style="{ height: `${height}px` }">
    <div
      class="cart-popin-button"
      @click="closeCartButton"
    />
    <div class="row">
      <h2>{{ $t('cartSmall.title') }}</h2>
    </div>
    <!-- COMMERCE 1 -->
    <div v-for="shop in shops" :key="shop.id" class="commerce row">
      <div class="col-12 commerce-cart">
        <div class="commerce-name">
          {{ shop.name }} ({{ shop.offers.length }})<br>
        </div>
        <CartPopinLine
          v-for="offer in shop.offers"
          :key="offer.id"
          :offer="offer"
          :shop-id="shop.id"
        />
        <div v-if="!shop.minimumReached(null, cart)" class="commande-minimum-msg">
          <img src="@/assets/attention-white.svg">
          {{ $t('cartSmall.minimum', { amount: $options.filters.price(shop.getMinimumPrice(null, cart)) }) }}
        </div>
      </div>
    </div>
    <div v-if="hubMinimumFees" class="commerce row">
      <div class="col-12 commerce-cart">
        <div class="commerce-name">{{ $t('cartSmall.fees') }}</div>
        <CartPopinLine
          :offer="{ article: { title: { fr: $t('cartSmall.delivery') } }, price: hubMinimumFees }"
          price-only
        />
      </div>
    </div>
    <div v-else-if="shops.length && !hubMinimumFees" class="commerce row">
      <div class="col-12 commerce-cart">
        <CartPopinLine
          :offer="{ article: { title: { fr: $t('cartSmall.deliveryFees') } }, price: 0 }"
          :offer-price-text="$t('cartSmall.included')"
          price-only
        />
      </div>
    </div>
    <div v-if="!minimumReached" class="py-3 commande-minimum-end-msg">
      <img src="@/assets/attention-white.svg">
      <h2 v-if="shopsReachedButNotPartner">{{ $t('cartSmall.cityNotReached', { amount: $options.filters.price(cart.getMinimumPrice()) }) }}</h2>
      <h2 v-else>{{ $t('cartSmall.shopNotReached') }}</h2>
    </div>
    <!-- TOTALS FOOTER -->
    <div v-if="cart" class="totals-footer">
      <div v-if="!cart.minimumReached()">
        <span
          v-marked="$t('cartSmall.availabilityMin', { amount: $options.filters.price(cart.getMinimumPrice()) })"
          class="text"
        >
        </span>
      </div>
      <span v-else class="text">
        {{ $t('cartSmall.availability') }}
      </span>
      <div class="livraison-date">
        {{ firstDeliveryDate | date('dddd Do MMMM YYYY') }}
        <CartHoraireShopsPopupButton :partner-id="partnerId" />
      </div>
      <div class="commander">
        <div class="total-amount">
          {{ (cart.getTotalPrice() + hubMinimumFees) | price }}
        </div>
        <router-link :to="{ name: 'cart' }">
          <Button
            color="white-text-red"
            :text="$t('cartSmall.submit')"
            :disabled="disabled"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CartHoraireShopsPopupButton from '@/components/MenusAndPopin/CartHoraireShopsPopupButton'
import CartPopinLine from '@/components/MenusAndPopin/CartPopinLine'
import Button from '@/components/UI/Button.vue'
import ResizeMixin from '@/mixins/ResizeMixin'

export default {
  name: 'CartPopin',
  components: {
    Button,
    CartHoraireShopsPopupButton,
    CartPopinLine
  },
  mixins: [ResizeMixin],
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    loading: false,
    firstDeliveryDate: null
  }),
  computed: {
    shops() {
      return this.$store.getters['cart/shops']
    },
    cart() {
      return this.$store.getters['cart/cart']
    },
    disabled() {
      return !this.shops.length || !this.minimumReached
    },
    minimumReached() {
      if (!this.shops.length) {
        return true
      }
      const salepoints = this.cart.getUnHoldSalepointsWithHubs()
      const notReached = salepoints.filter(sp => !sp.shop.minimumReached(null, this.cart))
      if (notReached.length) {
        return false
      }
      return this.cart.minimumReached()
    },
    shopsReachedButNotPartner() {
      if (!this.shops.length) {
        return false
      }
      const salepoints = this.cart.getUnHoldSalepointsWithHubs()
      const notReached = salepoints.filter(sp => !sp.shop.minimumReached(null, this.cart))
      if (notReached.length) {
        return false
      }
      return !this.cart.minimumReached()
    },
    hubMinimumFees() {
      const hubs = this.$store.getters['partner/hubs']
      return hubs.length && hubs[0].getMinimumFees()
    }
  },
  watch: {
    partnerId() {
      this.load()
    },
    '$route.name'() {
      this.closeCartButton()
    },
    'shops.length'() {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    openCartButton(event) {
      // console.log('OPEN!')
    },
    closeCartButton(event) {
      this.$emit('closeCartEvent')
    },
    load() {
      if (this.loading) {
        return
      }
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('cart/get', { partnerId: this.partnerId }),
          this.cart && this.$store.dispatch('shop/getCartOpenings', { cart: this.cart }),
          this.$store.dispatch('partner/hubs', { id: this.partnerId })
        ])
        .then(([cart, deliveryDates]) => {
          this.firstDeliveryDate = deliveryDates && deliveryDates[0]
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    removeOffer(shop, offer) {
      this.loading = true
      return this.$store.dispatch('cart/removeOffer', { offerId: offer.id, shopId: shop.id })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.cart-popin {
  background-color: var(--red-color);
  color: white;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 0px 6px #0000008c;
  padding: 30px;
  text-align: left;
  width: 365px;
  overflow-y: auto;
  z-index: 100;

  @include media-breakpoint-up(md) {
    width: 400px;
  }
}

.cart-popin-button {
  /* content: ""; */
  position: absolute;
  background-image: url("~@/assets/cart-popin-tab.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 150px;
  top: -58px;
  right: 77px;
  cursor: pointer;
  z-index: 1;

  @include media-breakpoint-up(md) {
    right: 0;
  }
}

.cart-popin {
  h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 22px;
    padding: 0;
    padding-bottom: 20px;
  }

  .commerce {
    padding: 15px 0;
    border-bottom: 1px dotted white;

    .commerce-cart {
      z-index: 100;
    }

    .commerce-icon {
      padding: 0;
      img {
        max-width: 30px;
      }
    }
    .commerce-name {
      font-size: 15px;
      padding-bottom: 4px;
      text-transform: uppercase;

      .livre {
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
  .commande-minimum-msg {
    background-color: rgba(255,255,255,0.2);
    padding: 5px;
    font-size: 14px;
    img {
      width: 25px;
    }
  }
  .commande-minimum-end-msg {
    background-color: rgba(255,255,255,0.2);
    padding: 15px;
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    h2 {
      font-size: 16px;
      padding: 0;
      margin: 0;
    }
    img {
      width: 45px;
      padding-right: 10px;
    }
  }
  .totals-footer {
    margin-top: 20px;
    border-top: 1px dotted white;

    .text {
      font-weight: 300;
      font-size: 15px;
    }

    .livraison-date {
      padding-bottom: 20px;
      text-align: center;
      font-size: 20px;
    }
    .commander {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .total-amount {
        font-size: 25px;
      }

      a.button {
        margin: 0px !important;
      }
    }
  }
}
</style>
