<template>
  <div
    id="main-menu"
    class="main-menu"
    tabindex="-1"
    :style="{ height: `${height}px` }"
  >
    <ul>
      <li class="menu-item-header">
        <div class="header-menu">
          <img
            src="@/assets/burger-icon-white.svg"
            class="icon"
            alt="Icone menu"
            @click="closeMenuButton"
          >
        </div>
        <div class="header-logo">
          <img
            src="@/assets/youpaq-logo-white.svg"
            alt="Youpag logo"
          >
        </div>
      </li>
      <li
        id="menu-item-1"
        class="menu-item"
      >
        <a
          href="#"
          @click="toggleMenu('category')"
        >
          <span class="item-text">{{ $t('content.menu.categories') }}</span>
        </a>
        <ul
          id="menu-categories"
          :class="{ active: openMenuCategories }"
        >
          <li
            v-for="category in categories"
            :key="category.id"
            class="sub-menu-item menu-item"
          >
            <router-link :to="{ name: 'shops', query: { categoryId: category.code } }">
              <span class="item-text">{{ category.title }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li
        id="menu-item-2"
        class="menu-item"
      >
        <a
          href="#"
          @click="toggleMenu('commercant')"
        >
          <span class="item-text">{{ $t('content.menu.shops') }}</span>
        </a>
        <ul
          id="menu-commercants"
          :class="{ active: openMenuCommercants }"
        >
          <li
            v-for="shop in shops"
            :key="shop.id"
            class="sub-menu-item menu-item"
          >
            <router-link :to="{ name: 'shop', params: { shopSlug: shop.slug } }">
              <span class="item-text">{{ shop.name }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li
        id="menu-item-3"
        class="menu-item"
      >
        <a
          href="#"
          @click="toggleMenu('selection')"
        >
          <span class="item-text">{{ $t('content.menu.selections') }}</span>
        </a>
        <ul
          id="menu-commercants"
          :class="{ active: openMenuSelections }"
        >
          <li
            v-for="filter in filters"
            :key="filter.id"
            class="sub-menu-item menu-item"
          >
            <router-link :to="{ name: 'enviede', params: { partnerId, filterId: filter.id } }">
              <span class="item-text">{{ filter.getName($l) }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li
        id="menu-item-4"
        class="menu-item"
      >
        <router-link :to="$siteLink($route, 'livraison')">{{ $t('content.menu.delivery') }}</router-link>
      </li>
      <li
        id="menu-item-5"
        class="menu-item"
      >
        <router-link :to="{ name: 'partnerhubs', params: { partnerId } }">{{ $t('content.menu.hubs') }}</router-link>
      </li>
      <li
        id="menu-item-6"
        class="menu-item"
      >
        <router-link :to="{ name: 'home' }">{{ $t('content.menu.home') }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import ResizeMixin from '@/mixins/ResizeMixin'

export default {
  name: 'TopMainMenu',
  mixins: [ResizeMixin],
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    offsetHeight: 2,
    openMenuCategories: false,
    openMenuCommercants: false,
    openMenuSelections: false
  }),
  computed: {
    categories() {
      return this.$store.getters['partner/categories']
    },
    shops() {
      return this.$store.getters['partner/shops']
    },
    filters() {
      return this.$store.getters['partner/filters']
    }
  },
  watch: {
    partnerId: 'load',
    '$route.fullPath': 'closeMenuButton'
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      if (!this.partnerId) {
        return
      }
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('partner/shops', { id: this.partnerId }),
          this.$store.dispatch('partner/categories', { id: this.partnerId }),
          this.$store.dispatch('partner/filters', { id: this.partnerId })
        ])
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    toggleMenu(menu) {
      if (menu === 'commercant') {
        this.openMenuCommercants = !this.openMenuCommercants
        this.openMenuCategories = false
        this.openMenuSelections = false
      } else if (menu === 'category') {
        this.openMenuCategories = !this.openMenuCategories
        this.openMenuCommercants = false
        this.openMenuSelections = false
      } else {
        this.openMenuSelections = !this.openMenuSelections
        this.openMenuCommercants = false
        this.openMenuCategories = false
      }
    },

    closeMenuButton(event) {
      this.openMenuCategories = false
      this.openMenuCommercants = false
      this.openMenuSelections = false
      this.$emit('closeMenuEvent')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.main-menu {
  position: absolute;
  top: -15px;
  left: 0;
  background-color: var(--red-color);
  color: white;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0px 0px 6px #0000008c;
  overflow: hidden;
  overflow-y: auto;
}
.main-menu ul {
  padding: 0;
  margin: 0;
}
.main-menu ul li:first-child {
  border: none;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.main-menu ul .sub-menu-item:first-child {
  align-items: start !important;
}
.menu-item-header {
  display: flex;
  padding: 20px;
}
.menu-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid white;
}
.menu-item a {
  color: white;
  text-decoration: none;
  text-align: left;
}
.menu-item a:hover {
  color: #f7cec6;
}
.menu-item ul {
  padding-top: 15px;
  padding-left: 20px;
}
.sub-menu-item {
  border: none;
  padding: 10px;
}
#menu-categories:not(.active) {
  display: none;
}
#menu-commercants:not(.active) {
  display: none;
}
#menu-selections:not(.active) {
  display: none;
}
.header-menu img {
  max-width: 80px;
  max-height: 30px;
  cursor: pointer;
}
.header-logo {
  width: 200px;
}
.header-logo img {
  max-width: 100px;
}
</style>
