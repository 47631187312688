import Vue from 'vue'
import lodash from 'lodash'
import Article from './article'
import Offer from './offer'
import PartnerFilter from './partnerfilter'
import Profile from './profile'
import Promotion from './promotion'
import PromotionCampaign from './promotioncampaign'
import SupplierCategory from './suppliercategory'
import Shop from './shop'
import Hub from './hub'
import User from './user'
import Cart from './cart'
import CartShop from './cart-shop'
import CartHub from './cart-hub'
import CartOffer from './cart-offer'

Vue.youpaqModel = {
  Article,
  Offer,
  PartnerFilter,
  Profile,
  Promotion,
  PromotionCampaign,
  SupplierCategory,
  Shop,
  Hub,
  User,
  Cart,
  CartShop,
  CartHub,
  CartOffer
}

const mixins = [
  'cart-salepoint', 'cartHasOffers', 'cartHasShops',
  'images', 'imageSquare', 'logo',
  'isHub', 'isOffer',
  'memoizable', 'metadata', 'pricing', 'salepoint',
  'updatable', 'timetable'
]
Vue.youpaqMixin = mixins.reduce((acc, mixin) => {
  acc[mixin] = require(`./mixins/${mixin}`).default
  return acc
}, {})

Vue.youpaqModelLoad = (instance, Obj) => {
  if (!Obj._mixinsLoaded) {
    for (const i in Obj.mixins) {
      const mixin = Vue.youpaqMixin[i]
      mixin.call(Obj.prototype, Obj.mixins[i])
      Object.assign(Obj.mixins, mixin.mixins || {})
      Object.assign(Obj.relations, mixin.relations || {})
    }
    Obj._mixinsLoaded = true
  }
  for (const i in Obj.relations) {
    if (instance[i]) {
      const Model = Vue.youpaqModel[Obj.relations[i]]
      if (Array.isArray(instance[i])) {
        instance[i] = lodash.map(instance[i], data => new Model(data))
      } else {
        instance[i] = new Model(instance[i])
      }
    }
  }
}
