/* eslint-disable */
import moment from 'moment'

/**
 * Mixin
 *
 */
var Mixin = function (options) {

  this.getMinOpening = function (date, table, opts = {}) {
    const alone = opts.alone
    if (!table) {
      console.log('no timetable found')
      return false
    }

    const closed = this.isClosedV2(date, table);
    const checkDate = moment(date)
    const start = checkDate.clone().hour(0).minute(0)
    const pivot1 = moment(`${checkDate.format('YYYY-MM-DD')} ${table.pivot1 || '16:00'}`, 'YYYY-MM-DD HH:mm')
    const pivot2 = moment(`${checkDate.format('YYYY-MM-DD')} ${table.pivot2 || '22:00'}`, 'YYYY-MM-DD HH:mm')
    const jp1 = (!alone || !table.jp0IfAlone) && table.jp1
    const jp2 = (!alone || !table.jp0IfAlone) && table.jp2

    console.log('alone', alone, 'and jp0', table.jp0IfAlone, 'so jp1=', jp1)

    // check maintenant si J+1 ou J+2. Entièrement basé sur le doc PDF du drive, projet Youpaq
    if (!closed) {
      if (jp2) {
        if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
          console.log('J+2, open, left')
          return checkDate.add(1, 'day')
        }
        console.log('J+2, open, middle/right')
        return checkDate.add(2, 'day')
      }
      if (jp1) {
        if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
          console.log('J+1, open, left')
          return checkDate.add(1, 'day')
        }
        if (checkDate.isBetween(pivot1, pivot2, 'minute', '(]')) {
          console.log('J+1, open, middle')
          return checkDate.add(1, 'day')
        }
        console.log('J+1, open, right')
        return checkDate.add(2, 'day')
      }
      // if jp0
      if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
        console.log('J+0, open, left')
        return checkDate
      }
      console.log('J+0, open, middle/right')
      return checkDate.add(1, 'day')
    } else {
      if (jp2) {
        console.log('J+2, closed, left/middle/right')
        return checkDate.add(2, 'day')
      }
      if (jp1) {
        if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
          console.log('J+1, closed, left')
          return checkDate.add(1, 'day')
        }
        if (checkDate.isBetween(pivot1, pivot2, 'minute', '(]')) {
          console.log('J+1, closed, middle')
          return checkDate.add(1, 'day')
        }
        console.log('J+1, closed, right')
        return checkDate.add(2, 'day')
      }
      // if jp0
      console.log('J+0, closed, left/middle/right')
      return checkDate.add(1, 'day')
    }
  };

  this.isClosedV2 = function (date, table) {
    const id = this.id
    if (!table) {
      return true
    }
    const checkDate = moment(date).locale('en')
    const todayName = checkDate.format('dddd').toLowerCase()
    console.group(`${id}: check close status for ${checkDate.format('YYYY-MM-DD')} (${todayName})`)

    // on vérifie que le shop n'est pas fermé, selon les jours spéciaux
    const period = !!(table.closed.periods || []).find(p => checkDate.isBetween(p.start, p.end, 'day', '[]'))
    if (period) {
      console.log('close period found')
      console.groupEnd()
      return true
    }
    const special = !!(table.closed.specialdays || []).find(sd => checkDate.isSame(sd.date, 'day') && sd.hours.length === 0)
    if (special) {
      console.log('close special found')
      console.groupEnd()
      return true
    }

    // check si les horaires du jour sont ok
    const day = table.timetable.find(t => t.name === todayName && t.hours.length)
    if (!day) {
      console.log('no timetable for this day')
      console.groupEnd()
      return true
    }
    console.log('is open')
    console.groupEnd()
    return false
  };

  return this;
};

Mixin.mixins = {
};

Mixin.relations = {
};

export default Mixin;
