<template>
  <div class="cart-add-product">
    <h5 v-if="!dense" @click="close">{{ article.getDisplayName() }}</h5>
    <div v-if="!hidePrice" class="input-group mb-3">
      <div v-html="$options.filters.unitprice(article)"></div>
    </div>

    <!-- CASE 1: by quantity, round number -->
    <div v-if="!article.isRawPackaged()" class="input-group mb-3">
      <div class="form-floating">
        <input
          v-model="quantityLocal"
          type="number"
          :step="step"
          min="0"
          class="form-control qty-field"
          :placeholder="minQuantity ? $t('article.quantityMin', { quantity: minQuantity }) : ' '"
        />
        <label>{{ $t('article.quantity') }}</label>
      </div>
      <span class="input-group-text">{{ $t(packagingTypeI18n) }}</span>
    </div>

    <div v-else>
      <div class="container" :class="{ dense: dense }">
        <!-- CASE 4: radio buttons portions/quantity -->
        <div v-if="quantities.length && !article.packaging.nodetail" class="row mb-3">
          <div class="col px-0">
            <div v-for="type in chooseTypes" :key="type.id" class="form-check form-check-inline">
              <input :id="`type-${type.id}`" v-model="chooseTypeLocal" :value="type.id" class="form-check-input" type="radio" name="chooseType" />
              <label :for="`type-${type.id}`" class="form-check-label">{{ type.text }}</label>
            </div>
          </div>
        </div>

        <!-- CASE 3+4: by portion -->
        <div v-if="chooseTypeLocal === 'unit'" class="row">
          <div class="col p-0">
            <div class="form-floating portions mb-3">
              <input
                v-model="portionsLocal"
                type="number"
                step="1"
                min="0"
                class="form-control"
                placeholder=" "
              />
              <label>{{ $t('article.portions') }}</label>
            </div>
          </div>
          <div class="col px-0">
            <div class="input-group mb-3 group-portion-quantity">
              <span class="input-group-text middle">{{ $t('article.of') }}</span>
              <select v-model="selectedQuantityLocal" :placeholder="$t('article.portionWeight')">
                <option v-for="(qty, i) in quantities" :key="i" :value="qty">{{ qty }}</option>
              </select>
              <span class="input-group-text">{{ article.getUnit() }}</span>
            </div>
          </div>
        </div>

        <!-- CASE 2+3+4: by quantity -->
        <div class="row">
          <div class="col px-0">
            <div class="input-group mb-3">
              <div class="form-floating calcul">
                <!-- CASE 4: by portion, the input is readonly  -->
                <input
                  v-model="quantityLocal"
                  type="number"
                  :step="step"
                  min="0"
                  class="form-control"
                  :class="{ 'qty-field': chooseTypeLocal !== 'unit' }"
                  :readonly="chooseTypeLocal === 'unit'"
                  :placeholder="minQuantity ? $t('article.quantityMin', { quantity: minQuantity }) : ' '"
                />
                <label v-if="chooseTypeLocal === 'unit'"></label>
                <label v-else>{{ $t('article.quantity') }}</label>
              </div>
              <span class="input-group-text">{{ article.getUnit() }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import decimal from '@/plugins/decimal'

export default {
  name: 'CartAjoutProduit',
  props: {
    hidePrice: { type: Boolean },
    closable: { type: Boolean },
    autoInit: { type: Boolean },
    article: { type: Object },
    dense: { type: Boolean },
    valid: { type: Boolean },
    quantity: { type: [String, Number] },
    selectedQuantity: { type: [String, Number] },
    portions: { type: [String, Number] },
    chooseType: { type: String, default: 'unit' },
    mode: { type: String, default: 'add' }
  },

  data: () => ({
    quantities: []
  }),

  computed: {
    chooseTypes() {
      return [
        { id: 'unit', text: this.$i18n.t('article.types.unit') },
        { id: 'quantity', text: this.$i18n.t('article.types.quantity') }
      ]
    },

    packagingTypeI18n() {
      if (this.article.packaging.type === 'piece') {
        return 'article.packaging.types.piece'
      }
      return `packaging.${this.article.packaging.type}`
    },

    minQuantity() {
      return this.article.packaging?.minimalQuantity || 0
    },

    validLocal() {
      return this.quantityLocal && this.quantityLocal >= this.minQuantity && (!this.portionsLocal || this.portionsLocal > 0)
    },

    normal() {
      return !this.article.isRawPackaged() || this.quantityLocal
    },

    step() {
      return this.article.getUnitStep()
    },

    quantityLocal: {
      get() {
        return this.quantity
      },
      set(v) {
        this.$emit('update:quantity', v)
      }
    },

    selectedQuantityLocal: {
      get() {
        return this.selectedQuantity
      },
      set(v) {
        this.$emit('update:selected-quantity', v)
      }
    },

    portionsLocal: {
      get() {
        return this.portions
      },
      set(v) {
        this.$emit('update:portions', v)
      }
    },

    chooseTypeLocal: {
      get() {
        return this.chooseType
      },
      set(v) {
        this.$emit('update:choose-type', v)
      }
    }
  },

  watch: {
    portionsLocal: 'calculateQuantity',
    validLocal(v) {
      this.$emit('update:valid', !!v)
    }
  },

  mounted() {
    if (this.autoInit) {
      this.init()
    }
  },

  methods: {
    init() {
      if (!this.article.isRawPackaged()) {
        this.quantityLocal = 1
      } else {
        this.quantities = (this.article.packaging.quantities || '')
          .split('\n')
          .filter(q => !!q)
          .map(q => Number(q))

        this.selectedQuantityLocal = this.quantities.length && this.quantities[0]
        this.chooseTypeLocal = this.quantities.length || this.article.packaging.nodetail ? 'unit' : 'quantity'
      }
    },

    close() {
      if (this.closable) {
        this.$emit('close')
      }
    },

    calculateQuantity() {
      this.quantityLocal = decimal.mul(this.portionsLocal, this.selectedQuantityLocal)
    },

    submit(shopId, offerId) {
      if (!this.validLocal) {
        return Promise.resolve()
      }

      let selectedQuantity = this.selectedQuantityLocal
      let portions = this.portionsLocal

      if (this.article.isRawPackaged() && this.chooseTypeLocal !== 'unit') {
        // si on est en mode "poids/volume", on ne transmet pas
        // les informations de portions et de quantité select
        selectedQuantity = null
        portions = null
      }

      const action = this.mode === 'add' ? 'cart/addOffer' : 'cart/updateOffer'

      return this.$store
        .dispatch(action, {
          shopId: shopId,
          offerId: offerId,
          quantity: this.quantityLocal,
          selectedQuantity: selectedQuantity,
          portions: portions
        })
        .then(data => {
          const text = this.mode === 'add'
            ? 'articlePopup.added'
            : 'articlePopup.updated'

          this.$store.commit('msg/show', { type: 'info', message: this.$i18n.t(text) })
          return data
        })
        .catch(err => this.$err(err))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.cart-add-product {
  .form-floating > .form-control:focus {
    &::placeholder {
      color: $input-placeholder-color !important;
    }
  }

  .input-group > .form-floating {
    flex-grow: 0;
    width: auto !important;
  }

  .form-floating input, .group-portion-quantity select {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, .35);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, .35);
    min-height: 60px;
    min-width: 70px;
    padding: 1rem 0.75rem;

    &:focus-visibke {
      outline: none;
    }
  }

  .form-floating.portions input {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .form-floating.calcul input{
    cursor: default;
    max-width: 100px;
  }

  .qty-field {
    max-width: none !important;
  }

  .calcul-label {
    font-size: 12px;
    padding-bottom: 5px;
  }

  .input-group .input-group-text {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--gray-color);
    color: white;
  }

  .input-group .input-group-text.middle {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: transparent !important;
    border: none;
    color: var(--gray-color);
  }

  .group-portion-quantity {
    min-height: 58px;
  }

  .container.dense {
    padding-left: 12px;
  }
}
</style>
