/* eslint-disable */
import lodash from 'lodash'
import moment from 'moment'
import Vue from 'vue'

var PromotionCampaign = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, PromotionCampaign)
};

PromotionCampaign.relations = {
    'owner': 'Profile',
    'parent': 'Profile',
    'promotions': 'Promotion'
};

PromotionCampaign.mixins = {
    'metadata': {},
    'updatable': {
        update: {
            postUpdate: function () {
                this.end = moment(moment(this.end).format('YYYY-MM-DD'), 'YYYY-MM-DD').add(1, 'days').subtract(1, 'seconds').format();
            }
        },
        getJson: {
            merge: {type: 'promotioncampaign'}
        }
    }
};

lodash.assign(PromotionCampaign.prototype, {
    getId: function () {
        return this.id;
    },

    getName(lang) {
        if (lodash.isString(this.name)) {
            return this.name;
        }
        return this.name[lang] || this.name.fr;
    },

    getOwner: function () {
        return this.owner;
    },

    getParent: function () {
        return this.parent;
    },

    getPromotions: function () {
        return this.promotions;
    },

    isSupplierCampaign: function () {
        return this.campaign_type === 'supplier';
    }
});

export default PromotionCampaign;
