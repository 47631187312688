import Vue from 'vue'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'
import lodash from 'lodash'
import moment from 'moment'
import fr from '@/i18n/fr.json'
import de from '@/i18n/de.json'
import en from '@/i18n/en.json'

const escapeFn = str => {
  const _entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
  }
  return (str || '').replace(/[<"/]/g, s => _entityMap[s])
}

const I18nPlugin = {
  install(vue, options) {
    vue.mixin({
      computed: {
        $tr() {
          return (data, ln) => {
            ln = ln || 'fr'
            const content = lodash.trim(data[ln]) ? data[ln] : data[options.default]
            return content
          }
        },

        $tu() {
          // unescape partiel de i18next
          return (key, data) => {
            return i18next.t(key, {
              ...data,
              interpolation: {
                escape: escapeFn
              }
            })
          }
        },

        $l() {
          return this.$store.getters['lang/current']
        }
      }
    })
  }
}

Vue.use(VueI18Next)

const resources = {}

export default {

  resources: resources,

  init(config) {
    const userLn = config.lang
    return i18next
      .init({
        returnObjects: true,
        lng: userLn,
        fallbackLng: config.defaultLang,
        preload: [config.defaultLang],
        available: config.availableLangs,
        resources: {
          fr: {
            translation: fr
          },
          de: {
            translation: de
          },
          en: {
            translation: en
          }
        },
        interpolation: {
          skipOnVariables: false,
          format(value, format, lng) {
            if (format) {
              return moment(value).format(format)
            }
            return value
          }
        }
      })
      .then(() => {
        Vue.use(I18nPlugin, { default: config.defaultLang })
        i18next.tu = (key, opt) => {
          return i18next.t(key, {
            ...opt,
            interpolation: {
              escape: escapeFn
            }
          })
        }
        return new VueI18Next(i18next)
      })
  }
}
