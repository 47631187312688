<template>
  <component :is="modal" v-if="modal" v-bind="modalParams" />
</template>

<script>
export default {
  name: 'ModalUi',
  computed: {
    modal() {
      return this.$store.getters['modal/component']
    },
    modalParams() {
      return this.$store.getters['modal/params'] || {}
    }
  }
}
</script>
