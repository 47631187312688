import Vue from 'vue'
import Vuex from 'vuex'

import ModalStore from './ModalStore'
import SideWidgetStore from './SideWidgetStore'
import YpqAuthStore from './YpqAuthStore'
import YpqCartStore from './YpqCartStore'
import YpqPartnerStore from './YpqPartnerStore'
import YpqShopStore from './YpqShopStore'
import YpqHubStore from './YpqHubStore'
import YpqProductStore from './YpqProductStore'
import YpqUserStore from './YpqUserStore'
import YpqMessageStore from './YpqMessageStore'
import YpqPromotionStore from './YpqPromotionStore'
import YpqFilterStore from './YpqFilterStore'
import YpqOrderStore from './YpqOrderStore'
import YpqFeedbackStore from './YpqFeedbackStore'
import LoaderStore from './LoaderStore'
import LangStore from './LangStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    modal: ModalStore,
    sidew: SideWidgetStore,
    auth: YpqAuthStore,
    cart: YpqCartStore,
    partner: YpqPartnerStore,
    shop: YpqShopStore,
    hub: YpqHubStore,
    product: YpqProductStore,
    msg: YpqMessageStore,
    user: YpqUserStore,
    promo: YpqPromotionStore,
    filter: YpqFilterStore,
    order: YpqOrderStore,
    feedback: YpqFeedbackStore,
    load: LoaderStore,
    lang: LangStore
  }
})
