import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    feedbacks: []
  },

  getters: {
    feedbacks: state => state.feedbacks
  },

  mutations: {
  },

  actions: {
    async list({ state }) {
      if (state.feedbacks.length) {
        return state.feedbacks
      }
      const res = await Vue.prototype.$api.get('/front/feedbacks')

      state.feedbacks = res.data
      return state.feedbacks
    }
  }
}
