/* eslint-disable */
import lodash from 'lodash'

/**
 * Mixin des images
 *
 */
const Mixin = function (options) {

    this.getBase = function () {
        if (this._links && this._links.images && (!options.multiple || lodash.isArray(this._links.images))) {
            return this._links.images;
        }
        if (this.images && (!options.multiple || lodash.isArray(this.images))) {
            return this.images;
        }

        return false;
    };

    this.getImageUrlByType = function (type) {
        var base;
        if (!this.getBase() || (options.multiple && this.getBase().length === 0)) {
            return '';
        }
        base = this.getBase();

        if (options.multiple) {
            base = base[0];
        }

        if (!base[type] || !base[type].href) {
            return '';
        }

        return base[type].href;
    };

    this.setImageUrlByType = function (type, url) {
        var base;
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        this.initImages();
        base = this.getBase();

        if (options.multiple) {
            base = base[0];
        }
        if (base[type] === undefined) {
            base[type] = {};
        }
        base[type].href = url;
        return this;
    };

    this.getImageUrl = function () {
        return this.getImageUrlByType('normal');
    };

    this.getOriginalImageUrl = function () {
        return this.getImageUrlByType('raw');
    };

    this.getMicroImageUrl = function () {
        return this.getImageUrlByType('micro');
    };

    this.getThumbImageUrl = function () {
        return this.getImageUrlByType('thumb');
    };

    this.getSquareSmallImageUrl = function () {
        return this.getImageUrlByType('squaresmall');
    };

    this.getSquareMiniImageUrl = function () {
        return this.getImageUrlByType('squaremini');
    };

    this.hasImages = function () {
        var base = this.getBase(),
            // si c'est un tableau d'images, on prend la 1ère
            imgs = lodash.isArray(base) ? lodash.head(base) : base;

        if (!imgs) {
            return false;
        }

        return lodash.reduce(imgs, function (acc, value) {
            if (value && value.href) {
                acc += 1;
            }
            return acc;
        }, 0) > 0;
    };

    this.initImages = function () {
        if (!this._links) {
            this._links = {};
        }
        if (!this._links.images) {
            this._links.images = {
                admin: {},
                micro: {},
                normal: {},
                raw: {},
                squaremini: {},
                squaresmall: {},
                thumb: {}
            };
            if (options.multiple) {
                this._links.images = [this._links.images];
            }
        }
    };

    this.setRawUrl = function (url) {
        return this.setImageUrlByType('raw', url);
    };

    this.setThumbUrl = function (url) {
        return this.setImageUrlByType('thumb', url);
    };

    this.setImageUrl = function (url) {
        return this.setImageUrlByType('normal', url);
    };

    this.setMicroUrl = function (url) {
        return this.setImageUrlByType('micro', url);
    };

    this.setAdminImageUrl = function (url) {
        return this.setImageUrlByType('admin', url);
    };

    this.setSquareSmallUrl = function (url) {
        return this.setImageUrlByType('squaresmall', url);
    };

    this.setSquareMiniUrl = function (url) {
        return this.setImageUrlByType('squaremini', url);
    };

    return this;
};

export default Mixin;
