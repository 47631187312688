import Vue from 'vue'

const prefs = Vue.prototype.$storage.getJson('ypq-prefs', {})

export default {
  namespaced: true,

  state: {
    preferences: { ...prefs }
  },

  getters: {
    preferences: state => state.preferences
  },

  mutations: {
    preferences(state, data) {
      Object.assign(state.preferences, data || {})
      Vue.prototype.$storage.setJson('ypq-prefs', state.preferences)
    }
  },

  actions: {
    async productFavouriteToggle(context, { shopId, productId }) {
      const res = await Vue.prototype.$api.post('/users/me/favourites/toggle', {
        shop: shopId,
        id: productId,
        type: 'articles'
      })
      return res.data.favourite
    },

    async shopFavouriteToggle(context, { shopId }) {
      const res = await Vue.prototype.$api.post('/users/me/favourites/toggle', {
        id: shopId,
        type: 'shops'
      })
      return res.data.favourite
    },

    async searchCitiesByZip(context, { zip }) {
      const res = await Vue.prototype.$api.get('/cities', {
        params: {
          query: zip
        }
      })
      return res.data
    },

    create(context, { user }) {
      return Vue.prototype.$api.post('/front/users', user)
    },

    async orders(context, { status }) {
      const res = await Vue.prototype.$api.get('/front/users/me/orders', {
        params: {
          status
        }
      })
      return res.data
    },

    async order(context, { orderId }) {
      const res = await Vue.prototype.$api.get(`/front/users/me/orders/${orderId}`)
      return res.data
    },

    async getSponsorCode() {
      const res = await Vue.prototype.$api.get('/front/users/me/sponsorcode')
      return res.data
    },

    async countFavourites(context, { shopId }) {
      const res = await Vue.prototype.$api.get('/front/users/me/countfavourites', {
        params: {
          shopId
        }
      })
      return res.data.count
    },

    tempAccount(context, data) {
      return Vue.prototype.$api.post('/session/temp-account', data)
    }
  }
}
