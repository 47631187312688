import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    user: null,
    connected: false
  },

  getters: {
    user: state => state.user,
    connected: state => state.connected,
    privateHubs: state => partnerId => {
      if (!state.connected) {
        return []
      }
      return (state.user.privateHubs || []).filter(row => row.partner === partnerId)
    }
  },

  mutations: {
    user(state, { user }) {
      if (user && !user.newsletter) {
        user.newsletter = {}
      }
      state.user = user
      state.connected = !!user
    }
  },

  actions: {
    async login({ commit }, { email, password }) {
      await Vue.prototype.$api.post('/session', { email, password })
      const res = await Vue.prototype.$api.get('/front/users/me')
      commit('user', { user: res.data })
      return res.data
    },

    async logout({ commit }) {
      await Vue.prototype.$api.delete('/session')
      commit('user', { user: null })
    },

    async updateUser({ state, commit }, { user }) {
      user.email = state.user.email

      await Vue.prototype.$api.put('/front/users/me', user)

      const u = Object.assign(state.user, user)

      commit('user', { user: u })
    },

    check({ commit }) {
      return Vue.prototype.$api
        .get('/session', {
          params: {
            silently: true
          }
        })
        .then(() => Vue.prototype.$api.get('/front/users/me'))
        .then(res => commit('user', { user: res.data }))
        // skip error
        .catch(() => null)
    },

    saveDeliveryAddress({ state, commit }, { address }) {
      return Vue.prototype.$api
        .put('/front/users/me', {
          ...state.user,
          deliveryCoordinate: address
        })
        .then(() => Vue.prototype.$api.get('/front/users/me'))
        .then(res => commit('user', { user: res.data }))
    },

    validate(context, { hash }) {
      return Vue.prototype.$api.post(`/front/users/validate/${hash}`)
    },

    passwordReminderSend(context, { email, from = null }) {
      return Vue.prototype.$api.post('/front/users/passwordreminder', {
        email,
        from
      })
    },

    async passwordReminderValidate(context, { hash, password, passwordVerification }) {
      const res = await Vue.prototype.$api.put(`/front/users/passwordreminder/${hash}`, {
        password,
        passwordVerification
      })
      return res.data
    }
  }
}
