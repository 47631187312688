/* eslint-disable */
import lodash from 'lodash'
import decimal from '@/plugins/decimal'
import Vue from 'vue'
import moment from 'moment'

var Cart = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, Cart)
};

Cart.relations = {
    hubs: 'CartHub',
    shops: 'CartShop'
};

Cart.mixins = {
    'metadata': {},
    'cartHasShops': {},
    'updatable': {
        getJson: {
            omit: ['constraints'],
            merge: {type: 'cart'}
        }
    }
};

lodash.assign(Cart.prototype, {

    getId: function () {
        return this.id;
    },

    setId: function (id) {
        this.id = id;
    },

    setCustomerId: function (id) {
        this.customer_id = id;
    },

    getOwnerId: function () {
        return (this.metadata.updated_by || this.metadata.created_by);
    },

    getHubs: function () {
        return lodash.sortBy(this.hubs, 'name');
    },

    getUnholdHubs: function () {
        return lodash.sortBy(lodash.reject(this.hubs, {hold: true}), 'name');
    },

    getHoldHubs: function () {
        return lodash.sortBy(lodash.filter(this.hubs, {hold: true}), 'name');
    },

    findHub: function (id) {
        return lodash.find(this.getHubs(), {id: id});
    },

    removeHub: function (id) {
        this.hubs = lodash.reject(this.hubs, {id: id});
    },

    removeHubShop: function (hubId, shopId) {
        var hub = this.findHub(hubId);

        hub.removeShop(shopId);
        if (!hub.hasShops()) {
            this.removeHub(hubId);
        }
        return this;
    },

    addFees: function (type, amount, label, rate) {
        if (!this.fees) {
            this.fees = [];
        }
        var existing_fees = lodash.find(this.fees, {type: type});
        if (existing_fees) {
            existing_fees.label = label;
            existing_fees.amount = amount;
            if (rate) {
                existing_fees.rate = rate;
            }
        } else {
            this.fees.push({
                type: type,
                label: label,
                amount: amount,
                rate: rate
            });
        }
        return this;
    },

    hasFees: function (type) {
        if (type) {
            return lodash.find(this.fees || [], {type: type});
        }
        return this.fees && this.fees.length > 0;
    },

    getFees: function () {
        return this.fees || [];
    },

    getFeeTotal: function (fee) {
        if (fee.amount !== null && fee.amount !== undefined) {
            return fee.amount;
        }
        var tmp = decimal.mul(this.getTotalPriceStuff(), fee.rate);
        return decimal.div(tmp, 100);
    },

    removeFees: function (type) {
        if (!this.fees) {
            this.fees = [];
        }
        this.fees = lodash.reject(this.fees, {type: type});
        return this;
    },

    hasShop: function (id, checkHubs) {
        var in_hub = checkHubs && this.hasHub(id),
            in_shop = this.findShop(id);

        return in_hub || in_shop;
    },

    hasShopInHub: function (shopId) {
        return lodash.filter(this.getHubs(), function (hub) {
            return hub.findShop(shopId);
        }).length > 0;
    },

    getUnholdTopLevelSalepoints: function () {
        return this.getUnholdHubs().concat(this.getUnholdShops());
    },

    findTopLevelSalepoint: function (id) {
        var salepoint;
        salepoint = this.findHub(id);
        if (salepoint) {
            return salepoint;
        }
        return this.findShop(id);
    },

    findSalepoint: function (id) {
        var salepoint = this.findTopLevelSalepoint(id);
        if (!salepoint) {
            salepoint = lodash.reduce(this.getHubs(), function (acc, hub) {
                var shop = hub.findShop(id);
                if (shop) {
                    acc = shop;
                }
                return acc;
            }, false);
        }
        return salepoint;
    },

    getUnHoldSalepointsWithHubs: function () {
        var salepoints = lodash.map(this.getUnholdShops(), function (s) {
            return {hub: null, shop: s};
        });
        lodash.forEach(this.getUnholdHubs(), function (h) {
            salepoints = salepoints.concat(lodash.map(h.getUnholdShops(), function (s) {
                return {hub: h, shop: s};
            }));
        });
        return salepoints;
    },

    hasPromocode: function () {
        return this.promocodes && this.promocodes.length > 0;
    },

    hasManualPromocode: function () {
        return lodash.find(this.promocodes || [], function (code) {
            return !code.auto;
        });
    },

    hasPackagingPacketOffer: function (packet) {
        return !!lodash.find(this.getShops(), function (shop) {
            return shop.hasPackagingPacketOffer(packet);
        });
    },

    updateOffer: function (offer) {
        var search = this;
        if (offer.hub) {
            search = search.findHub(offer.hub.id);
        }
        search = search.findShop(offer.shop.id);
        search.updateOffer(offer);
    },

    pushOffer: function (offer, deliveryConstraints, shopConfig) {
        throw new Error('not implemented')
    },

    removeOffer: function (lineId, shopId, hubId) {
        var parentObj = this,
            shop;
        if (hubId) {
            parentObj = this.findHub(hubId);
        }
        shop = parentObj.findShop(shopId);
        shop.removeOffer(lineId);
        if (!shop.hasOffers()) {
            parentObj.removeShop(shopId);
        }
        if (hubId && !parentObj.hasShops()) {
            this.removeHub(hubId);
        }

        return this;
    },

    removeSalepoint: function (salepointId) {
        this.removeHub(salepointId);
        this.removeShop(salepointId);
    },

    isEmpty: function () {
        return this.hubs.length === 0 && this.shops.length === 0;
    },

    setPaymode: function (paymode, cardtype) {
        this.paymode = paymode;
        this.cardtype = cardtype;
        return this;
    },

    getPaymode: function () {
        return this.paymode;
    },

    getCardType: function () {
        return this.cardtype;
    },

    setStatus: function (value) {
        this.status = value;
        return this;
    },

    getStatus: function () {
        return this.status || 'cart';
    },

    hasValidPaymode: function () {
        return this.paymode;
    },

    getTotalPrice: function () {
        var that = this;
        var fees = lodash.reduce(this.fees || [], function (total, fee) {
            if (fee.type !== 'promocode') { // uniquement codepromo partner
                return total;
            }
            return decimal.add(total, that.getFeeTotal(fee));
        }, 0);

        var total = lodash.reduce(this.getUnholdHubs().concat(this.getUnholdShops()), function (total, salepoint) {
            return decimal.add(total, salepoint.getTotalPrice());
        }, fees);
        return total < 0 ? 0 : total;
    },

    getTotalItems: function () {
        return lodash.reduce(this.getUnholdHubs().concat(this.getUnholdShops()), function (total, salepoint) {
            return decimal.add(total, salepoint.getTotalItems());
        }, 0);
    },

    getTotalPriceStuff: function () {
        return lodash.reduce(this.getUnholdHubs().concat(this.getUnholdShops()), function (total, salepoint) {
            return decimal.add(total, salepoint.getTotalPriceWithFees());
        }, 0);
    },

    getTotalPriceWithFees: function (front) {
        var that = this;
        var fees = lodash.reduce(this.getUnholdShops(), function (total, shop) {
            if (!shop.hasFees()) {
                return total;
            }
            return decimal.add(total, shop.getTotalFees());
        }, 0);

        var total = decimal.add(this.getTotalPrice(), fees);
        total = lodash.reduce(this.fees || [], function (total, fee) {
            if (fee.type === 'promocode') { // uniquement codepromo partner
                return total;
            }
            return decimal.add(total, that.getFeeTotal(fee));
        }, total);
        if (this.delivery) {
            total = decimal.add(total < 0 ? 0 : total, this.delivery.fee || 0);
        }
        return total < 0 ? 0 : total;
    },

    getTotalPriceWithMargin: function (margin, front) {
        var total = this.getTotalPriceWithFees(front);
        return total * (1 + margin);
    },

    removeUnholdSalepoints: function () {
        var part_hold_hubs = lodash.reduce(this.getUnholdHubs(), function (acc, hub) {
                var hold_shops = lodash.map(hub.getHoldShops(), function (shop) {
                    shop.hold = false;
                    return shop;
                });
                if (hold_shops.length > 0) {
                    hub.shops = hold_shops;
                    acc.push(hub);
                }
                return acc;
            }, []),
            full_hold_hubs = lodash.map(this.getHoldHubs(), function (hub) {
                hub.hold = false;
                return hub;
            });

        this.hubs = full_hold_hubs.concat(part_hold_hubs);
        this.shops = lodash.map(this.getHoldShops(), function (shop) {
            shop.hold = false;
            return shop;
        });

        return this;
    },

    setDeliveryTemp: function (salepointId, data) {
        this.delivery = {
            salepoint: salepointId,
            type: data.type,
            date: data.date,
            mode: data.mode || 'pickup',
            note: data.note,
            fee: data.type === 'hub' && this.delivery && this.delivery.fee
        };
        return this;
    },

    getDeliverySlicedHours: function (timetable) {
        var date = this.delivery?.date
        if (!date) {
            return null
        }
        var m = moment(date).locale('en')
        var hour = m.format('HH:mm')
        var day = m.format('dddd').toLowerCase()
        var d = timetable.timetable.find(d => d.name === day)
        return d?.deliverySlices.find(slice => slice.value === hour)
    },

    getMinimumPrice() {
        var shops = this.getShops();
        if (shops.length === 1 && this.minimumPriceSolo && this.minimumPriceSolo.length) {
            for (var i = 0; i < this.minimumPriceSolo.length; i += 1) {
                var min = this.minimumPriceSolo[i];
                var found = lodash.find(shops, function (s) {
                    return s.id === min.id;
                });
                if (found && min.minimumOrderPriceSolo > 0) {
                    return min.minimumOrderPriceSolo;
                }
            }
        }
        return this.minimumPrice || 0;
    },

    getMinimumPriceSoloForShop(shopId) {
        return this.minimumPriceSolo?.find(s => s.id === shopId)
    },

    minimumReached() {
        return this.getTotalPrice() >= this.getMinimumPrice()
    },

    minimumStandardReached() {
        return this.getTotalPrice() >= (this.minimumPrice || 0)
    },

    needReserveMargin() {
        var quantityOffer = lodash.find(this.getShops(), function (shop) {
            return lodash.find(shop.getOffers(), function (offer) {
                // check if offer has a packaging type relevant for reserve margin
                return offer.getArticle().isWeightPriced();
            });
        });
        return !!quantityOffer;
    }
});

export default Cart;
