<template>
  <div :class="{ light: light }">
    <!-- edit button -->
    <button v-if="!editing" @click="editing = true">
      <img
        src="@/assets/minicart-icon.png"
        class="produit-cart"
      />
    </button>

    <!-- edit zone CASE 1 -->
    <template v-else-if="simpleEditor">
      <span class="quantity">{{ quantity || 0 }}</span>
      <img src="/imgs/cart-minus-qty-icon.svg" class="minus" @click="increment(-1)" />
      <img src="/imgs/cart-plus-qty-icon.svg" class="plus" @click="increment(1)" />
    </template>

    <!-- edit zone CASE 2,3,4 -->
    <template v-else>
      <span class="quantity" @click="showPopup">{{ quantity || 0 }}{{ offer.getUnit() }}</span>
      <span @click="showPopup"><img src="@/assets/edit-qty-icon.svg"></span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProduitQuantityEditor',

  props: {
    partnerId: { type: String },
    shopId: { type: String },
    article: { type: Object },
    offer: { type: Object },
    edition: { type: Boolean },
    light: { type: Boolean }
  },

  data() {
    return {
      editing: false,
      loading: false,
      quantity: null
    }
  },

  computed: {
    modalDone() {
      return this.$store.getters['modal/doneSwitch']
    },
    simpleEditor() {
      return !this.article.isRawPackaged()
    },
    offerInCart() {
      const shop = this.$store.getters['cart/shops'].find(shop => shop.id === this.shopId)
      if (!shop) {
        return null
      }
      return shop.offers.find(offer => offer.id === this.offer.id)
    }
  },

  watch: {
    modalDone() {
      if (this.$store.getters['modal/doneParams'].offerId === this.offer.id) {
        this.addProductDone()
      }
    },
    editing(v) {
      if (v && !this.edition) {
        this.quantity = this.offerInCart?.quantity
        if (this.simpleEditor) {
          if (!this.offerInCart) {
            // product is not in cart, add it with a quantity of 1
            return this.increment(1)
          }
        } else if (!this.simpleEditor && !this.offerInCart) {
          return this.showPopup()
        }
      }
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      if (this.edition) {
        this.editing = true
        this.quantity = this.offerInCart?.quantity
      }
    },

    showPopup() {
      // product has a complex quantity editor (CASE 2,3,4). Show popup
      const update = this.offerInCart && {
        quantity: this.offerInCart.quantity,
        selectedQuantity: this.offerInCart.selectedQuantity,
        portions: this.offerInCart.portions,
        chooseType: this.offerInCart.chooseType
      }

      return this.$store.commit('modal/show', {
        component: 'ProductAddPopup',
        params: {
          ...(update || {}),
          shopId: this.shopId,
          article: this.article,
          offer: this.offer
        }
      })
    },

    increment(quantity) {
      if (this.loading) {
        return
      }
      this.loading = true
      let promise

      this.quantity = Math.round(this.quantity || 0) + quantity

      if (this.quantity <= 0) {
        promise = this.$store.dispatch('cart/removeOffer', { offerId: this.offer.id, shopId: this.shopId })
          .then(() => this.$store.commit('msg/show', { type: 'info', message: this.$i18n.t('articlePopup.removed') }))
      } else if (this.offerInCart) {
        promise = this.$store.dispatch('cart/updateOffer', { offerId: this.offer.id, shopId: this.shopId, quantity: this.quantity })
          .then(() => this.$store.commit('msg/show', { type: 'info', message: this.$i18n.t('articlePopup.updated') }))
      } else {
        promise = this.$store.dispatch('cart/addOffer', { offerId: this.offer.id, shopId: this.shopId, quantity: this.quantity })
          .then(() => this.$store.commit('msg/show', { type: 'info', message: this.$i18n.t('articlePopup.added') }))
      }

      return promise
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    addProductDone() {
      // popup is closed and product is already added to cart
      this.quantity = this.offerInCart?.quantity
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.cart {
  input {
    border: none;
    background: transparent;
    text-align: right;
    max-width: 60px;
    margin-right: 5px;
    color: var(--red-color);

    &:focus-visible {
      outline: none !important;
    }
  }
  button {
    border: none;
    background: transparent;
    padding: 0;
  }
  img {
    margin: 0;
    cursor: pointer;
    max-width: 25px;
  }
  img.minus, img.plus {
    max-width: 25px !important;

    @include media-breakpoint-up(md) {
      max-width: 30px !important;
    }

    @include media-breakpoint-up(lg) {
      max-width: 25px !important;
    }
  }
  img.minus {
    margin-right: 8px;

    @include media-breakpoint-up(lg) {
      margin-right: 3px;
    }
  }
  .quantity {
    color: var(--red-color);
    margin-right: 10px;
  }

  .light {
    .quantity, input {
      color: white;
    }
    input {
      width: auto;
    }
    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
