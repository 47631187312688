/* eslint-disable */
import lodash from 'lodash'
import Vue from 'vue'

var Offer = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, Offer)
};

Offer.relations = {
    article: 'Article',
    shop: 'Shop',
    offer: 'Offer',
    promotion: 'Promotion'
};

Offer.mixins = {
    'metadata': {},
    'pricing': {},
    'isOffer': {},
    'updatable': {
        getJson: {
            merge: {type: 'offer'}
        }
    }
};

lodash.assign(Offer.prototype, {

    getId: function () {
        return this.id;
    },

    setId: function (id) {
        this.id = id;
    },

    getSupplierCode: function () {
        return this.data.supplier_code;
    },

    getSupplierOffer: function () {
        return this.offer;
    },

    getSupplierId: function () {
        return this.supplier_id;
    },

    getShop: function () {
        return this.shop;
    },

    getArticle: function () {
        return this.article;
    },

    getQuantity: function () {
        return (this.quantity || 1);
    },

    getDeliveryModes: function () {
        var personalized_delivery_modes = this.data.deliveryModes || [],
            delivery_modes = this.getShop() ? this.getShop().getDeliveryModes() : [];
        return lodash.filter(delivery_modes, function (mode) {
            var perso = lodash.find(personalized_delivery_modes, {code: mode.code});
            if (perso) {
                mode.enabled = perso.enabled;
            }
            return mode.enabled;
        });
    },

    getSupplierPriceLabel: function () {
        if (this.price_status && this.price_status === 'fixed') {
            return "Prix imposé";
        }
        if (this.price_status && this.price_status === 'minimum') {
            return "Prix minimum";
        }
        return "Prix recommandé";
    }

});

export default Offer;
