/* eslint-disable space-before-function-paren */
/* eslint-disable no-console */

import { register } from 'register-service-worker'

// Show notification for refresh page
const createUIPrompt = async (registration) => {
  const newVersion = await caches.has('youpaq-service-worker')

  const div = document.createElement('div')
  div.className = 'v-snack v-snack--active v-snack--bottom v-snack--has-background v-snack--multi-line'
  div.innerHTML = `
      <div id="pwacontainer">UPDATE <span id="pwabtnclose">x</span></div>
  `
  document.getElementById('app').appendChild(div)

  setTimeout(() => {
    if (newVersion) {
      try {
        registration.waiting.postMessage({
          type: 'SKIP_WAITING'
        })
      } catch (e) {
        div.remove()
        return console.error(e)
      }
    } else {
      window.location.reload()
    }

    const close = document.getElementById('pwabtnclose')
    close.addEventListener('click', () => {
      div.remove()
    })
  }, 600)
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      if (registration.waiting) {
        createUIPrompt(registration)
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })

  // Listen for new service worker taking control
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', async () => {
      const newVersion = await caches.has('youpaq-service-worker')
      if (newVersion) {
        window.location.reload()
      }
    }, { once: true })
  }
}
