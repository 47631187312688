/* eslint-disable */
import lodash from 'lodash'
import decimal from '@/plugins/decimal'
import Vue from 'vue'

var CartShop = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, CartShop)
};

CartShop.relations = {};

CartShop.mixins = {
    'metadata': {},
    'cartHasOffers': {},
    'salepoint': {},
    'cart-salepoint': {},
    'timetable': {}
};

lodash.assign(CartShop.prototype, {

    minimumReached: function (deliveryMode, cart) {
        return this.getTotalPrice() >= this.getMinimumPrice(deliveryMode, cart);
    },

    /**
     * Retourne le délai de préparation de commande pickup, en minutes.
     *
     * @returns {number}
     */
    getPickupOrderDelay: function () {
        var delivery_mode = this.getDeliveryMode('pickup');
        return Number(delivery_mode && delivery_mode.delay) * 60;
    },

    getDeliveryModes: function () {
        var modes = this.deliveryModes || this.delivery || [];
        if (!this.getOwner()) {
            return modes;
        }

        return modes.concat(lodash.filter(this.getOwner().getDeliveryModes(), {enabled: true}));
    },

    addFees: function (type, rate, amount, label) {
        if (!this.fees) {
            this.fees = [];
        }
        var existing_fees = lodash.find(this.fees, {type: type});
        if (existing_fees) {
            existing_fees.rate = rate;
            existing_fees.label = label;
            existing_fees.amount = amount;
        } else {
            this.fees.push({
                type: type,
                rate: rate,
                label: label,
                amount: amount
            });
        }
        return this;
    },

    removeFees: function (type) {
        if (!this.fees) {
            this.fees = [];
        }
        this.fees = lodash.reject(this.fees, {type: type});
        return this;
    },

    hasFees: function (type) {
        if (type) {
            return lodash.find(this.fees || [], {type: type});
        }
        return this.fees && this.fees.length > 0;
    },

    getFees: function () {
        return this.fees || [];
    },

    getFeeTotal: function (fee) {
        if (fee.amount !== null && fee.amount !== undefined) {
            return fee.amount;
        }
        var tmp = decimal.mul(this.getTotalPrice(), fee.rate);
        return decimal.div(tmp, 100);
    },

    getTotalFees: function () {
        var that = this;
        return lodash.reduce(this.fees, function (total, fee) {
            return decimal.add(total + that.getFeeTotal(fee));
        }, 0);
    },

    getTotalPriceStuff: function () {
        return this.getTotalPriceWithFees();
    },

    getTotalPriceWithFees: function () {
        var total = decimal.add(this.getTotalPrice(), this.getTotalFees());
        return total > 0 ? total : 0;
    },

    /**
     * @internal checker aussi le modèle shop
     */
    isVirtual: function () {
        return !this.getDeliveryMode('pickup');
    },

    /**
     * @internal checker aussi le modèle shop
     */
    isIndependant: function () {
        return !this.getDeliveryMode('hub');
    }
});

export default CartShop;
