<template>
  <div>
    <input
      v-model="email"
      class="form-control"
      :class="{ 'is-invalid': !!invalid }"
      type="email"
      autocapitalize="none"
      :placeholder="$t('user.email')"
      name="login"
      :readonly="readonly"
      @blur="blurred = true"
    />
    <div class="invalid-feedback">
      {{ invalid }}
    </div>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'

export default {
  name: 'UserEmailField',
  mixins: [Rules],

  props: {
    value: { type: String },
    label: { type: String },
    readonly: { type: Boolean }
  },

  computed: {
    email: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>
