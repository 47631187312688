<template>
  <div ref="modal" class="modal fade" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!--
        <div class="modal-header">
          <h5 class="modal-title">Connexion</h5>
          <button type="button" class="btn-close" aria-label="Fermer" @click="close()"></button>
        </div>
        -->

        <Login :show="!!modal" @closeLoginEvent="logged" @loading="v => loading = v" />
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import Login from '@/components/MenusAndPopin/Login'

export default {
  name: 'LoginPopup',
  components: { Login },

  props: {
    returnPath: { type: String }
  },

  data: () => ({
    modal: null,
    loading: false
  }),

  beforeDestroy() {
    this.modal && this.modal.dispose()
  },

  mounted() {
    this.init()
    this.modal.show()
  },

  methods: {
    init() {
      this.modal = new Modal(this.$refs.modal, {
        keyboard: false,
        backdrop: 'static'
      })
      this.$refs.modal.addEventListener('hidePrevented.bs.modal', event => {
        this.close()
      })
      this.$refs.modal.addEventListener('hidden.bs.modal', event => {
        this.modal.dispose()
        this.$store.commit('modal/hide')
      })
    },

    close(force) {
      if (!force && this.loading) {
        return
      }
      this.modal.hide()
    },

    logged() {
      this.close(true)
      this.$router.push({ path: this.returnPath })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.modal-content {
  text-align: left;
}
</style>
