/* eslint-disable */
import lodash from 'lodash'

/**
 * Mixin
 *
 */
var Mixin = function (options) {

    this.getDeliveryModes = function () {
        var modes = this.hubsDeliveryModes || this.delivery || [];
        if (!this.getOwner()) {
            return modes;
        }

        return lodash.unionBy(modes, lodash.filter(this.getOwner().getHubsDeliveryModes(), {enabled: true}), 'code');
    };

    /**
     * Retourne le délai de préparation de commande pickup, en minutes.
     *
     * @returns {number}
     */
    this.getPickupOrderDelay = function (hours) {
        var delivery_mode = this.getDeliveryMode('pickup') || {},
            delay = Number(delivery_mode.build) + Number(delivery_mode.shops);

        return hours ? delay : delay * 60;
    };

    return this;
};

export default Mixin;
