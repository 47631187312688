<template>
  <div ref="modal" class="modal fade cat-shops-modal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" :aria-label="$t('base.close')" @click="close()"></button>
        </div>

        <div class="modal-body">
          <CategorieCommercantSlider :category-id="categoryName" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import CategorieCommercantSlider from '@/components/Sliders/CategorieCommercantSlider'

export default {
  name: 'NosCategoriesItemPopup',
  components: { CategorieCommercantSlider },
  props: {
    categoryName: { type: String }
  },

  data: () => ({
    modal: null
  }),

  watch: {
    $route: 'close'
  },

  beforeDestroy() {
    this.close()
  },

  mounted() {
    this.init()
    this.modal.show()
  },

  methods: {
    init() {
      this.modal = new Modal(this.$refs.modal, {
        keyboard: false,
        backdrop: 'static'
      })
      this.$refs.modal.addEventListener('hidePrevented.bs.modal', event => {
        this.close()
      })
      this.$refs.modal.addEventListener('hidden.bs.modal', event => {
        this.modal.dispose()
        this.$store.commit('modal/hide')
      })
    },

    close(force) {
      if (!force && this.loading) {
        return
      }
      this.modal.hide()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';
.cat-shops-modal .modal-dialog {
  width: 95% !important;
  max-width: 1200px !important;
}

.cat-shops-modal .modal-header {
  border:none;
  justify-content: flex-end;
  padding: 0;

  .btn-close {
    background-size: contain;
    width: 25px;
    height: 25px;
    padding:0;
    margin: 10px;

    @include media-breakpoint-up(md) {
      width: 30px;
      height: 30px;
      margin: 20px;
    }
  }
}

.cat-shops-modal .modal-body {
  padding: 0;
  @include media-breakpoint-up(md) {
    padding: 0px 45px 30px 45px;
  }
}
</style>
