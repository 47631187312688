/* eslint-disable */

/**
 * Mixin
 *
 */
var Mixin = function (options) {

    this.getLastModified = function () {
        return this.metadata.updated_at;
    };

    this.getCreatedAt = function () {
        return this.metadata.created_at;
    };

    this.getUpdatedBy = function () {
        return this.metadata.updated_by;
    };

    return this;
};

export default Mixin;
