import Vue from 'vue'
import store from '../stores'

/**
 * Expected binding:
 * - 'role'
 * - ['role']
 * - { roles: ['role'], tenant: tenantId|current|undefined }
 * - { roles: 'role', tenant: tenantId|current|undefined }
 */
const canFn = can => function(el, binding) {
  const data = binding.value

  let roles = data
  if (typeof data === 'string') {
    roles = { roles: [data] }
  } else if (Array.isArray(data)) {
    roles = { roles: data }
  } else if (roles.roles === 'string') {
    roles.roles = [roles.roles]
  }

  if (roles.tenant === 'current') {
    roles.tenant = store.getters['sp/current'].id
  }

  const connected = store.getters['auth/user']
  if (!connected.id && roles.roles.indexOf('public') !== -1) {
    el && el.style.setProperty('display', can ? '' : 'none', can ? '' : 'important')
    return can
  }

  const croles = (connected.roles || [])
    .filter(r => !roles.tenant || r.tenant_id === roles.tenant)
    .map(r => r.role)

  const intersection = croles.filter(r => roles.roles.includes(r))

  if (!connected.id || (can && !intersection.length) || (!can && intersection.length)) {
    el && el.style.setProperty('display', 'none', 'important')
    return false
  } else {
    if (el) {
      el.style.display = ''
    }
    return true
  }
}

Vue.directive('can', {
  bind: canFn(true),
  update: canFn(true)
})

Vue.directive('cannot', {
  bind: canFn(false),
  update: canFn(false)
})

Vue.filter('can', roles => {
  return canFn(true)(null, { value: roles })
})
