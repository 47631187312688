/* eslint-disable */
import lodash from 'lodash'
import Vue from 'vue'


var SupplierCategory = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, SupplierCategory)
};

SupplierCategory.relations = {};

lodash.assign(SupplierCategory.prototype, {

    getTitle(lang) {
        if (lodash.isString(this.title)) {
            return this.title;
        }
        return this.title[lang] || this.title.fr;
    }

});

export default SupplierCategory;
