/* eslint-disable */
import lodash from 'lodash'

/**
 * Mixin qui donne le pouvoir à un service de mettre en cache ses résultats de méthode
 *
 * @param {Object} options Paramétrage du mixin
 *          - container: le nom de la propriété qui va contenir le cache.
 */
var Mixin = function (options) {
    var container = options && options.container ? options.container : '_cache';
    this[container] = {};

    this.memoize = function (methodName) {
        var params = Array.prototype.slice.call(arguments, 1),
            method = this[methodName],
            cache_path = methodName + '.' + params.join('.');

        if (!lodash.get(this[container], cache_path)) {
            lodash.set(this[container], cache_path, method.call(this, params));
        }

        return lodash.get(this[container], cache_path);
    };

    return this;
};

export default Mixin;
