<template>
  <div
    id="main-menu"
    :style="{ height: `${height}px` }"
    class="main-menu"
    tabindex="-1"
    @focus="openMenuButton"
    @focusout="closeMenuButton"
  >
    <ul>
      <li class="menu-item">
        <div class="header-menu">
          <img
            src="@/assets/ville-icon-white.png"
            class="icon"
            alt="Icone ville"
            @click="closeMenuButton"
          />
        </div>
        <div
          class="header-ville-content"
          @click="closeMenuButton"
        >
          <template v-if="!!currentPartner">
            <div class="header-ville-label active">
              {{ currentPartner.name }}
            </div>
            <div class="header-ville-changer active">
              {{ $t('content.header.changeCity') }}
            </div>
          </template>
          <div v-else class="header-ville-choisir active">
            {{ $t('content.header.chooseCity') }}
          </div>
        </div>
      </li>
      <li v-for="partner in partners" :key="partner.id" class="menu-item">
        <a :href="$router.resolve({ name: 'partner', params: { partnerId: partner.id } }).href">
          <span class="item_text">{{ partner.name }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import ResizeMixin from '@/mixins/ResizeMixin'

export default {
  name: 'TopMainMenuVille',
  mixins: [ResizeMixin],
  props: {
    choisir: {
      type: Boolean,
      default: false
    },
    changer: {
      type: Boolean,
      defaut: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    partners() {
      return this.$store.getters['partner/partners']
    },
    currentPartner() {
      return this.$store.getters['partner/current']
    },
    partnerId() {
      return this.$route.params.partnerId
    }
  },
  mounted() {
    this.loading = true
    return Promise
      .all([
        this.$store.dispatch('partner/list'),
        this.partnerId && this.$store.dispatch('partner/get', { id: this.partnerId })
      ])
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },
  methods: {
    openMenuButton(event) {
      // console.log('OPEN!')
    },
    closeMenuButton(event) {
      this.$emit('closeMenuEvent')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../assets/styles/youpaq.scss';

#main-menu {
  position: absolute;
  top: -15px;
  left: -10px;
  background-color: var(--red-color);
  color: white;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0px 0px 6px #0000008c;
  min-width: 200px;
  z-index: 3000;
  overflow-y: auto;

  @include media-breakpoint-up(md) {
    left: -10px;
  }
  .header-menu img {
    margin-right: 10px;
    cursor: pointer;
  }
  .header-ville-label  {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }
  .header-ville-label:not(.active) {
    display: none;
  }
  .header-ville-choisir, .header-ville-changer  {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .header-ville-choisir:not(.active) {
    display: none;
  }
  .header-ville-changer:not(.active) {
    display: none;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  ul li:first-child {
    border: none;
    display: flex;
    align-items: center;
    padding-top: 13px;
    font-weight: 700;
  }
  .menu-item {
    display: flex;
    padding: 20px;
    border-top: 1px solid white;
  }
  .menu-item a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  .menu-item a:hover {
    color: #f7cec6;
  }
  .header-menu img {
    max-width: 80px;
    max-height: 30px;
    cursor: pointer;
  }
  .header-logo {
    width: 120px;
  }
  .header-logo img {
    max-width: 100px;
  }
}
</style>
