<template>
  <div
    id="payment-logos"
  >
    <div class="d-flex justify-content-center justify-content-md-start">
      <img src="@/assets/payment-logo-visa-mc.png">
      <img src="@/assets/payment-logo-twint.png">
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaiementLogos'
}
</script>

<style lang="scss">
@import '../../assets/styles/youpaq.scss';

#payment-logos {
    margin: 0;

    img {
        max-height: 40px;
        margin-right: 10px;
    }

    img:last-child {
      margin-right: 0;
    }
}
</style>
