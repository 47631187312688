/* eslint-disable */
import lodash from 'lodash'
import Vue from 'vue'

var Shop = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, Shop)
};

Shop.relations = {};

Shop.mixins = {
    'metadata': {},
    'salepoint': {},
    'imageSquare': {},
    'timetable': {},
    'updatable': {
        getJson: {
            merge: {type: 'shop'}
        }
    }
};

lodash.assign(Shop.prototype, {

    getDescription(lang) {
        if (lodash.isString(this.description)) {
            return this.description;
        }
        return this.description[lang] || this.description.fr;
    },

    getDescriptionShort(lang) {
        if (lodash.isString(this.descriptionShort)) {
            return this.descriptionShort;
        }
        return this.descriptionShort[lang] || this.descriptionShort.fr;
    },

    getDeliveryModes: function () {
        var modes = this.deliveryModes || this.delivery || [];
        if (!this.getOwner()) {
            return modes;
        }

        return modes.concat(lodash.filter(this.getOwner().getDeliveryModes(), {enabled: true}));
    },

    formatTimetable: function () {
        return {
            timetable: this.timetable,
            closed: this.closed
        };
    },

    /**
     * @interal checker aussi le modèle cart-shop
     */
    isVirtual: function () {
        return !this.getDeliveryMode('pickup');
    },

    /**
     * Détermine si le shop est indépendant (ne livre dans aucun HUB)
     *
     * @interal checker aussi le modèle cart-shop
     * @return {Boolean}
     */
    isIndependant: function () {
        return !this.getDeliveryMode('hub');
    }
});

export default Shop;
