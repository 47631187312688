import Vue from 'vue'

const Hub = Vue.youpaqModel.Hub

export default {
  namespaced: true,

  state: {
    timetable: {},
    hubs: {},
    privateCount: null,
    privateHubs: []
  },

  getters: {
    privateHubs: state => state.privateHubs
  },

  mutations: {
    reset(state) {
      state.timetable = {}
      state.hubs = {}
      state.privateCount = null
      state.privateHubs = []
    }
  },

  actions: {
    async get({ state }, { id }) {
      if (state.hubs[id]) {
        return state.hubs[id]
      }
      const res = await Vue.prototype.$api.get(`/front/hubs/${id}`)

      state.hubs[id] = new Hub(res.data)

      return state.hubs[id]
    },

    async getOpenings({ state }, { id }) {
      if (state.timetable[id]) {
        return state.timetable[id]
      }
      const res = await Vue.prototype.$api.get(`/front/hubs/${id}/timetable`)

      res.data.timetable.forEach(day => {
        if (day.deliverySlices) {
          day.deliverySlices = day.deliverySlices.split('\n').map(slice => {
            const start = slice.split('-').shift()
            return {
              value: start,
              text: slice.replace('-', ' - ')
            }
          })
        } else {
          day.deliverySlices = []
        }
      })

      state.timetable[id] = res.data
      return state.timetable[id]
    },

    async searchByZip(context, { zip }) {
      const res = await Vue.prototype.$api.get('/front/hubs-search', {
        params: {
          zip
        }
      })
      return res.data.map(hub => new Hub(hub))
    },

    async countPrivate({ state }, { partnerId }) {
      const res = await Vue.prototype.$api.get(`/front/partners/${partnerId}/privatehubscount`)
      state.privateCount = res.data
      return state.privateCount
    },

    async searchPrivate({ state }, { partnerId, code }) {
      const res = await Vue.prototype.$api.get(`/front/partners/${partnerId}/privateHub`, {
        params: { code }
      })
      const hub = new Hub(res.data)
      if (!state.privateHubs.find(hub => hub.id === res.data.id)) {
        state.privateHubs.push(hub)
      }
      return hub
    },

    async searchByTag(context, { tag }) {
      const res = await Vue.prototype.$api.get('/front/hubs-tags', {
        params: {
          tag
        }
      })
      return res.data.map(hub => new Hub(hub))
    }
  }
}
