export default {
  data() {
    return {
      height: 200,
      offsetHeight: 80,
      onResize: this.onResizeHandler
    }
  },

  created() {
    window.addEventListener('resize', this.onResize)
    this.onResizeHandler()
  },

  onBeforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResizeHandler() {
      this.height = window.innerHeight - this.offsetHeight
    }
  }
}
