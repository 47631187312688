export default {
  namespaced: true,

  state: {
    switch: false,
    categoryId: null
  },

  getters: {
    switch: state => state.switch,
    data: state => ({
      categoryId: state.categoryId
    })
  },

  mutations: {
    focus(state, { categoryId }) {
      state.categoryId = categoryId
      state.switch = !state.switch
    },

    reset(state) {
      state.categoryId = null
    }
  },

  actions: {
  }
}
