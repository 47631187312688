<template>
  <button
    :type="type"
    :color="color"
    :disabled="disabled || loading"
    :class="{ disabled: disabled }"
    @click="ev => !disabled && $emit('click', ev)"
  >
    <template v-if="!loading">{{ text }}</template>
    <img v-else src="/imgs/loading-small.gif" />
  </button>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    color: {
      type: String,
      default: 'red'
    },
    text: {
      type: String,
      default: 'Continuer'
    },
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

button {
    background-color: var(--red-color);
    border: none;
    padding: 10px 20px;
    border-radius: 10px !important;
    color: white;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 1px 1px 5px #00000087;
    margin-top: 20px !important;

    img {
      max-width: 27px;
      max-height: 27px;
    }
}
button[color="red"] {
  background-color: var(--red-color);
}
button[color="yellow"] {
  background-color: var(--yellow-color);
}
button[color="white-text-red"] {
  background-color: white;
  color: var(--red-color);
}
button.disabled {
  background-color: rgb(200, 200, 200) !important;
}
</style>
