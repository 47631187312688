import Vue from 'vue'

const Hub = Vue.youpaqModel.Hub
const Shop = Vue.youpaqModel.Shop
const Article = Vue.youpaqModel.Article
const PartnerFilter = Vue.youpaqModel.PartnerFilter
const SupplierCategory = Vue.youpaqModel.SupplierCategory

export default {
  namespaced: true,

  state: {
    banners: [],
    partners: [],
    shops: [],
    hubs: [],
    categories: [],
    allCategories: [],
    filters: [],
    selectionProducts: [],
    current: null
  },

  getters: {
    banners: state => state.banners,
    partners: state => state.partners,
    shops: state => state.shops,
    hubs: state => state.hubs,
    hubsByMode: state => mode => state.hubs.filter(hub => hub.hasDeliveryMode(mode)),
    current: state => state.current,
    categories: state => state.categories,
    allCategories: state => state.allCategories,
    filters: state => state.filters
  },

  mutations: {
    reset(state) {
      state.banners = []
      state.shops = []
      state.hubs = []
      state.categories = []
      state.allCategories = []
      state.filters = []
      state.selectionProducts = []
      // no need to reset partners list, it is the same across them
    }
  },

  actions: {
    async list({ state }) {
      if (state.partners.length) {
        return state.partners
      }

      const res = await Vue.prototype.$api.get('/front/partners')

      state.partners = res.data
        .reduce((acc, state) => acc.concat(state.cities), [])
        .sort((a, b) => a.name.localeCompare(b.name))

      return state.partners
    },

    async get({ state }, { id }) {
      if (state.current && state.current.id === id) {
        return state.current
      }
      const res = await Vue.prototype.$api.get(`/front/partners/${id}`, {
        params: {
          setSession: true
        }
      })
      state.current = res.data

      return state.current
    },

    async banners({ state }, { id }) {
      if (state.banners.length && id === state.current.id) {
        return state.banners.length
      }
      state.banners = []

      const res = await Vue.prototype.$api.get(`/front/partners/${id}/banners`)
      state.banners = res.data

      return state.banners
    },

    async shops({ state }, { id }) {
      if (state.shops.length && id === state.current.id) {
        return state.shops
      }
      const res = await Vue.prototype.$api.get(`/front/partners/${id}/salepoints`)

      state.shops = res.data
        .filter(salepoint => salepoint.type === 'shop')
        .map(shop => new Shop(shop))

      return state.shops
    },

    async hubs({ state }, { id }) {
      if (state.hubs.length && id === state.current.id) {
        return state.hubs
      }
      const res = await Vue.prototype.$api.get(`/front/partners/${id}/hubs`)

      state.hubs = res.data.map(hub => new Hub(hub))

      return state.hubs
    },

    async categories({ state }, { id }) {
      if (state.allCategories.length && !id) {
        return state.allCategories
      }
      if (state.categories.length && id === state.current.id) {
        return state.categories
      }

      if (id) {
        const res = await Vue.prototype.$api.get(`/front/partners/${id}/categories`)
        state.categories = res.data.map(c => new SupplierCategory(c))
        return state.categories
      }
      const res = await Vue.prototype.$api.get('/front/categories')
      state.allCategories = res.data.map(c => new SupplierCategory(c))
      return state.allCategories
    },

    async filters({ state }, { id }) {
      if (state.filters.length && id === state.current.id) {
        return state.filters
      }
      const res = await Vue.prototype.$api.get(`/front/partners/${id}/filters`)

      state.filters = res.data.map(filter => new PartnerFilter(filter))

      return state.filters
    },

    async filterFavourite({ dispatch }, { id }) {
      const filters = await dispatch('filters', { id })
      const filter = filters.find(filter => filter.mode === 'favourite')
      if (!filter) {
        throw new Error('Aucun filtre favoris configuré pour cette ville!')
      }
      return filter
    },

    async selectionProducts({ state }, { id }) {
      if (state.selectionProducts.length) {
        return state.selectionProducts
      }
      const res = await Vue.prototype.$api.get(`/front/partners/${id}/articles`, {
        params: {
          start: 0,
          limit: 50,
          tags: 'Y-SELECTION'
        }
      })

      state.selectionProducts = res.data.map(article => new Article(article))

      return state.selectionProducts
    }
  }
}
