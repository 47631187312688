<template>
  <div ref="modal" class="modal fade" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('articlePopup.title') }}</h5>
          <button type="button" class="btn-close" aria-label="Fermer" @click="close()"></button>
        </div>

        <CartAjoutProduit
          v-if="article"
          ref="adder"
          class="modal-body"
          :article="article"
          :quantity.sync="quantityLocal"
          :selected-quantity.sync="selectedQuantityLocal"
          :portions.sync="portionsLocal"
          :choose-type.sync="chooseTypeLocal"
          :valid.sync="valid"
          :mode="mode"
        />

        <div v-if="offer" class="modal-footer">
          <button
            :disabled="!valid || loading"
            type="submit"
            @click="submit"
          >
            {{ $t('articlePopup.add', { amount: $options.filters.price(offer.getPrice(quantityLocal)) }) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import CartAjoutProduit from '@/components/CartCheckout/CartAjoutProduit'

export default {
  name: 'ProductAddPopup',
  components: { CartAjoutProduit },
  props: {
    article: { type: Object },
    offer: { type: Object },
    shopId: { type: String },
    quantity: { type: [String, Number] },
    selectedQuantity: { type: [String, Number] },
    portions: { type: [String, Number] },
    chooseType: { type: String }
  },

  data: () => ({
    modal: null,
    loading: false,
    valid: false,
    chooseTypeLocal: 'unit',
    quantityLocal: null,
    selectedQuantityLocal: null,
    portionsLocal: null
  }),

  computed: {
    mode() {
      return this.quantity || this.portions || this.selectedQuantity ? 'edit' : 'add'
    }
  },

  beforeDestroy() {
    this.modal && this.modal.dispose()
  },

  mounted() {
    this.init()
    this.modal.show()
  },

  methods: {
    init() {
      this.quantityLocal = this.quantity || null
      this.selectedQuantityLocal = this.selectedQuantity || null
      this.portionsLocal = this.portions || null
      this.chooseTypeLocal = this.chooseType || 'unit'

      if (!this.article.reseller_offers || !this.article.reseller_offers.length) {
        this.$set(this.article, 'reseller_offers', [this.offer])
      }

      this.modal = new Modal(this.$refs.modal, {
        keyboard: false,
        backdrop: 'static'
      })
      this.$refs.modal.addEventListener('hidePrevented.bs.modal', event => {
        this.close()
      })
      this.$refs.modal.addEventListener('hidden.bs.modal', event => {
        this.modal.dispose()
        this.$store.commit('modal/hide')
      })

      this.$refs.adder.init()
    },

    close(force) {
      if (!force && this.loading) {
        return
      }
      this.modal.hide()
    },

    submit() {
      if (this.loading || !this.valid) {
        return
      }
      this.loading = true
      return this.$refs.adder
        .submit(this.shopId, this.offer.id)
        .then(() => {
          // on va indiquer que le processus est terminé
          this.$store.commit('modal/done', { offerId: this.offer.id })
          this.close(true)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.modal-content {
  text-align: left;
}

.modal-header {
  background-color: var(--red-color);
  color: white;

  .modal-title {
    font-weight: 600 !important;
  }

  .btn-close {
    color: white;
  }
}

.modal-body {
  padding: 30px 40px 0px 40px;
}

.modal-footer {
  border: none;
  padding: 20px 40px 30px 40px;

  button {
    background-color: var(--red-color);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 25px;
    color: white;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 1px 1px 4px #00000069;
  }
}
</style>
