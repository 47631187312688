import Vue from 'vue'
import lodash from 'lodash'

const Article = Vue.youpaqModel.Article

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async getCampaignArticles(context, { promotionCampaignId, offset, limit }) {
      const res = await Vue.prototype.$api.get(`/promotioncampaigns/${promotionCampaignId}/promotions`, {
        params: {
          offset,
          limit
        }
      })
      return res.data.map(promo => {
        const article = lodash.cloneDeep(promo.article)
        delete promo.article
        if (article.reseller_offers?.length) {
          article.reseller_offers[0].promotion = promo
        }
        return new Article(article)
      })
    },

    async autoPromocode() {
      const res = await Vue.prototype.$api.get('/front/promocodes/auto')
      return res.data
    },

    async applyPromocode(context, { code }) {
      const res = await Vue.prototype.$api.post(`/front/promocodes/${(code || '').toUpperCase()}`)
      return res.data
    },

    async unapplyPromocode() {
      const res = await Vue.prototype.$api.delete('/front/promocodes')
      return res.data
    },

    async getPromocode(context, { code }) {
      const res = await Vue.prototype.$api.get(`/front/promocodes/${code}`)
      return res.data
    }
  }
}
