/* eslint-disable */
import lodash from 'lodash'
import Vue from 'vue'

var CartOffer = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, CartOffer)
};

CartOffer.relations = {
    article: 'Article',
    shop: 'Shop',
    promotion: 'Promotion'
};

CartOffer.mixins = {
    'metadata': {},
    'pricing': {},
    'isOffer': {}
};

lodash.assign(CartOffer.prototype, {

    getId: function () {
        return this.id;
    },

    getArticle: function () {
        return this.article;
    },

    getShop: function () {
        return this.shop;
    },

    getQuantity: function () {
        return this.quantity;
    },

    setQuantity: function (value) {
        this.quantity = value;
        return this;
    },

    clone() {
        const data = lodash.cloneDeep(this);
        return new CartOffer(data);
    }
});

export default CartOffer;
