<template>
  <div ref="modal" class="modal fade" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <form class="modal-content" @submit.prevent.stop="submit">
        <div class="modal-header">
          <button type="button" class="btn-close" aria-label="Fermer" @click="close()"></button>
        </div>

        <template v-if="!done">
          <div class="modal-body">
            <div>
              <img src="@/assets/pass-recovery-icon.svg">
            </div>
            <div>
              <h5 class="modal-title">{{ $t('userPasswordReminderPopup.title') }}</h5>
              <p>{{ $t('userPasswordReminderPopup.text') }}</p>
            </div>
          </div>

          <div class="modal-footer">
            <UserEmailField v-model="$v.email.$model" :rules="$v.email" class="email-input" />
            <button
              :disabled="loading || $v.$invalid"
              type="submit"
              class="btn btn-primary"
            >
              {{ $t('userPasswordReminderPopup.submit') }}
            </button>
          </div>
        </template>
        <div v-else class="modal-body">
          <div class="alert alert-success">
            <p>{{ $t('userPasswordReminderPopup.success') }}</p>
            <hr />
            <SpamMessage />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import UserEmailField from '@/components/User/UserEmailField'
import SpamMessage from '@/components/MenusAndPopin/SpamMessage'
import { Modal } from 'bootstrap'
import { required, email } from 'vuelidate/lib/validators'
import Rules from '@/mixins/Rules'

export default {
  name: 'AccountPasswordReminderPopup',

  components: { UserEmailField, SpamMessage },
  mixins: [Rules],

  data: () => ({
    modal: null,
    loading: false,
    done: false,
    email: null
  }),

  validations: {
    email: { required, email }
  },

  beforeDestroy() {
    this.modal && this.modal.dispose()
  },

  mounted() {
    this.init()
    this.modal.show()
  },

  methods: {
    init() {
      this.modal = new Modal(this.$refs.modal, {
        keyboard: false,
        backdrop: 'static'
      })
      this.$refs.modal.addEventListener('hidePrevented.bs.modal', event => {
        this.close()
      })
      this.$refs.modal.addEventListener('hidden.bs.modal', event => {
        this.modal.dispose()
        this.$store.commit('modal/hide')
      })
    },

    close(force) {
      if (!force && this.loading) {
        return
      }
      this.modal.hide()
    },

    submit() {
      this.loading = true
      return this.$store.dispatch('auth/passwordReminderSend', { email: this.email })
        .then(() => (this.done = true))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.modal-dialog {
  max-width: 750px;
}

.modal-content {
  text-align: left;
  color: white;
  background-color: var(--blue-color);
  border-radius: 0;

  .modal-header {
    border-bottom: 0;
  }
  .modal-body {
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      padding: 0 40px;
      flex-direction: row;
    }
    img {
      max-height: 100px;
      padding-right: 20px;
      padding-bottom: 20px;

      @include media-breakpoint-up(md) {
        max-height: 120px;
      }
    }
    h5 {
      font-size: 25px;
      line-height: 25px;
      font-weight: 900;
      padding-bottom: 15px;

      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
    }
    p {
      font-size: 16px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    border: 0;
    padding: 0 20px 40px 20px;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
      padding: 15px 40px 40px 40px;
    }

    .email-input {
      width: 100%;
      padding-bottom: 10px;

      @include media-breakpoint-up(md) {
        width: 75%;
      }
    }

    input {
      width: 250px;
      height: 40px;
      border-color: #00000052;
      box-sizing: border-box;
      border: none;
      box-shadow: inset 1px 1px 3px #0000009a;
      margin-top: 25px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 400;
      font-family: inherit;
      padding: 20px;
    }

    button {
      background-color: var(--light-purple-color);
      border: none;
      padding: 10px 20px;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      font-weight: 700;
      box-shadow: 1px 1px 5px #00000087;
      cursor: pointer;
    }
  }
}
</style>
