<template>
  <div v-if="loading" class="loading">
    <div class="loader">
      <img src="@/assets/loading.gif" title="loading" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  computed: {
    loading() {
      return this.$store.getters['load/loading']
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);

  .loader {
    position: absolute;
    top: 40%; left: 50%;
    transform: translate(-50%);

    img {
      opacity: 0.5
    }
  }
}
</style>
