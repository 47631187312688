<template>
  <div
    id="user-menu"
    class="main-menu"
  >
    <div
      class="user-menu-button"
      @click="closeMenuButton"
    />
    <ul>
      <li v-if="partnerId" class="menu-item" @click="closeMenuButton">
        <router-link
          :to="{ name: 'favourite' }"
        >
          {{ $t('content.menu.favourite') }}
        </router-link>
      </li>
      <li class="menu-item" @click="closeMenuButton">
        <router-link
          :to="{ name: 'espaceperso', hash: '#account' }"
        >
          {{ $t('content.menu.account') }}
        </router-link>
      </li>
      <li class="menu-item" @click="closeMenuButton">
        <router-link
          :to="{ name: 'espaceperso', hash: '#orders' }"
        >
          {{ $t('content.menu.orders') }}
        </router-link>
      </li>
      <li class="menu-item" @click="closeMenuButton">
        <a href="#" @click="doLogout">
          <img
            src="@/assets/logout-icon.png"
            class="logout-icon"
            alt="Icone logout"
          >
          {{ $t('content.menu.logout') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TopUserMenu',
  props: {
    choisir: {
      type: Boolean,
      default: false
    },
    changer: {
      type: Boolean,
      defaut: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    partners() {
      return this.$store.getters['partner/partners']
    },
    currentPartner() {
      return this.$store.getters['partner/current']
    },
    partnerId() {
      return this.$route.params.partnerId
    }
  },
  mounted() {
    this.loading = true
    return Promise
      .all([
        this.$store.dispatch('partner/list'),
        this.partnerId && this.$store.dispatch('partner/get', { id: this.partnerId })
      ])
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },
  methods: {
    doLogout() {
      return this.$store.dispatch('auth/logout')
        .catch(err => this.$err(err))
        .finally(() => {
          if (!this.$route.params.partnerId) {
            // no redirection if not inside a city
            return
          }
          this.$router.push({ name: 'home' })
        })
    },
    closeMenuButton(event) {
      this.$emit('closeUserMenuEvent')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../assets/styles/youpaq.scss';

#user-menu {
  position: absolute;
  top: 43px;
  left: -186px;
  background-color: var(--red-color);
  color: white;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0px 0px 6px #0000008c;
  min-width: 230px;
  z-index: 3000;

  @include media-breakpoint-up(md) {
    left: -170px;
  }

  .user-menu-button {
    /* content: ""; */
    position: absolute;
    background-image: url("~@/assets/login-form-tab.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 90px;
    height: 240px;
    top: -54px;
    right: 0px;
    cursor: pointer;
    z-index: -1;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  ul li:first-child {
    border-top: none !important;
  }
  ul li {
    display: flex;
    padding: 20px;
    border-top: 1px solid white;
  }
  .menu-item a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  .menu-item a:hover {
    color: #f7cec6;
  }
  .menu-item img.logout-icon {
    filter: brightness(0) invert(1);
    width: 25px;
    margin-right: 5px;
  }
}
</style>
