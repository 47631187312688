/* eslint-disable */
import lodash from 'lodash'
import Vue from 'vue'

var Hub = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, Hub)
};

Hub.relations = {
    firstShop: 'Shop'
};

Hub.mixins = {
    'metadata': {},
    'salepoint': {},
    'isHub': {},
    'updatable': {
        getJson: {
            merge: {type: 'hub'}
        }
    }
};

lodash.assign(Hub.prototype, {

    hasDeliveryMode: function (hubMode) {
        const mode = this.getDeliveryMode(hubMode)
        if (!mode || mode.enabled === false) {
            return false
        }
        if (hubMode !== 'home' && this.isDeliveryHomeOnly()) {
            return false
        }
        return true
    },

    isDeliveryHomeOnly() {
        return !!this.homeOnly
    },

    deliverZipCode: function (zip) {
        const mode = this.getDeliveryMode('home')
        if (!mode || mode.enabled === false) {
            return false
        }
        return (mode.npa || []).find(npa => npa.npa === zip)
    }
});

export default Hub;
