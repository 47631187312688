<template>
  <div ref="modal" class="modal fade horaire-modal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 lass="modal-title">{{ $t('schedules.title') }}</h5>
          <button type="button" class="btn-close" aria-label="Fermer" @click="close()"></button>
        </div>

        <div class="modal-body">
          <p class="text-start">
            {{ $t('schedules.content') }}
          </p>
          <CartHoraireShops :partner-id="partnerId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import CartHoraireShops from '@/components/CartCheckout/CartHoraireShops'

export default {
  name: 'CartHoraireShopsPopup',
  components: { CartHoraireShops },
  props: {
    partnerId: { type: String }
  },

  data: () => ({
    modal: null
  }),

  beforeDestroy() {
    this.close()
  },

  mounted() {
    this.init()
    this.modal.show()
  },

  methods: {
    init() {
      this.modal = new Modal(this.$refs.modal, {
        keyboard: false,
        backdrop: 'static'
      })
      this.$refs.modal.addEventListener('hidePrevented.bs.modal', event => {
        this.close()
      })
      this.$refs.modal.addEventListener('hidden.bs.modal', event => {
        this.modal.dispose()
        this.$store.commit('modal/hide')
      })
    },

    close(force) {
      if (!force && this.loading) {
        return
      }
      this.modal.hide()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';
.horaire-modal .modal-header {
  background-color: var(--red-color);
  color: white;

  h5 {
    font-weight: 600;
  }
}
</style>
