import App from './App.vue'
import Vue from 'vue'
import ProductAddPopup from '@/components/MenusAndPopin/ProductAddPopup'
import HubDeliveryPopup from '@/components/MenusAndPopin/HubDeliveryPopup'
import AccountPasswordReminderPopup from '@/components/MenusAndPopin/AccountPasswordReminderPopup'
import LoginPopup from '@/components/MenusAndPopin/LoginPopup'
import CartHoraireShopsPopup from '@/components/MenusAndPopin/CartHoraireShopsPopup'
import NosCategoriesItemPopup from '@/components/Categories/NosCategoriesItemPopup'

import './plugins/messages'
import './plugins/storage'
import './plugins/marked'
import './plugins/images'
import './plugins/filters'
import './plugins/iframe'
import './models'

import store from './stores'
import i18nBoot from './plugins/i18n'
import './plugins/can'
import './plugins/axios'
import router from './router'
import './plugins/analytics'
import './registerServiceWorker'

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.config.productionTip = false

const boot = async App => {
  Vue.prototype.$api.defaults.baseURL = process.env.VUE_APP_API

  const apiConfig = await Vue.prototype.$api.get('/front/config')

  const forceDatatransProd = process.env.VUE_APP_DATATRANS_FORCE_PROD === 'true'

  const datatransScript = !forceDatatransProd && apiConfig.data.env === 'development'
    ? 'https://pay.sandbox.datatrans.com/upp/payment/js/datatrans-2.0.0.js'
    : 'https://pay.datatrans.com/upp/payment/js/datatrans-2.0.0.js'

  Vue.prototype.$config = {
    ...apiConfig.data,
    api: process.env.VUE_APP_API,
    local: !!process.env.VUE_APP_LOCAL,
    datatransScript: datatransScript,
    forceDatatransProd: forceDatatransProd,
    infoSiteUrl: 'https://wp-youpaq.spoutnik.org',
    googleAnalyticsEnabled: ['www.youpaq.com', 'youpaq.com'].indexOf(window.location.hostname) === 0,
    theme: {
      color: {
        yellow: '#E1AC30',
        darkYellow: '#E89E0B',
        green: '#86B83E',
        red: '#E45E43',
        gray: '#3E3E3D',
        purple: '#673A8F',
        lightPurple: '#BA8DC0',
        mediumPurple: '#AC5B9F',
        blue: '#64BDDF'
      }
    }
  }

  const i18n = await i18nBoot.init(Vue.prototype.$config, store)

  store.commit('lang/available', { available: Vue.prototype.$config.availableLangs })
  store.commit('lang/defaultLn', { defaultLn: Vue.prototype.$config.defaultLang })
  await store.dispatch('lang/current', { current: Vue.prototype.$storage.get('ypq-lang') || Vue.prototype.$config.lang })

  Vue.component('ProductAddPopup', ProductAddPopup)
  Vue.component('HubDeliveryPopup', HubDeliveryPopup)
  Vue.component('AccountPasswordReminderPopup', AccountPasswordReminderPopup)
  Vue.component('LoginPopup', LoginPopup)
  Vue.component('CartHoraireShopsPopup', CartHoraireShopsPopup)
  Vue.component('NosCategoriesItemPopup', NosCategoriesItemPopup)

  // connected session autologin
  return store.dispatch('auth/check')
    .finally(() => {
      new Vue({
        store,
        router,
        i18n,
        render: h => h(App)
      }).$mount('#app')
    })
}

boot(App)
