<template>
  <div
    class="selectdiv"
    :color="color"
  >
    <select
      v-model="current"
      :disabled="loading"
      class="lang-selector"
      name="lang"
    >
      <option
        v-for="lang in available"
        :key="lang"
        :value="lang"
        :disabled="loading"
      >
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LangSelector',
  props: {
    color: {
      type: String,
      default: 'gray'
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    current: {
      get() {
        return this.$store.getters['lang/current'].toUpperCase()
      },
      set(v) {
        this.loading = true
        this.$store.dispatch('lang/current', { current: v })
          .finally(() => (this.loading = false))
      }
    },

    available() {
      return this.$store.getters['lang/available'].map(ln => ln.toUpperCase())
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/youpaq.scss';
.selectdiv {
  position: relative;
  width: 44px;
  margin: 0 auto;

  .lang-selector option {
    color: black;
  }
}
.selectdiv[color="white"] {
  &:after {
    background: url("~@/assets/arrow-down-white.svg") no-repeat;
  }

  .lang-selector {
    color: white;
  }
}
.selectdiv[color="gray"] {
  &:after {
    background: url("~@/assets/arrow-down-gray.svg") no-repeat;
  }

  .lang-selector {
    color: var(--gray-color);
  }
}
.selectdiv:after {
    content: '';
    background: url("~@/assets/arrow-down-gray.svg") no-repeat;
    background-size: contain;
    right: 0px;
    top: 8px;
    height: 15px;
    width: 15px;
    position: absolute;
    pointer-events: none;
}
/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}
.selectdiv .lang-selector {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  display: block;
  border: none;
  background: transparent;
  width: 100%;
  font-size: 16px;
  cursor: pointer;

  &:focus-visible, &:focus, &:active, &:target, &:hover, &:visited {
    outline: none;
    border: none;
  }
}
</style>
