<template>
  <table class="shops-timetable table table-sm table-responsive caption-top">
    <caption v-if="!hideTitle">{{ $t('schedules.firstDay') }} <strong>{{ firstDeliveryDate | date('dddd Do MMMM YYYY') }}</strong></caption>
    <thead>
      <tr>
        <th>{{ $t('schedules.shop') }}</th>
        <th v-for="date in dates" :key="date" class="col-day">{{ date | date('dd D') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="shop in shops" :key="shop.id">
        <td>{{ shop.name }}</td>
        <td
          v-for="date in dates"
          :key="date"
          :class="{ 'table-success': matrix[date][shop.id], 'table-danger': !matrix[date][shop.id] }"
        >
          &nbsp;
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CartHoraireShops',
  props: {
    partnerId: { type: String },
    hideTitle: { type: Boolean }
  },

  data: () => ({
    loading: false,
    dates: [],
    matrix: {},
    firstDeliveryDate: null
  }),

  computed: {
    cart() {
      return this.$store.getters['cart/cart']
    },
    shops() {
      return this.$store.getters['cart/shops']
    }
  },

  watch: {
    'shops.length': 'loadTimetables'
  },

  mounted() {
    return this.loadTimetables()
  },

  methods: {
    loadTimetables() {
      if (this.loading || !this.cart) {
        return
      }
      this.loading = true
      this.dates = []
      return this.$store.dispatch('shop/getCartOpenings', { cart: this.cart })
        .then(deliveryDates => {
          this.firstDeliveryDate = deliveryDates[0]

          for (let i = 0; i < 7; i += 1) {
            this.dates.push(moment().add(i, 'days').format('YYYY-MM-DD'))
          }
          // we check all shops timetables to see when they are open/close
          this.matrix = this.shops.reduce((acc, shop) => {
            const table = this.$store.getters['shop/timetable'](shop.id)
            const start = shop.getMinOpening(this.dates[0], table, { alone: this.cart.getShops().length === 1 })

            this.dates.forEach(date => {
              const closed = shop.isClosedV2(date, table)
              if (!acc[date]) {
                acc[date] = {}
              }
              acc[date][shop.id] = !closed && start.isSameOrBefore(date)
            })
            return acc
          }, {})
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.shops-timetable {
  font-size: 70%;
  background-color: white;

  .col-day {
    white-space: normal;
  }

  .table-danger {
    background-color: var(--red-color);
    opacity: .3;
  }

  .table-success {
    background-color: var(--green-color);
    opacity: .3;
  }
}
</style>
