/* eslint-disable */
import moment from 'moment'

/**
 * Mixin
 */
var Mixin = function (options) {


    /**
     * Est-ce que l'offre a une promotion à la date donnée.
     *
     * @param {moment} date
     * @returns {boolean}
     */
    this.hasPromotion = function (date) {
        return this.promotion && !moment(this.promotion.start, 'YYYY-MM-DD').isAfter(date, 'day') && !moment(this.promotion.end, 'YYYY-MM-DD').isBefore(date, 'day');
    };

    /**
     * Est-ce que l'offre a une promotion annoncée à la date donnée.
     *
     * @param {moment} date
     * @returns {boolean}
     */
    this.hasAnnouncedPromotion = function (date) {
        return this.promotion && !moment(this.promotion.announcement, 'YYYY-MM-DD').isAfter(date, 'day') && moment(this.promotion.start, 'YYYY-MM-DD').isAfter(date, 'day');
    };

    /**
     * Récupération de la promotion associée à l'offre
     *
     * @returns {Promotion}
     */
    this.getPromotion = function () {
        return this.promotion;
    };

    return this;
};

export default Mixin;
