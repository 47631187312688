/**
 * Mettre à True pour ne pas effectuer les opérations. True va faire
 * du direct
 * @var {Boolean}
 */
const DEBUG = false

/**
 * Nombre de décimales souhaitées
 * @var {Number}
 */
const DECIMALS = 2

export default {

  /**
   * Récupère le facteur de multiplication en fonction des décimales
   *
   * @return {Int} une puissance de 10
   */
  getFactor() {
    // on calcule le facteur à +1 de la décimale, pour être sûr d'avoir
    // les chiffres nécessaires au moment de l'arrondi.
    // ex: 2 décimales: 0.181 * 26.5 = 4.796, arrondi aux 5ct = 4.80
    //
    // avec le +1:
    // - valeurs factorisées: 181 * 26'500
    // - valeur finale: 4.797
    // - arrondi aux 5ct = 4.80
    //
    // sans le +1:
    // - valeurs factorisées: 18 * 2'650
    // - valeur finale: 4.77
    // - arrondi aux 5ct = 4.75
    return Math.pow(10, parseInt(DECIMALS, 10) + 1)
  },

  /**
   * Récupère les 2 nombres factorisés en Int
   *
   * @param {Float} a
   * @param {Float} b
   * @return {Object} a: Int, b: Int, f: Int
   */
  getFactorized(a, b) {
    const f = this.getFactor()
    return {
      f: f,
      a: Math.round(a * f || 0),
      b: Math.round(b * f || 0)
    }
  },

  /**
   * Retourne le résultat avec le bon nombre de décimales
   *
   * @param {Float} a
   * @param {Float} b
   * @param {Function} operator Fn qui reçoit a et b factorisés en Int
   * @return {Float} la valeur avec les décimales
   */
  getResult(a, b, operator) {
    if (DEBUG) {
      return operator(a, b)
    }
    const f = this.getFactorized(a, b)
    const value = operator(f.a, f.b, f.f) || 0
    // comme on a boosté de 1 le facteur (pour gérer les arrondis)
    // on doit le retirer ici pour avoir le bon nombre de décimales
    // dans le résultat final
    const realFactor = f.f / 10

    return Math.round(value / 10) / realFactor
  },

  /**
   * S'assure de la justesse du nombre puis le retourne pour l'affichage
   *
   * @param {Float} value
   * @return {Float} la correctement calculée
   */
  display(value) {
    return this.add(value, 0)
  },

  /**
   * Effectue une addition
   *
   * @param {Float} a
   * @param {Float} b
   * @return {Float} la valeur correctement calculée
   */
  add(a, b) {
    return this.getResult(a, b, (fa, fb) => fa + fb)
  },

  /**
   * Effectue une soustraction
   *
   * @param {Float} a
   * @param {Float} b
   * @return {Float} la valeur correctement calculée
   */
  sub(a, b) {
    return this.getResult(a, b, (fa, fb) => fa - fb)
  },

  /**
   * Effectue une multiplication
   *
   * @param {Float} a
   * @param {Float} b
   * @return {Float} la valeur correctement calculée
   */
  mul(a, b) {
    return this.getResult(a, b, (fa, fb, f) => {
      // comme chaque terme a été factorisé, pour la multiplication
      // on doit le prendre en compte. Because:
      // (2 * 100) * (3 * 100) / 100 !== 6
      return fa * fb / (!DEBUG ? f : 1)
    })
  },

  /**
   * Effectue une division
   *
   * @param {Float} a
   * @param {Float} b
   * @return {Float} la valeur correctement calculée
   */
  div(a, b) {
    return this.getResult(a, b, (fa, fb, f) => {
      // comme chaque terme a été factorisé, pour la division on doit
      // le prendre en compte. Because:
      // (4 * 100) / (2 * 100) / 100 !== 2
      return (fa / fb) * (!DEBUG ? f : 1)
    })
  },

  /**
   * Arrondit un nombre aux 5ct
   *
   * @param {Float} value
   * @return {Float} la valeur correctement arrondie
   */
  round5(value) {
    return this.div(Math.round(this.mul(value, 20)), 20)
  }
}
