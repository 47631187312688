/* eslint-disable */
import lodash from 'lodash'
import moment from 'moment'
import decimal from '@/plugins/decimal'
import Vue from 'vue'

var CartHub = function (props) {
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, CartHub)
};

CartHub.relations = {};

CartHub.mixins = {
    'metadata': {},
    'cartHasShops': {},
    'isHub': {},
    'salepoint': {},
    'cart-salepoint': {}
};

lodash.assign(CartHub.prototype, {

    getTotalPrice: function () {
        return lodash.reduce(this.getUnholdShops(), function (total, shop) {
            return decimal.add(total, shop.getTotalPrice());
        }, 0);
    },

    getTotalItems: function () {
        return lodash.reduce(this.getUnholdShops(), function (total, shop) {
            return decimal.add(total, shop.getTotalItems());
        }, 0);
    },

    minimumReached: function () {
        return lodash.reject(this.getUnholdShops(), function (shop) {
            return shop.minimumReached('hub');
        }).length === 0;
    },

    removeOffer: function (id) {
        this.shops = lodash.compact(lodash.map(this.getShops(), function (shop) {
            return shop.removeOffer(id);
        }));
        if (!this.hasShops()) {
            return null;
        }
        return this;
    },

    /**
     * Surcharge de la méthode du salepoint pour changer la logique de
     * détection du pickup du lendemain
     *
     * @param {Cart} cart nécessaire pour les hubs
     * @return {Boolean}
     */
    deliverPickupTomorrowAtBest: function (cart) {
        if (this.deliverPickupTomorrowAtBestBase()) {
            return true;
        }

        // si le hub lui-même ne livre pas le jour suivant. On va
        // vérifier si l'un des shops a cette restriction
        return lodash.find(cart.getUnholdShops(), function (shop) {
            // pas de risque de boucle infinie, cette méthode n'est
            // redéfinie que par les hubs
            return shop.deliverPickupTomorrowAtBest();
        }) ? true : false;
    },

    deliverPickupTomorrowAtBestForShop: function (shopId, cart) {
        var shop = cart.findSalepoint(shopId);
        return shop && shop.deliverPickupTomorrowAtBest(true);
    },

    /**
     * Vérification si la date de livraison est encore valide
     *
     * @returns {Boolean}
     */
    isDeliveryDateInvalid: function () {
        var date = this.getDeliveryDate();
        if (!date) {
            return false;
        }

        return moment(date).subtract(this.getDeliveryDelay(), 'minutes').isBefore(moment(), 'minute');
    },

    setDeliveryFees: function (mode, amount) {
        var delivery_mode = this.getDeliveryMode(mode);
        if (delivery_mode) {
            delivery_mode.fees = amount;
        }
        return this;
    },

    getDeliveryFees: function (mainDelivery) {
        var delivery_mode = this.getActiveDeliveryMode(),
            fees = delivery_mode.fees || 0;

        if (mainDelivery && ((this.delivery || {}).mode === 'home' || mainDelivery.feeLabel)) {
            fees = mainDelivery.fee;
        }
        return fees;
    },

    getTotalPriceStuff: function () {
        var total = lodash.reduce(this.getUnholdShops(), function (t, shop) {
            return decimal.add(t, shop.getTotalPriceWithFees());
        }, 0);
        return total > 0 ? total : 0;
    },

    getTotalPriceWithFees: function (calculatedTotal, mainDelivery) {
        var total = calculatedTotal || 0;
        if (calculatedTotal === undefined) {
            total = lodash.reduce(this.getUnholdShops(), function (t, shop) {
                return decimal.add(t, shop.getTotalPriceWithFees());
            }, 0);
        }
        total = decimal.add(total, this.getDeliveryFees(mainDelivery));
        return total > 0 ? total : 0;
    },

    getTotalFees: function () {
        return this.getDeliveryFees();
    }

});

export default CartHub;
