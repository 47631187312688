import Vue from 'vue'
import unitprice from './unitprice'
import moment from 'moment'
import i18next from 'i18next'

Vue.filter('packaging', (article) => {
  return unitprice.packaging(article, i18next.t)
})

Vue.filter('price', (price, options = null) => {
  if (!options || !(options instanceof Object)) {
    options = { style: options || 'normal' }
  }
  const format = new Intl.NumberFormat('fr-CH', {
    style: options.style === 'light' ? 'decimal' : 'currency',
    currency: 'CHF',
    minimumFractionDigits: 2
  })
  const result = format.format(price)
  if (options.article && options.article.isWeightPriced()) {
    return `${result}/${options.article.getTarificationUnit()}`
  }
  return result
})

Vue.filter('unitprice', (article, omitPromotion, date, offers) => {
  const result = unitprice.filter(article, omitPromotion, date, offers)
  return result
})

Vue.filter('date', (date, format) => {
  return moment(date).locale(Vue.prototype.$config.lang).format(format)
})
