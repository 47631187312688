import Vue from 'vue'

const Article = Vue.youpaqModel.Article

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async get(context, { id, shopId }) {
      const res = await Vue.prototype.$api.get(`/front/shops/${shopId}/articles/${id}`)
      return new Article(res.data)
    },

    async suggestions(context, { id, shopId, limit }) {
      const res = await Vue.prototype.$api.get(`/front/shops/${shopId}/articles/${id}/suggestions`, {
        params: {
          limit
        }
      })
      return res.data.map(article => new Article(article))
    },

    async searchInShop(context, { search, id, offset, limit }) {
      const res = await Vue.prototype.$api.get('/front/articles/search', {
        params: {
          term: search,
          context: 'articles-shop',
          type: 'shop',
          id: id,
          withResellerOffers: true,
          start: offset,
          limit: limit
        }
      })
      return res.data.map(article => new Article(article))
    }
  }
}
