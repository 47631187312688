import i18next from 'i18next'

export default {

  props: {
    rules: { type: Object, default: () => ({}) }
  },

  data: () => ({
    blurred: false
  }),

  computed: {
    invalid() {
      if (!this.blurred) {
        return false
      }
      if (this.rules.required === false) {
        return i18next.t('rules.required')
      }
      if (this.rules.minLength === false) {
        return i18next.t('rules.minLength')
      }
      if (this.rules.birthdate === false) {
        return i18next.t('rules.birthdate')
      }
      if (this.rules.email === false) {
        return i18next.t('rules.email')
      }
      if (this.rules.sameAsPassword === false) {
        return i18next.t('rules.sameAsPassword')
      }
      if (this.rules.goodPasswordEntropy === false) {
        return i18next.t('rules.goodPasswordEntropy')
      }
      return false
    }
  }
}
