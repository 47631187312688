/* eslint-disable */
import lodash from 'lodash'
import moment from 'moment'

/**
 * Mixin
 */
var Mixin = function (options) {

    options = lodash.merge({
        getJson: {},
        update: {}
    }, options);

    this.getJson = function () {
        var json = lodash.omit(lodash.pick(this, lodash.keys(this)), (options.getJson.omit || []));
        if (options.getJson.merge) {
            json = lodash.merge(json, options.getJson.merge);
        }
        return json;
    };

    /**
     * Update user with some data
     *
     * @param {Object} userdata Some data to merge into this user
     * @param {String} updaterId The user that make the update
     * @param {Boolean} force If true, update the object even if nothing changed.
     * @param {Boolean} mergeArrayFields If true, will merge array fields, otherwise new array will replace old
     */
    this.update = function (userdata, updaterId, force, mergeArrayFields) {
        var mergeCustomizer,
            that = this,
            getSubset = function (patternObj, fullObj) {
                return lodash.reduce(patternObj, function (acc, value, key) {
                    if (fullObj === undefined) {
                        return acc;
                    }

                    if (lodash.isObject(value) && !lodash.isArray(value)) {
                        acc[key] = getSubset(value, fullObj[key]);
                        return acc;
                    }

                    if (fullObj[key] === undefined) {
                        return acc;
                    }

                    acc[key] = fullObj[key];
                    return acc;
                }, {});
            },
            equal_exclude = ['id'];

        if (options.update.equalExclude) {
            equal_exclude = equal_exclude.concat(options.update.equalExclude);
        }

        if (!force && lodash.isEqual(getSubset(lodash.omit(userdata, equal_exclude), this), lodash.omit(userdata, equal_exclude))) {
            return false;
        }

        if (!mergeArrayFields) {
            mergeCustomizer = function (a, b) {
                if (lodash.isArray(a)) {
                    return b;
                }
            };
        } else {
            mergeCustomizer = function (a, b) {
                if (lodash.isArray(a)) {
                    return lodash.uniq(a.concat(b));
                }
            };
        }

        lodash.mergeWith(this, userdata, mergeCustomizer);

        // personalizations
        if (options.update.postUpdate) {
            if (!lodash.isArray(options.update.postUpdate)) {
                options.update.postUpdate = [options.update.postUpdate];
            }
            lodash.each(options.update.postUpdate, function (action) {
                if (!lodash.isFunction(action)) {
                    return;
                }
                action.apply(that);
            });
        }

        this.metadata.updated_by = updaterId;
        this.metadata.updated_at = moment().format();

        return true;
    };

    return this;
};

export default Mixin;
