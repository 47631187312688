/* eslint-disable */
import lodash from 'lodash'

/**
 * Mixin
 *
 */
var Mixin = function () {
    this.getShops = function () {
        return lodash.sortBy(this.shops, 'name');
    };

    this.getUnholdShops = function () {
        return lodash.sortBy(lodash.reject(this.shops, {hold: true}), 'name');
    };

    this.getHoldShops = function () {
        return lodash.sortBy(lodash.filter(this.shops, {hold: true}), 'name');
    };

    this.hasShops = function () {
        return this.shops !== undefined && this.shops.length > 0;
    };

    this.findShop = function (id) {
        return lodash.find(this.getShops(), {id: id});
    };

    this.removeShop = function (id) {
        this.shops = lodash.reject(this.shops, {id: id});
    };

    this.toggleOffers = function () {
        lodash.map(this.getShops(), function (shop) {
            shop.toggleOffers();
        });
    };

    return this;
};

Mixin.relations = {
    shops: 'CartShop'
};

export default Mixin;
