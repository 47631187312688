/* eslint-disable */
import Vue from 'vue'
import lodash from 'lodash'
import moment from 'moment'

var PartnerFilter = function (props) {
  lodash.assign(this, props);
  Vue.youpaqModelLoad(this, PartnerFilter)
};

PartnerFilter.relations = {};

PartnerFilter.mixins = {
  'metadata': {},
  'images': {},
  'updatable': {
    update: {
      postUpdate: function () {
        this.end = moment(moment(this.end).format('YYYY-MM-DD'), 'YYYY-MM-DD').add(1, 'days').subtract(1, 'seconds').format();
      }
    },
    getJson: {
      merge: {type: 'partnerfilter'}
    }
  }
};

lodash.assign(PartnerFilter.prototype, {
  getId: function () {
    return this.id;
  },

  getDescription(lang) {
    if (lodash.isString(this.description)) {
      return this.description;
    }
    return this.description[lang] || this.description.fr;
  },

  getName(lang) {
    if (lodash.isString(this.name)) {
      return this.name;
    }
    return this.name[lang] || this.name.fr;
  },

  getPartner() {
    return this.partner;
  },

  hexColour() {
    return (this.colour || '').indexOf('#') !== -1;
  }
});

export default PartnerFilter;
