export default {
  namespaced: true,

  state: {
    loading: false
  },

  getters: {
    loading: state => state.loading
  },

  mutations: {
    loading(state, data) {
      state.loading = data.loading
    }
  },

  actions: {
  }
}
