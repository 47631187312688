/* eslint-disable */
import units from '@/plugins/units'
import unitprice from '@/plugins/unitprice'

/**
 * Mixin
 *
 */
var Mixin = function (options) {

    this.getPackaging = function () {
        return this.getArticle().packaging;
    };

    /**
     * Unité de conditionnement de l'article
     *
     * @returns {String}
     */
    this.getUnit = function () {
        return this.getArticle().getUnit();
    };

    /**
     * Unité pour la tarification de l'article
     *
     * @returns {String}
     */
    this.getTarificationUnit = function () {
        return this.getArticle().getTarificationUnit();
    };

    /**
     * Prix de base pour l'offre revendeur (peut-être influencé par une éventuelle promotion)
     *
     * @param {Moment} date La date pour laquelle on aimerait connaître le prix
     * @returns {boolean|string|constraints.price|{}|string|*|*}
     */
    this.getRawPrice = function (date) {
        if (this.hasPromotion(date)) {
            return this.getPromotion().getPrice();
        }
        return this.getBaseRawPrice();
    };

    this.getBaseRawPrice = function () {
        // test sur data pour éviter plantage pour produits price = null
        return (this.price || (this.data && this.data.final_price) || 0);
    };

    /**
     * Prix unitaire de l'offre.
     * S'il s'agit d'un produit facturé au poids, mais conditionné en pièces, on calcule
     * le prix moyen d'une pièce.
     *
     * @param {String} [date] La date à laquelle on demande ce prix
     * @return {Number}
     */
    this.getUnitPrice = function (date) {
        return unitprice.cartUnitPrice(this.getArticle(), [this], undefined, false, date);
    };

    /**
     *  Prix total de la ligne, selon la quantité.
     *
     * @param {Number} [quantity] La quantité d'articles
     * @param {Boolean} [omitPromotion]
     * @param {String} [date] La date à laquelle on demande ce prix
     * @return {Number}
     */
    this.getPrice = function (quantity, omitPromotion, date) {
        quantity = quantity || this.quantity;
        return unitprice.getPrice(this.getArticle(), quantity, [this], undefined, date, omitPromotion);
    };

    return this;
};

Mixin.relations = {
    promotion: 'Promotion'
};

export default Mixin;
