<template>
  <div class="login-form">
    <div
      class="login-form-button"
      @click="closeLoginButton"
    />
    <p>
      {{ $t('login.title') }}
    </p>
    <form @submit.prevent.stop="submit">
      <input
        v-model.trim="$v.email.$model"
        type="email"
        name="email"
        :placeholder="$t('login.email')"
      >
      <input
        v-model="$v.password.$model"
        type="password"
        name="password"
        :placeholder="$t('login.password')"
      >
      <div class="pass-lost">
        <a href="#" @click="showPasswordReminder">{{ $t('login.forgot') }}</a>
      </div>
      <div class="signin-submit-buttons">
        <router-link :to="{ name: 'inscription' }">{{ $t('login.signup') }}</router-link>
        <button type="submit" :class="{ disabled: disabled }" :disabled="disabled">
          {{ $t('login.submit') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  props: {
    show: { type: Boolean },
    refreshOnLogin: { type: Boolean }
  },
  data: () => ({
    loading: false,
    email: null,
    password: null
  }),
  computed: {
    disabled() {
      return this.loading || this.$v.$invalid
    },
    preferences() {
      return this.$store.getters['user/preferences']
    }
  },
  watch: {
    loading(v) {
      this.$emit('loading', v)
    },
    show(v) {
      if (v) {
        this.email = this.preferences.email
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods: {
    closeLoginButton(event) {
      this.$emit('closeLoginEvent')
    },
    showPasswordReminder() {
      this.$store.commit('modal/show', {
        component: 'AccountPasswordReminderPopup',
        params: {}
      })
    },
    showSubscribe() {
      this.closeLoginButton()
      this.$store.commit('modal/show', {
        component: 'AccountCreatePopup',
        params: {}
      })
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      return this.$store.dispatch('auth/login', { email: this.email, password: this.password })
        .then(() => {
          this.closeLoginButton()
          this.$store.commit('msg/show', { type: 'info', message: this.$i18n.t('content.side.login.logged') })
          if (this.refreshOnLogin) {
            window.location.reload()
          }
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.login-form {
  background-color: var(--red-color);
  color: white;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 0px 6px #0000008c;
  padding: 30px 40px;
  text-align: left;
  z-index: 100;
}

.login-form-button {
  /* content: ""; */
  position: absolute;
  background-image: url("~@/assets/login-form-tab.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 150px;
  top: -58px;
  right: 0;
  cursor: pointer;
}

p {
  margin: 5px 0;
}

input {
  width: 250px;
  height: 40px;
  border-color: #00000052;
  box-sizing: border-box;
  border: none;
  box-shadow: inset 1px 1px 3px #0000009a;
  margin-top: 25px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 400;
  font-family: inherit;
  padding: 20px;
}

input:focus-visible {
  outline: none;
}

.pass-lost {
  a {
    font-size: 11px;
    font-weight: 300;
    color: white;
    text-decoration: none;
    padding-left: 5px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.signin-submit-buttons {
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signin-submit-buttons a {
  color: white;
  text-decoration: none;
  font-size: 15px;
  font-weight: 300;
}

.signin-submit-buttons a:hover {
  text-decoration: underline;
}

.signin-submit-buttons button {
  background-color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  color: var(--red-color);
  font-size: 16px;
  font-weight: 700;
  box-shadow: 1px 1px 5px #00000087;
  cursor: pointer;
}

.signin-submit-buttons button:hover {
  color: salmon;
  box-shadow: 1px 1px 0px #00000087;
}

.pass-recuperer-pp:not(.active) {
  display: none;
}
.pass-recuperer-pp.active {
  display: flex;
}
</style>
