import lodash from 'lodash'

const defaults = {
  info: {
    timeout: 2000,
    color: 'success'
  },
  error: {
    timeout: 8000,
    color: 'danger'
  },
  warn: {
    timeout: 8000,
    color: 'warning'
  }
}

export default {
  namespaced: true,

  state: {
    id: 0,
    messages: [],
    interval: null
  },

  getters: {
    messages: state => state.messages
  },

  mutations: {
    show(state, data) {
      state.id += 1
      if (state.id > 10000) {
        state.id = 1
      }
      if (!data.type || !defaults[data.type]) {
        data.type = 'info'
      }
      state.messages.push({
        ...defaults[data.type],
        ...data,
        id: state.id,
        date: new Date(),
        show: true
      })
      lodash.remove(state.messages, m => !m.show)
    },

    clear(state) {
      const now = Date.now()
      state.messages = state.messages.filter(msg => now - msg.timeout <= msg.date.getTime())
    }
  },

  actions: {
    start({ state, commit }) {
      state.interval = window.setInterval(() => {
        commit('clear')
      }, 1000)
    },

    stop({ state }) {
      window.clearInterval(state.interval)
    }
  }
}
