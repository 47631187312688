<template>
  <header class="top-header">
    <div class="header-left">
      <div class="header-menu">
        <img
          src="@/assets/burger-icon.png"
          class="icon"
          alt="Icone menu"
          @click="openMenu = true, openLogin = false, openCart = false, openMenuVille = false, openUserMenu = false"
        >
      </div>
      <div class="header-logo">
        <router-link :to="{ name: partnerId ? 'partner' : 'home' }">
          <img
            src="@/assets/youpaq-logo.svg"
            width="100"
            alt="Youpag logo"
          >
        </router-link>
      </div>
      <div
        class="header-contact d-none d-lg-flex"
        :class="{ 'd-lg-none' : isNestleHome}"
      >
        <img
          src="@/assets/telephone-icon.png"
          class="icon"
          alt="Icone téléphone"
        >
        <span>{{ $t('content.header.contactUs') }}<br>info@youpaq.com</span>
      </div>
      <div
        id="menu-connecte"
        :class="{ active: openMenu }"
      >
        <TopMainMenuConnecte :partner-id="currentPartner && currentPartner.id" @closeMenuEvent="openMenu = false" />
      </div>
    </div>
    <div class="header-right">
      <div
        class="header-ville"
        :class=" { active: ville, 'd-none' : isNestleHome }"
      >
        <img
          src="@/assets/ville-icon.png"
          alt="Icone ville"
          class="icon"
          @click="openMenuVille = true, openMenu = false, openLogin = false, openCart = false, openUserMenu = false"
        >
        <div
          class="header-ville-content"
          @click="openMenuVille = true, openMenu = false, openLogin = false, openCart = false, openUserMenu = false"
        >
          <span class="header-ville-label d-none d-md-flex">{{ currentPartner && currentPartner.name }}</span>
          <span class="header-ville-switch d-none d-md-flex">{{ $t('content.header.changeCity') }}</span>
        </div>
        <div
          class="menu-ville"
          :class="{ active: openMenuVille }"
        >
          <TopMainMenuVille @closeMenuEvent="openMenuVille = false" />
        </div>
      </div>

      <div class="d-none">
        <CartPopin :partner-id="currentPartner && currentPartner.id" />
      </div>
      <div class="header-actions">
        <div v-if="partnerId && !hideCart" class="header-cart">
          <a href="#">
            <img
              src="@/assets/minicart-icon.png"
              class="icon"
              alt="Icone panier"
              @click="openCart = true, openMenu = false, openLogin = false, openMenuVille = false, openUserMenu = false"
            />
            <span v-show="cartOffers > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{ cartOffers }}</span>
          </a>
          <div
            class="cart"
            :class="{ active: openCart }"
          >
            <CartPopin :partner-id="currentPartner && currentPartner.id" @closeCartEvent="openCart = false" />
          </div>
        </div>
        <div
          v-if="partnerId"
          class="header-favoris d-flex"
          :class="{ active: favoris }"
        >
          <router-link :to="{ name: 'favourite', params: { partnerId } }">
            <img
              src="@/assets/favoris-icon.png"
              class="icon"
              alt="Icone favoris"
            />
          </router-link>
        </div>
        <div
          class="header-compte"
          :class=" { active: connected }"
        >
          <div class="language" :class="{ 'd-none' : !isNestleHome}">
            <LangSelector color="gray" />
          </div>
          <div class="compte-icon">
            <img
              v-if="!connected"
              src="@/assets/compte-icon.png"
              class="icon"
              alt="Icone compte"
              @click="openLogin = true, openMenu = false, openCart = false, openMenuVille = false, openUserMenu = false"
            />
            <img
              v-else
              src="@/assets/compte-icon.png"
              class="icon"
              alt="Icone compte"
              @click="openUserMenu = true, openLogin = false, openMenu = false, openCart = false, openMenuVille = false"
            />
            <span v-if="user" class="name">{{ user.firstname }}<br />{{ user.lastname }}</span>
          </div>
          <div
            class="login"
            :class="{ active: openLogin }"
          >
            <Login :show="openLogin" @closeLoginEvent="openLogin = false" />
          </div>
          <div
            class="user-menu"
            :class="{ active: openUserMenu }"
          >
            <TopUserMenu @closeUserMenuEvent="openUserMenu = false" />
          </div>
        </div>
        <div
          class="header-logout d-none"
          :class=" { active: connected }"
        >
          <a href="#" @click="doLogout">
            <img
              src="@/assets/logout-icon.png"
              class="icon"
              alt="Icone logout"
            >
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TopMainMenuConnecte from '@/components/MenusAndPopin/TopMainMenuConnecte.vue'
import TopMainMenuVille from '@/components/MenusAndPopin/TopMainMenuVille.vue'
import TopUserMenu from '@/components/MenusAndPopin/TopUserMenu.vue'
import Login from '@/components/MenusAndPopin/Login.vue'
import CartPopin from '@/components/MenusAndPopin/CartPopin.vue'
import LangSelector from '@/components/LangSelector'

export default {
  name: 'Header',
  components: {
    TopMainMenuConnecte,
    TopMainMenuVille,
    TopUserMenu,
    Login,
    CartPopin,
    LangSelector
  },
  props: {
    partnerId: {
      type: String
    },
    ville: {
      type: Boolean,
      default: true
    },
    favoris: {
      type: Boolean,
      default: true
    },
    login: {
      type: Boolean,
      default: false
    },
    logout: {
      type: Boolean,
      default: false
    },
    cart: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openMenu: false,
      openLogin: false,
      openCart: false,
      openMenuVille: false,
      openUserMenu: false
    }
  },
  computed: {
    currentPartner() {
      return this.$store.getters['partner/current']
    },
    cartOffers() {
      return this.$store.getters['cart/offers']().length
    },
    connected() {
      return this.$store.getters['auth/connected']
    },
    user() {
      return this.$store.getters['auth/user']
    },
    hideCart() {
      return this.$route.meta.hideCart
    },
    isNestleHome() {
      return this.$route.name === 'homenestle'
    }
  },
  watch: {
    $route() {
      this.openLogin = false
      this.openCart = false
      this.openMenu = false
      this.openMenuVille = false
      this.openUserMenu = false
    }
  },
  methods: {
    doLogout() {
      return this.$store.dispatch('auth/logout')
        .catch(err => this.$err(err))
        .finally(() => {
          if (!this.$route.params.partnerId) {
            // no redirection if not inside a city
            return
          }
          this.$router.push({ name: 'home' })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

header {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding: 15px 10px 10px 10px;
  background: #ffffff;

  @include media-breakpoint-up(lg) {
    padding: 15px 10px 10px 100px;
  }
}

header > div {
  display: flex;
  align-items: center;
}

header > div > div {
  display: flex;
}

  .top-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 0px 5px 0px #0000005e;

    img.icon {
      max-height: 30px;
    }

    .header-left {
      position: relative;
      max-width: 145px;

      @include media-breakpoint-up(lg) {
        max-width: 100%;
      }

      .header-logo {
        margin-right: 10px;
        width: 120px;

        img {
          max-height: 32px;

          @include media-breakpoint-up(md) {
            max-height: 43px;
          }
        }
      }

      .header-menu {
        margin-left: 10px;

        img {
          max-width: 80px;
          cursor: pointer;
          margin-right: 10px;
        }
      }
      .header-contact {
        display: none;
        align-items: center;
        font-size: 16px;

        @include media-breakpoint-up(lg) {
          display: flex;
        }

        img {
          margin-right: 10px;
        }

        span {
          text-align: left;
          font-size: 15px;
        }
      }
    }

    .header-right {
      .header-ville {
        display: flex;
        align-items: center;
        margin-right: 40px;
        position: relative;

        @include media-breakpoint-up(md) {
          margin-right: 50px
        }

        img {
          @include media-breakpoint-up(md) {
            display: flex;
            margin-right: 10px;
            max-width: 25px;
            cursor: pointer;
          }
        }

        span {
          display: block;
        }

        .header-ville-label {
          font-size: 17px;
          cursor: pointer;
          @include media-breakpoint-up(md) {
            font-size: 20px;
          }
        }

        .header-ville-switch {
          display: none;

          @include media-breakpoint-up(md) {
            display: flex;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      .header-actions {
        display: flex;
        align-items: center;

        .header-favoris {
          display: none;

          @include media-breakpoint-up(md) {
            display: flex;
          }

          img {
            cursor: pointer;
          }
        }
        .header-cart {
          position: relative;

          .cart {
            position: absolute;
            top: 42px;
            right: -111px;

            @include media-breakpoint-up(md) {
              right: -35px;
            }
          }
        }

        .header-compte {
          position: relative;

          .compte-icon {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--gray-color);
            color: #e45e43;
            font-size: 12px;

            img {
              cursor: pointer;
            }
          }

          img {
            margin-right: 10px;
          }

          span {
            display: none;

            @include media-breakpoint-up(md) {
              display: flex;
              text-align: left;
            }
          }
          .login {
            position: absolute;
            top: 42px;
            right: -12px;

            @include media-breakpoint-up(lg) {
              right: -20px;
            }
          }
        }
        .header-logout {
          margin-left: 0px !important;
        }
      }
    }
  }

  .header-ville:not(.active) {
    display: none !important;
  }

  .header-favoris:not(.active) {
    display: none !important;
  }

  .header-compte:not(.active) {
    span.name {
      display: none !important;
    }
  }

  .header-logout:not(.active) {
    display: none !important;
  }

  #menu-connecte:not(.active) {
    display: none;
  }

  .menu-ville:not(.active) {
    display: none;
  }

  .header-cart .cart:not(.active) {
    display: none;
  }

  .header-compte .login:not(.active) {
    display: none;
  }

  .header-compte .user-menu:not(.active) {
    display: none;
  }

  .header-actions > div {
    margin: 0 10px;
    display: flex;

    @include media-breakpoint-up(lg) {
      margin: 0 20px;
    }
  }
  .language {
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
  .lang-selector {
    color: var(--grey-color);
  }
</style>
