<template>
  <div v-if="offerLocal" class="product-item">
    <div class="row">
      <div v-if="offerLocal" class="col-5 d-flex">
        <div class="product-name">
          <span v-if="offerLocal.article.brand">{{ offerLocal.article.brand }} </span>{{ offerLocal.article.getTitle ? offerLocal.article.getTitle($l) : offerLocal.article.title.fr }}
          <template v-if="!priceOnly">
            <br />
            <span v-if="!offerLocal.getArticle().isRawPackaged()" class="info">{{ offerLocal.article | packaging }}</span>
            <span v-if="offerLocal.portions" class="info">
              {{ $t('cart.hint.portions', { count: offerLocal.portions, quantity: offerLocal.selectedQuantity, unit: offerLocal.getUnit() }) }}
            </span>
          </template>
        </div>
      </div>
      <div v-if="!priceOnly" class="col-3">
        <div class="product-qty">
          <ProduitQuantityEditor
            v-if="offerLocal"
            ref="quantityEditor"
            edition
            light
            :partner-id="partnerId"
            :shop-id="shopId"
            :offer="offerLocal"
            :article="offerLocal.getArticle()"
          />
        </div>
      </div>
      <div class="amount col-3 d-flex justify-content-end" :class="{ 'col-6': priceOnly }">
        <template v-if="offerPriceText">{{ offerPriceText }}</template>
        <template v-else>{{ offerPrice | price('light') }}</template>
      </div>
      <div v-if="!priceOnly" class="remove col-1 p-0 d-flex justify-content-end align-items-start" @click="remove">
        <img src="@/assets/cart-remove-icon.svg">
      </div>
    </div>
  </div>
</template>

<script>
import ProduitQuantityEditor from '@/components/Produits/ProduitQuantityEditor'

export default {
  name: 'CartPopinLine',
  components: { ProduitQuantityEditor },
  props: {
    shopId: { type: String },
    offer: { type: Object },
    offerPriceText: { type: String },
    priceOnly: { type: Boolean }
  },

  data: () => ({
    loading: false,
    edit: false,
    valid: false,
    offerLocal: null
  }),

  computed: {
    partnerId() {
      return this.$route.params.partnerId
    },

    offerPrice() {
      return this.priceOnly ? this.offerLocal.price : this.offerLocal.getPrice()
    }
  },

  watch: {
    'offer.quantity'() {
      this.init()
      this.$refs.quantityEditor.init()
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      if (this.priceOnly) {
        this.offerLocal = this.offer
        return
      }
      this.offerLocal = this.offer.clone()
    },

    update() {
      this.loading = true
      return this.$refs.adder.submit(this.shopId, this.offerLocal.id)
        .then(() => (this.edit = false))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    remove() {
      this.loading = true
      return this.$store.dispatch('cart/removeOffer', { offerId: this.offerLocal.id, shopId: this.shopId })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.product-item {
  font-size: 14px;
  padding-bottom: 10px;

  .product-qty, .product-name .info {
    font-weight: 300;
    white-space: nowrap;
  }
  .product-qty {
    min-width: 42px;
    cursor: pointer;
  }
  .remove img {
    max-width: 20px;
    cursor: pointer;
  }

  .product-edit {
    width: 100%;
    background-color: white;
    padding: 10px;
    color: black;
  }
}
</style>
