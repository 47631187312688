export default [
  // liens envoyés pour ancienne comm
  {
    path: '/fr/city/:partnerId',
    redirect: to => ({
      name: 'partner',
      params: { partnerId: to.params.partnerId }
    })
  },
  {
    path: '/fr/cities/:partnerId',
    redirect: to => ({
      name: 'partner',
      params: { partnerId: to.params.partnerId }
    })
  },
  {
    path: '/fr/partners/:partnerId',
    redirect: to => ({
      name: 'partner',
      params: { partnerId: to.params.partnerId }
    })
  },
  // liens depuis l'ancien wordpress
  {
    path: '/fr/:partnerId',
    redirect: to => ({
      name: 'partner',
      params: { partnerId: to.params.partnerId }
    })
  }
]
