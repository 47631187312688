/* eslint no-console: off */

import Vue from 'vue'
import moment from 'moment'

const Article = Vue.youpaqModel.Article
const Shop = Vue.youpaqModel.Shop
const PromotionCampaign = Vue.youpaqModel.PromotionCampaign

const isClosed = (state, { id, date }) => {
  const table = state.timetable[id]
  if (!table) {
    return true
  }
  const checkDate = moment(date).locale('en')
  const todayName = checkDate.format('dddd').toLowerCase()
  console.group(`${id}: check close status for ${checkDate.format('YYYY-MM-DD')} (${todayName})`)

  // on vérifie que le shop n'est pas fermé, selon les jours spéciaux
  const period = !!(table.closed.periods || []).find(p => checkDate.isBetween(p.start, p.end, 'day', '[]'))
  if (period) {
    console.log('close period found')
    console.groupEnd()
    return true
  }
  const special = !!(table.closed.specialdays || []).find(sd => checkDate.isSame(sd.date, 'day') && sd.hours.length === 0)
  if (special) {
    console.log('close special found')
    console.groupEnd()
    return true
  }

  // check si les horaires du jour sont ok
  const day = table.timetable.find(t => t.name === todayName && t.hours.length)
  if (!day) {
    console.log('no timetable for this day')
    console.groupEnd()
    return true
  }
  console.log('is open')
  console.groupEnd()
  return false
}

const getMinOpening = (state, { id, date, alone }) => {
  const table = state.timetable[id]
  if (!table) {
    console.log('no timetable found')
    return false
  }

  const closed = isClosed(state, { id, date })
  const checkDate = moment(date)
  const start = checkDate.clone().hour(0).minute(0)
  const pivot1 = moment(`${checkDate.format('YYYY-MM-DD')} ${table.pivot1}`, 'YYYY-MM-DD HH:mm')
  const pivot2 = moment(`${checkDate.format('YYYY-MM-DD')} ${table.pivot2}`, 'YYYY-MM-DD HH:mm')
  const jp1 = (!alone || !table.jp0IfAlone) && table.jp1
  const jp2 = (!alone || !table.jp0IfAlone) && table.jp2

  console.log('alone', alone, 'and jp0', table.jp0IfAlone, 'so jp1=', jp1)

  // check maintenant si J+1 ou J+2. Entièrement basé sur le doc PDF du drive, projet Youpaq
  if (!closed) {
    if (jp2) {
      if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
        console.log('J+2, open, left')
        return checkDate.add(1, 'day')
      }
      console.log('J+2, open, middle/right')
      return checkDate.add(2, 'day')
    }
    if (jp1) {
      if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
        console.log('J+1, open, left')
        return checkDate.add(1, 'day')
      }
      if (checkDate.isBetween(pivot1, pivot2, 'minute', '(]')) {
        console.log('J+1, open, middle')
        return checkDate.add(1, 'day')
      }
      console.log('J+1, open, right')
      return checkDate.add(2, 'day')
    }
    // if jp0
    if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
      console.log('J+0, open, left')
      return checkDate
    }
    console.log('J+0, open, middle/right')
    return checkDate.add(1, 'day')
  } else {
    if (jp2) {
      console.log('J+2, closed, left/middle/right')
      return checkDate.add(2, 'day')
    }
    if (jp1) {
      if (checkDate.isBetween(start, pivot1, 'minute', '[]')) {
        console.log('J+1, closed, left')
        return checkDate.add(1, 'day')
      }
      if (checkDate.isBetween(pivot1, pivot2, 'minute', '(]')) {
        console.log('J+1, closed, middle')
        return checkDate.add(1, 'day')
      }
      console.log('J+1, closed, right')
      return checkDate.add(2, 'day')
    }
    // if jp0
    console.log('J+0, closed, left/middle/right')
    return checkDate.add(1, 'day')
  }
}

export default {
  namespaced: true,

  state: {
    current: null,
    banners: {},
    timetable: {},
    tags: {},
    categories: {},
    promotionsCampaigns: {}
  },

  getters: {
    current: state => state.current,
    timetable: state => shopId => shopId ? (state.timetable[shopId] || []) : state.timetable,
    tags: state => shopId => shopId ? (state.tags[shopId] || []) : state.tags,
    categories: state => shopId => shopId ? (state.categories[shopId] || []) : state.categories,
    promotionsCampaigns: state => shopId => shopId ? (state.promotionsCampaigns[shopId] || []) : state.promotionsCampaigns,
    banners: state => shopId => state.banners[shopId] || []
  },

  mutations: {
    reset(state) {
      state.timetable = {}
      state.tags = {}
      state.banners = {}
      state.categories = {}
      state.promotionsCampaigns = {}
    }
  },

  actions: {
    async get({ state }, { id }) {
      if (state.current && state.current.id === id) {
        return state.current
      }
      const res = await Vue.prototype.$api.get(`/front/shops/${id}`)

      state.current = new Shop(res.data)

      return state.current
    },

    async getBySlug({ state, dispatch }, { slug }) {
      if (state.current && state.current.slug === slug) {
        return state.current
      }
      const res = await Vue.prototype.$api.get(`/front/shops-slug/${slug}`)
        .catch(err => {
          if (err?.response.status === 404) {
            // try to fetch by id
            return Vue.prototype.$api.get(`/front/shops/${slug}`)
          }
          throw err
        })

      state.current = new Shop(res.data)

      return state.current
    },

    async products(context, { id, offset, limit, category, tags, campaign }) {
      if (!campaign) {
        const res = await Vue.prototype.$api.get(`/front/shops/${id}/articles`, {
          params: {
            start: offset,
            limit: limit,
            category,
            tags
          }
        })
        return res.data.map(article => new Article(article))
      }
      return this.dispatch('promo/getCampaignArticles', {
        promotionCampaignId: campaign,
        offset,
        limit
      })
    },

    async tags({ state }, { id }) {
      if (state.tags[id]) {
        return state.tags[id]
      }
      const res = await Vue.prototype.$api.get(`/front/shops/${id}/tagscount`, {
        params: {
          categoryId: null,
          tags: null,
          id: id,
          context: 'shop'
        }
      })
      state.tags[id] = res.data
      return res.data
    },

    async categories({ state }, { id }) {
      if (state.categories[id]) {
        return state.categories[id]
      }
      const res = await Vue.prototype.$api.get(`/front/shops/${id}/articlecategories`)
      state.categories[id] = res.data
      return res.data
    },

    async promotionsCampaigns({ state }, { id }) {
      if (state.promotionsCampaigns[id]) {
        return state.promotionsCampaigns[id]
      }
      const res = await Vue.prototype.$api.get(`/front/shops/${id}/promotioncampaigns`)
      state.promotionsCampaigns[id] = res.data.map(campaign => new PromotionCampaign(campaign))
      return state.promotionsCampaigns[id]
    },

    async listForReseller(context, { resellerId, partnerId }) {
      const res = await Vue.prototype.$api.get(`/front/resellers/${resellerId}/shops`, {
        params: {
          pid: partnerId
        }
      })
      return res.data
    },

    async getOpenings({ state }, { id, partnerId }) {
      if (state.timetable[id]) {
        return state.timetable[id]
      }
      const res = await Vue.prototype.$api.get(`/front/shops/${id}/timetable`, {
        params: {
          partnerId
        }
      })
      state.timetable[id] = res.data
      return state.timetable[id]
    },

    async getCartOpenings({ state, dispatch }, { cart, hub }) {
      const shops = cart.shops || []
      await Promise.all(shops.map(shop => dispatch('getOpenings', { id: shop.id, partnerId: cart.partner_id })))

      const hubTimetable = hub && await this.dispatch('hub/getOpenings', { id: hub.id })
      const hubState = {
        disabled: !hub,
        timetable: hub && {
          [hub.id]: hubTimetable
        }
      }

      console.group('Timetables')

      // on parcourt les shops pour trouver le 1er jour ouvrable le plus lointain
      let date = moment()
      console.group('J+0/1/2')
      shops.forEach(shop => {
        const next = getMinOpening(state, { id: shop.id, date: moment(), alone: shops.length === 1 })
        if (next && next.isAfter(date)) {
          date = next
        }
        console.log(shop.id, date.format('YYYY-MM-DD'))
      })
      console.log(`first pivot found for cart: ${date.format('YYYY-MM-DD')}`)
      console.groupEnd()

      // on s'assure que tous les shops sont ouverts en même temps
      const dates = []
      const limit = date.clone().add(7, 'day')

      console.group('Open days')
      while (date.isSameOrBefore(limit, 'day')) {
        const closed = shops.find(shop => isClosed(state, { id: shop.id, date: date }))
        const hubClosed = hubState.disabled ? false : isClosed(hubState, { id: hub.id, date: date })
        if (!closed && !hubClosed) {
          dates.push(date.clone())
          console.log(`${date.format('YYYY-MM-DD')} has opened SHOPs`)
        } else {
          console.log(`${date.format('YYYY-MM-DD')} has closed SHOPs (${closed}) or closed HUB (${hubClosed})`)
        }
        date.add(1, 'day')
      }
      console.groupEnd()

      console.groupEnd()
      return dates
    },

    async banners({ state }, { shopId }) {
      if (state.banners[shopId]?.length) {
        return state.banners[shopId]
      }
      const res = await Vue.prototype.$api.get(`/front/shops/${shopId}/banners`)
      state.banners[shopId] = res.data
      return state.banners[shopId]
    }
  }
}
