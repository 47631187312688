export default {
  namespaced: true,

  state: {
    component: null,
    params: {},
    doneSwitch: false,
    doneParams: {}
  },

  getters: {
    component: state => state.component,
    params: state => state.params,
    doneSwitch: state => state.doneSwitch,
    doneParams: state => state.doneParams
  },

  mutations: {
    show(state, { component, params }) {
      state.component = component
      state.params = params
    },

    hide(state) {
      state.component = null
      state.params = {}
    },

    done(state, data) {
      state.doneSwitch = !state.doneSwitch
      state.doneParams = data
    }
  },

  actions: {
  }
}
