<template>
  <img class="clock-btn" src="@/assets/clock-white.svg" @click="show">
</template>

<script>

export default {
  name: 'CartHoraireShopsPopupButton',
  props: {
    partnerId: { type: String }
  },
  methods: {
    show() {
      this.$store.commit('modal/show', {
        component: 'CartHoraireShopsPopup',
        params: {
          partnerId: this.partnerId
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';
.cart-popin .clock-btn {
  width: 40px;
  cursor: pointer;
}
</style>
