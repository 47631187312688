/* eslint-disable */
import lodash from 'lodash'
import Vue from 'vue'

var Profile = function (props) {
    lodash.assign(this, {
        importationAdapters: {
            promotions: 'youpaq',
            articles: 'youpaq'
        }
    }, props);
    Vue.youpaqModelLoad(this, Profile)
};

Profile.relations = {
    'maincontact': 'User'
};

Profile.mixins = {
    'metadata': {},
    'updatable': {
        getJson: {
            merge: {type: 'profile'}
        }
    }
};

lodash.assign(Profile.prototype, {

    getId: function () {
        return this.id;
    },

    setId: function (id) {
        this.id = id;
    },

    getEanCode: function () {
        return this.eanSupplierCode;
    },

    setEanCode: function (code) {
        this.eanSupplierCode = code;
    },

    getDisplayName: function () {
        return this.company;
    },

    getDeliveryModes: function () {
        return this.deliveryModes;
    },

    getHubsDeliveryModes: function () {
        return this.hubsDeliveryModes;
    },

    getMainContact: function () {
        return this.maincontact;
    },

    getImportAdapter: function (module) {
        return this.importationAdapters[module];
    },

    getArticlesImportAdapter: function () {
        return this.importationAdapters.articles;
    }
});

export default Profile;
