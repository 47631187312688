/* eslint-disable */
import lodash from 'lodash'
import decimal from '@/plugins/decimal'
import moment from 'moment'

/**
 * Mixin
 *
 */
var Mixin = function (options) {

    this.getOffers = function () {
        return this.offers;
    };

    this.hasOffers = function () {
        return this.offers !== undefined && this.offers.length > 0;
    };

    this.findOffer = function (id) {
        return lodash.find(this.getOffers(), {id: id});
    };

    this.toggleOffers = function () {
        this.expanded = !this.expanded;
    };

    this.addOffer = function (data) {
        throw new Error('not implemented')
    };

    this.updateOffer = function (offer) {
        var existing_offer = this.findOffer(offer.id);

        if (offer.selectedQuantity && offer.portions) {
            // on est en mode "par unité", donc on calcule la qte
            offer.quantity = decimal.mul(offer.portions, offer.selectedQuantity);
            existing_offer.portions = offer.portions;
            existing_offer.selectedQuantity = offer.selectedQuantity;
        }
        existing_offer.setQuantity(offer.quantity);
    };

    this.removeOffer = function (offerId) {
        this.offers = lodash.reject(this.offers, {id: offerId});
        if (!this.hasOffers()) {
            return null;
        }
        return this;
    };

    this.hasPackagingPacketOffer = function (packet) {
        return lodash.find(this.getOffers(), function (offer) {
            return offer.article && offer.article.packaging.packet === packet;
        })
    };

    this.getTotalPrice = function () {
        var that = this;
        return lodash.reduce(this.getOffers(), function (total, offer) {
            return decimal.add(total, offer.getPrice(undefined, that.getPromotionsPriceDate()));
        }, 0);
    };

    this.getPromotionsPriceDate = function () {
        // pour que ça marche coté serveur et client
        return this.getDeliveryDate();
    };

    this.getTotalItems = function () {
        return lodash.reduce(this.getOffers(), function (total, offer) {
            var current_quantity = offer.getArticle().isRawPackaged() ? 1 : offer.quantity;
            return decimal.add(total, current_quantity);
        }, 0);
    };

    return this;
};

Mixin.relations = {
    offers: 'CartOffer'
};

export default Mixin;
