<template>
  <div id="cat-commercant-slider">
    <carousel-3d
      v-if="shops.length"
      :disable3d="true"
      :clickable="false"
      :controls-visible="true"
      :height="600"
      :display="3"
      :space="330"
    >
      <template v-for="(shop, index) in shops">
        <slide :key="shop.id" :index="index">
          <div class="slider-item">
            <router-link :to="{ name: 'shop', params: { shopSlug: shop.slug } }">
              <img :src="$resize(shop.imageUrl, { w: 300, h: 200, f: 'cover' })">
            </router-link>
            <router-link :to="{ name: 'shop', params: { shopSlug: shop.slug } }">
              <h3>{{ shop.name }}</h3>
            </router-link>
            <p><span v-marked="shop.getDescriptionShort($l)"></span></p>
            <div
              class="ajouter-favoris"
              :class="{ activeFavoris: favorisActive }"
            >
              <button @click="toggleFavourite">
                <template v-if="!favorisActive">
                  <img src="@/assets/favoris-icon-white-empty.svg" />
                  <span>{{ $t('shop.addFavourite') }}</span>
                </template>
                <template v-else>
                  <img src="@/assets/favoris-icon-white-full.svg" />
                  <span>{{ $t('shop.removeFavourite') }}</span>
                </template>
              </button>
            </div>
          </div>
        </slide>
      </template>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  name: 'CategorieCommercantSlider',
  components: {
    Carousel3d,
    Slide
  },
  props: {
    categoryId: { type: String },
    favourite: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    favorisActive: {
      get() {
        return this.favourite
      },
      set(v) {
        this.$emit('update:favourite', v)
      }
    },

    categories() {
      return this.$store.getters['partner/categories']
    },

    shops() {
      const shops = this.$store.getters['partner/shops']

      if (this.categoryId) {
        const category = this.categories.find(category => category.code === this.categoryId)
        if (!category) {
          return []
        }
        return category.shopIds
          .map(shopId => shops.find(s => s.id === shopId))
          .filter(shop => !!shop)
      }
      return shops
    }
  },
  methods: {
    toggleFavourite() {
      if (this.loading) {
        return
      }
      this.loading = true
      return this.$store.dispatch('user/shopFavouriteToggle', { shopId: this.shopId })
        .then(active => {
          this.favorisActive = active
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('modal/show', {
              component: 'LoginPopup',
              params: {
                returnPath: `${this.$route.path}#shops`
              }
            })
            return
          }
          this.$err(err)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* Overwritten Carousel 3d style*/
#cat-commercant-slider .carousel-3d-container {
  height: 650px !important;
}

#cat-commercant-slider .carousel-3d-slide {
  border: none !important;
  background: transparent !important;
  overflow: visible !important;
  width: 300px !important;
  height: 650px !important;

  @include media-breakpoint-down(md) {
    width: 300px !important;
    height: 650px !important;
  }
}

#cat-commercant-slider .carousel-3d-slide:not(.current) {
  @include media-breakpoint-down(lg) {
    opacity:abs($number: 0.1) !important;
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}

::v-deep .prev,
::v-deep .next {
  color: transparent !important;
  width: 70px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
}
::v-deep .prev {
  background: url("~@/assets/slider-prev-yellow.svg") no-repeat;
  left: 5px !important;
  @include media-breakpoint-up(md) {
    left: calc(50vw - 280px) !important;
  }
  @include media-breakpoint-up(lg) {
    left: calc(15% - 150px) !important;
  }
}

::v-deep .next {
  background: url("~@/assets/slider-next-yellow.svg") no-repeat;
  background-position-x: right;
  right: 5px !important;
  @include media-breakpoint-up(md) {
    right: calc(50vw - 280px) !important;
  }
  @include media-breakpoint-up(lg) {
    right: calc(15% - 150px) !important;
  }
}

#cat-commercant-slider {
  background-color: white;
  z-index: 10;
}

.slider-item {
  position: relative;
  background-color: var(--gray-color);
  color: white;
  box-shadow: 2px 3px 6px #0000005e;
  min-height: 600px;
  min-width: 300px;
}
.slider-item a {
  color: white;
  text-decoration: none;
}
.slider-item img {
  max-width: 100%;
}
.slider-item h3 {
  padding: 0 30px;
  text-align: left;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  margin: 30px 0 5px 0;
}
.slider-item p {
  padding: 0 30px;
  text-align: left;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5rem;
}
.ajouter-favoris {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding-right: 25px;

  button {
    padding-left: 45px;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;

    img {
      width: 50px;
      @include media-breakpoint-up(md) {
        width: 30px;
      }
    }
    span {
      color: white;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
      padding-left: 15px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }
}
.ajouter-favoris:not(.activeFavoris) {
  button.favoris-full {
    display: none;
  }
  button.favoris-empty {
    display: flex;
    align-items: center;
  }
}
.ajouter-favoris.activeFavoris {
  button.favoris-full {
    display: flex;
    align-items: center;
  }
  button.favoris-empty {
    display: none;
  }
}
</style>
