<template>
  <div ref="modal" class="modal fade" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="type !== 'home'" class="modal-title">{{ $t('hubDelivery.pickup') }}</h5>
          <h5 v-else class="modal-title">{{ $t('hubDelivery.home') }} <sup>*</sup></h5>
          <button type="button" class="btn-close" aria-label="Fermer" @click="close()"></button>
        </div>

        <div class="modal-body">
          <h5>{{ hub.name }}</h5>
          <p>{{ $t('hubDelivery.date') }}</p>
          <table class="table table-hover table-cursor">
            <tbody>
              <tr v-for="(date, index) in dates" :key="index" @click="submit(date)">
                <td>{{ date | date('dddd LL') }}</td>
                <td v-if="type !== 'home'">{{ getHours(date) }}</td>
                <td v-else>
                  <div v-for="(s, i) in getSlicedHours(date)" :key="i" class="form-check">
                    <input
                      :id="`slice-${index}-${i}`"
                      class="form-check-input"
                      type="radio"
                      name="slice"
                      :value="s.value"
                      @click="selectSlice(date, s.value)"
                    />
                    <label class="form-check-label" :for="`slice-${index}-${i}`">
                      {{ s.text }}
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <CartHoraireShops :partner-id="partnerId" hide-title />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartHoraireShops from '@/components/CartCheckout/CartHoraireShops'
import { Modal } from 'bootstrap'
import moment from 'moment'

export default {
  name: 'HubDeliveryPopup',
  components: { CartHoraireShops },
  props: {
    partnerId: { type: String },
    hub: { type: Object },
    type: { type: String }
  },

  data: () => ({
    modal: null,
    slice: null,
    dates: [],
    hubTimetable: {},
    loading: false
  }),

  beforeDestroy() {
    this.close()
  },

  mounted() {
    this.init()
    this.modal.show()
  },

  methods: {
    init() {
      this.modal = new Modal(this.$refs.modal, {
        keyboard: false,
        backdrop: 'static'
      })
      this.$refs.modal.addEventListener('hidePrevented.bs.modal', event => {
        this.close()
      })
      this.$refs.modal.addEventListener('hidden.bs.modal', event => {
        this.modal.dispose()
        this.$store.commit('modal/hide')
      })

      this.loading = true
      return this.$store.dispatch('cart/get', { partnerId: this.partnerId })
        .then(cart => Promise.all([
          this.$store.dispatch('shop/getCartOpenings', { cart, hub: this.hub }),
          this.$store.dispatch('hub/getOpenings', { id: this.hub.id })
        ]))
        .then(data => {
          this.dates = data[0]
          this.hubTimetable = data[1]
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    getHours(date) {
      return this.hub.getOpeningHours(date, this.hubTimetable)
    },

    getSlicedHours(date) {
      const name = moment(date).locale('en').format('dddd').toLowerCase()
      const day = this.hubTimetable.timetable.find(day => day.name === name)
      return day?.deliverySlices || []
    },

    close(force) {
      if (!force && this.loading) {
        return
      }
      this.modal.hide()
    },

    selectSlice(date, slice) {
      const h = slice.split(':')
      date.hours(h[0]).minutes(h[1])
      this.slice = slice
      return this.submit(date)
    },

    submit(date) {
      if (this.loading || (this.type === 'home' && !this.slice)) {
        return
      }
      this.loading = true
      return this.$store
        .dispatch('cart/setDelivery', {
          hubId: this.hub.id,
          mode: this.type,
          date: date.toISOString()
        })
        .then(() => {
          this.$store.commit('modal/done', { hubId: this.hub.id })
          this.close(true)
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.table-cursor td {
  cursor: pointer;
}

.modal-content {
  text-align: left;
}
</style>
