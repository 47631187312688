<template>
  <div v-show="active.length" class="messages">
    <template v-for="msg in messages">
      <div v-if="msg.show" :key="msg.id" class="message-item" :class="`bg-${msg.color}`">
        <p>{{ msg.message }}</p>
        <span @click="msg.show = false">X</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Message',
  computed: {
    messages() {
      return this.$store.getters['msg/messages']
    },
    active() {
      return this.messages.filter(msg => msg.show)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.messages {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

 .message-item {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    padding: 1em;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    color: white;
    font-weight: 500;
    font-size: 20px;

    p {
      text-align: center;
      flex: 1 1;
    }
    span {
      display: none;
    }
 }
}
</style>
