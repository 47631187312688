/* eslint-disable */
import lodash from 'lodash'

/**
 * Mixin des images
 *
 */
var Mixin = function (options) {

    this.getBaseLogo = function () {
        if (this.logo && lodash.isString(this.logo)) {
            return this.logo;
        } else if (this._links && this._links.logo) {
            return this._links.logo;
        } else if (this.logo) {
            return this.logo;
        }

        return false;
    };

    this.getLogoUrl = function () {
        var base = this.getBaseLogo();
        if (!base || !base.normal || !base.normal.href) {
            return '';
        }
        return base.normal.href;
    };

    this.getOriginalLogoUrl = function () {
        var base = this.getBaseLogo();
        if (!base || !base.raw || !base.raw.href) {
            return '';
        }
        return base.raw.href;
    };

    this.getThumbLogoUrl = function () {
        var base = this.getBaseLogo();

        if (lodash.isString(base)) {
            return base;
        }

        if (!base || !base.thumb || !base.thumb.href) {
            return ''; //config.images[this.getType()].logo['default'];
        }
        return base.thumb.href;
    };

    this.initLogo = function () {
        if (!this._links) {
            this._links = {};
        }
        if (!this._links.logo) {
            this._links.logo = {
                normal: {},
                raw: {},
                thumb: {}
            };
        }
    };

    this.setRawLogoUrl = function (url) {
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        this.initLogo();
        this._links.logo.raw.href = url;
        return this;
    };

    this.setThumbLogoUrl = function (url) {
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        this.initLogo();
        this._links.logo.thumb.href = url;
        return this;
    };

    this.setLogoUrl = function (url) {
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        this.initLogo();
        this._links.logo.normal.href = url;
        return this;
    };

    return this;
};

export default Mixin;
