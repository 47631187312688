import Vue from 'vue'
import i18next from 'i18next'

import VueRouter from 'vue-router'
import store from '../stores'
import oldRoutes from './redirect-old'

const ViewNotFound = () => import(/* webpackChunkName: "home" */ '@/views/ViewNotFound.vue')
const ViewSite = () => import(/* webpackChunkName: "home" */ '@/views/ViewSite.vue')
const ViewHome = () => import(/* webpackChunkName: "home" */ '@/views/ViewHome.vue')
const ViewAccountValidate = () => import(/* webpackChunkName: "validate" */ '@/views/ViewAccountValidate.vue')
const ViewAccountPasswordReminder = () => import(/* webpackChunkName: "validate" */ '@/views/ViewAccountPasswordReminder.vue')
const ViewRecoverCart = () => import(/* webpackChunkName: "validate" */ '@/views/ViewRecoverCart.vue')

const ViewFavourites = () => import(/* webpackChunkName: "connected" */ '@/views/ViewFavourites.vue')

const ViewHomeVilleIdentifie = () => import(/* webpackChunkName: "ville" */ '@/views/ViewHomeVilleIdentifie.vue')
const ViewCart = () => import(/* webpackChunkName: "cart" */ '@/views/ViewCart.vue')
const ViewCommercant = () => import(/* webpackChunkName: "commercant" */ '@/views/ViewCommercant.vue')
const ViewEspacePerso = () => import(/* webpackChunkName: "espaceperso" */ '@/views/ViewEspacePerso.vue')
const ViewCreerCompte = () => import(/* webpackChunkName: "account" */ '@/views/ViewCreerCompte.vue')
const ViewLivraison = () => import(/* webpackChunkName: "livraison" */ '@/views/ViewLivraison.vue')
const ViewMerci = () => import(/* webpackChunkName: "merci" */ '@/views/ViewMerci.vue')
const ViewPaiement = () => import(/* webpackChunkName: "paiement" */ '@/views/ViewPaiement.vue')
const ViewProduit = () => import(/* webpackChunkName: "produit" */ '@/views/ViewProduit.vue')
const ViewShop = () => import(/* webpackChunkName: "shop" */ '@/views/ViewShop.vue')
const ViewVilleCommercants = () => import(/* webpackChunkName: "vileecommercants" */ '@/views/ViewVilleCommercants.vue')
const ViewEnvieDe = () => import(/* webpackChunkName: "enviede" */ '@/views/ViewEnvieDe.vue')
const ViewHubs = () => import(/* webpackChunkName: "hubs" */ '@/views/ViewHubs.vue')
const ViewHomeNestle = () => import(/* webpackChunkName: "account" */ '@/views/ViewHomeNestle.vue')

Vue.use(VueRouter)

const beforeEnter = (to, from, next) => {
  const promises = []
  if (to.params.partnerId) {
    // if partner has changed, reset all stores
    if (store.getters['partner/current']?.id !== to.params.partnerId) {
      store.commit('partner/reset')
      store.commit('shop/reset')
      store.commit('hub/reset')
    }

    promises.push(store.dispatch('partner/get', { id: to.params.partnerId }))
    // also push cart information
    promises.push(store.dispatch('cart/get', { partnerId: to.params.partnerId }))
    if (to.meta.banners) {
      promises.push(store.dispatch('partner/banners', { id: to.params.partnerId }))
    }
  }
  if (to.params.shopSlug) {
    promises.push(store.dispatch('shop/getBySlug', { slug: to.params.shopSlug }).then(shop => {
      to.params.shopId = shop.id
    }).catch(err => {
      to.params.shopId = to.params.shopSlug
      throw err
    }))
  }
  store.commit('load/loading', { loading: true })
  return Promise.all(promises)
    .catch(err => ({ err }))
    .then(data => {
      store.commit('load/loading', { loading: false })
      next(vm => {
        if (data.err) {
          vm.$err(data.err)
        }
      })
    })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    const promise = to.meta.scrollPromise || Promise.resolve()
    return promise
      .then(() => {
        if (to.hash) {
          return {
            selector: to.hash
          }
        }
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(err)
        return savedPosition
      })
  },
  routes: []
    // old structure (to remove someday)
    .concat(oldRoutes)
    // partners (cities) routes
    .concat([{
      path: '/cities/:partnerId',
      name: 'partner',
      props: true,
      component: ViewHomeVilleIdentifie,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/shops',
      name: 'shops',
      props: true,
      component: ViewVilleCommercants,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/envie-de/:filterId',
      name: 'enviede',
      props: true,
      component: ViewEnvieDe,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/products',
      name: 'products',
      props: true,
      component: ViewShop,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/shops/:shopSlug',
      name: 'shop',
      props: true,
      component: ViewCommercant,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/shops/:shopSlug/products/:productId',
      name: 'product',
      props: true,
      component: ViewProduit,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/cart',
      name: 'cart',
      props: true,
      component: ViewCart,
      beforeEnter: beforeEnter,
      meta: {
        public: false,
        hideCart: true
      }
    }, {
      path: '/cities/:partnerId/delivery',
      name: 'delivery',
      props: true,
      component: ViewLivraison,
      beforeEnter: beforeEnter,
      meta: {
        public: false,
        hideCart: true
      }
    }, {
      path: '/cities/:partnerId/payment',
      name: 'payment',
      props: true,
      component: ViewPaiement,
      beforeEnter: beforeEnter,
      meta: {
        public: false,
        hideCart: true
      }
    }, {
      path: '/cities/:partnerId/thanks',
      name: 'thanks',
      props: true,
      component: ViewMerci,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/favourite',
      name: 'favourite',
      props: true,
      component: ViewFavourites,
      beforeEnter: beforeEnter,
      meta: {
        public: false,
        banners: true
      }
    }, {
      path: '/cities/:partnerId/hubs',
      name: 'partnerhubs',
      props: true,
      component: ViewHubs,
      beforeEnter: beforeEnter
    }, {
      path: '/cities/:partnerId/site/:page',
      name: 'partnersite',
      props: true,
      component: ViewSite,
      beforeEnter: beforeEnter
    }])
    // outside a partner (city)
    .concat([{
      path: '/hubs',
      name: 'hubs',
      props: true,
      component: ViewHubs,
      meta: {
        headerCmp: 'HomeHeader'
      }
    }, {
      path: '/validate/:hash',
      name: 'validate-account',
      props: true,
      component: ViewAccountValidate,
      meta: {
        headerCmp: 'HomeHeader',
        private: false
      }
    }, {
      path: '/passwordreminder/:hash',
      name: 'password-reminder',
      props: true,
      component: ViewAccountPasswordReminder,
      meta: {
        headerCmp: 'HomeHeader'
      }
    }, {
      path: '/inscription',
      name: 'inscription',
      component: ViewCreerCompte,
      meta: {
        headerCmp: 'HomeHeader',
        private: false
      }
    }, {
      path: '/site/:page',
      name: 'site',
      props: true,
      component: ViewSite,
      meta: {
        headerCmp: 'HomeHeader'
      }
    }, {
      path: '/espaceperso',
      name: 'espaceperso',
      props: true,
      component: ViewEspacePerso
    }, {
      path: '/recovercart',
      name: 'recovercart',
      props: true,
      component: ViewRecoverCart,
      meta: {
        headerCmp: 'HomeHeader',
        hideCart: true
      }
    }, {
      path: '/',
      name: 'home',
      component: ViewHome,
      meta: {
        headerCmp: 'HomeHeader'
      }
    }, {
      path: '/nestleshop',
      name: 'homenestle',
      component: ViewHomeNestle
    }, {
      path: '*',
      menu: true,
      component: ViewNotFound,
      meta: {
        headerCmp: 'HomeHeader'
      }
    }])
})

router.beforeEach((to, from, next) => {
  if (to.meta.public === false && !store.getters['auth/connected']) {
    store.commit('msg/show', { message: i18next.t('login.nedded') })
    store.commit('modal/show', {
      component: 'LoginPopup',
      params: {
        returnPath: to.path
      }
    })
    return next(!from?.name ? { name: 'home' } : false)
  }
  if (to.meta.private === false && store.getters['auth/connected']) {
    store.commit('msg/show', { message: i18next.t('site.unauthorized') })
    return next({ name: 'home' })
  }
  next()
})

Vue.prototype.$siteLink = (route, page) => {
  let name = 'site'
  if (route.params.partnerId) {
    name = 'partnersite'
  }
  return { name: name, params: { page } }
}

export default router
