import Vue from 'vue'

const Article = Vue.youpaqModel.Article
const Shop = Vue.youpaqModel.Shop

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async listProducts(context, { id, shopId, terms, offset, limit }) {
      const res = await Vue.prototype.$api.get(`/front/partners-filters/${id}/articles`, {
        params: {
          start: offset,
          limit: limit,
          terms: terms || undefined,
          shopId
        }
      })
      return res.data.map(article => new Article(article))
    },

    async listShops(context, { id, partnerId }) {
      const res = await Vue.prototype.$api.get(`/front/partners-filters/${id}/shops`, {
        params: {
          partnerId
        }
      })
      return res.data.map(shop => new Shop(shop))
    }
  }
}
