/* eslint-disable */
import lodash from 'lodash'
import moment from 'moment'
import Vue from 'vue'

var User = function (props) {
    if (props.birthdate) {
        props.birthdate = moment(props.birthdate);
    }
    lodash.assign(this, props);
    Vue.youpaqModelLoad(this, User)
};

User.relations = {
    'proaccount': 'Profile'
};

User.mixins = {
    'metadata': {},
    'images': {},
    'updatable': {
        getJson: {
            merge: {type: 'user'}
        }
    }
};

lodash.assign(User.prototype, {

    getId: function () {
        return this.id;
    },

    setId: function (id) {
        this.id = id;
    },

    getDisplayName: function () {
        return this.firstname + ' ' + this.lastname;
    },

    getPassword: function () {
        return this.password;
    },

    setPassword: function (password) {
        this.password = password;
    },

    getEmail: function () {
        return this.email;
    },

    setEmail: function (email) {
        this.email = email;
    },

    isEnabled: function () {
        return (this.profile && this.profile.enabled);
    },

    /**
     * Vérification si cet utilisateur est suffisamment complet pour confirmer une commande
     */
    canConfirmOrder: function () {
        if (
            !this.firstname
            || !this.lastname
            || !this.coordinate
            || !this.coordinate.street
            || !this.coordinate.zip
            || !this.coordinate.city
            || !this.coordinate.pays_id
            || !this.coordinate.phone
        ) {
            return false;
        }
        return true;
    },

    /**
     * Get roles of this user
     */
    getRoles: function () {
        var automatic_roles = ['member'];
        if (this.isEnabled()) {
            automatic_roles.push('member-pro');
        }
        return (this.roles || []).concat(automatic_roles);
    },

    addRole: function (role) {
        if (!this.roles) {
            this.roles = [];
        }
        this.roles.push(role);
        return this;
    },

    getProfileId: function () {
        if (!this.profile || !this.profile.id) {
            return null;
        }
        return this.profile.id;
    },

    getFavourites: function (type) {
        if (!this.favourites || !this.favourites[type]) {
            return [];
        }
        return this.favourites[type];
    },

    getFavouritesIds: function (type) {
        return lodash.map(this.getFavourites(type), 'id');
    },

    setFavourites: function (type, data) {
        if (!this.favourites) {
            this.favourites = {};
        }
        this.favourites[type] = data;
        return this;
    },

    toggleFavourite: function (type, data) {
        var favourites = this.getFavourites(type),
            favourite = lodash.find(favourites, {id: data.id});

        if (favourite) {
            favourites = lodash.reject(favourites, {id: data.id});
        } else {
            favourites.push(lodash.merge(lodash.omit(data, 'type'), {
                metadata: {
                    created_at: moment().format()
                }
            }));
        }
        this.setFavourites(type, favourites);
    },

    hasFavourite: function (type, data) {
        return !!lodash.find(this.getFavourites(type), {id: data.id});
    },

    addPaymode: function (paymode) {
        if (this.paymodes === undefined) {
            this.paymodes = [];
        }
        if (lodash.find(this.paymodes, {id: paymode.id})) {
            throw "Un mode de paiement avec cet identifiant existe déjà.";
        }
        this.paymodes.push(paymode);
        return this;
    },

    removePaymode: function (pid) {
        lodash.remove(this.paymodes, function (paymode) {
            return paymode.id === pid;
        });
        return this;
    }

});

export default User;
